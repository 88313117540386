import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/redux/typed-hooks';
import { editSupplierAddress, clearSupplierDetailsPatchErrors } from '@app/redux/reducers/supplierDetails';
import { selectIsLoadingPatchSupplierAddress, selectHasSupplierAddressPatchError } from '@app/redux/reducers';
import { useSelectInput, useTextInput } from '@app/components/shared/Form/Inputs';
import { useRedirect } from '@app/hooks';
import { objectHasNonEmptyValue, validateRequiredField } from '@app/utils/validation';

export const useEditAddressForm = (address: SupplierAddress) => {
    const type = useSelectInput(address.type);
    const addressStreet = useTextInput(address.addressStreet);
    const addressHouseNumber = useTextInput(address.addressHouseNumber);
    const addressSuffix = useTextInput(address.addressSuffix);
    const addressPostalCode = useTextInput(address.addressPostalCode);
    const addressCity = useTextInput(address.addressCity);
    const countryCode = useSelectInput(address.countryCode);
    const isLoading = useAppSelector(selectIsLoadingPatchSupplierAddress);
    const hasError = useAppSelector(selectHasSupplierAddressPatchError);
    const dispatch = useAppDispatch();
    const { supplierId } = useParams();
    const [formErrors, setFormErrors] = useState({
        addressStreet: '',
        addressHouseNumber: '',
        addressSuffix: '',
        addressPostalCode: '',
        addressCity: '',
    });

    // Redirect on successful API call
    useRedirect();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newError = {
            addressStreet: validateRequiredField({ value: addressStreet.value, fieldName: 'Street' }),
            addressHouseNumber: validateRequiredField({ value: addressHouseNumber.value, fieldName: 'House number' }),
            addressSuffix: '',
            addressPostalCode: validateRequiredField({ value: addressPostalCode.value, fieldName: 'Postal code' }),
            addressCity: validateRequiredField({ value: addressCity.value, fieldName: 'City' }),
            // The remaining fields are always valid
        };
        setFormErrors(newError);
        if (objectHasNonEmptyValue(newError)) return;

        const newAddress: EditSupplierAddress = {
            type: type.value as SupplierAddress['type'],
            addressStreet: addressStreet.value,
            addressHouseNumber: addressHouseNumber.value,
            addressSuffix: addressSuffix.value,
            addressPostalCode: addressPostalCode.value,
            addressCity: addressCity.value,
            countryCode: countryCode.value as SupplierAddress['countryCode'],
        };

        if (supplierId) {
            dispatch(editSupplierAddress({ newAddress, supplierId, addressId: address.id }));
        }
    };

    useEffect(() => {
        dispatch(clearSupplierDetailsPatchErrors());
    }, [dispatch]);

    return {
        type,
        addressStreet,
        addressHouseNumber,
        addressSuffix,
        addressPostalCode,
        addressCity,
        countryCode,
        handleSubmit,
        isLoading,
        formErrors,
        hasError,
    };
};

import React from 'react';
import { Heading } from '../../shared';

interface SupplierInfoProps {
    supplierName: SupplierDetails['supplierName'];
    supplierNumber: SupplierDetails['supplierNumber'];
    skuPrefix: SupplierDetails['skuPrefix'];
    isSelfBilling: SupplierDetails['isSelfBilling'];
    chamberOfCommerce: SupplierDetails['chamberOfCommerce'];
    website: SupplierDetails['website'];
}

export const SupplierInfo = ({
    supplierName,
    supplierNumber,
    skuPrefix,
    isSelfBilling,
    chamberOfCommerce,
    website,
}: SupplierInfoProps) => {
    return (
        <>
            <Heading level={2} text='Supplier information' />
            <p>
                {supplierName}
                <br />
                Supplier number: {supplierNumber}
                <br />
                SKU Prefix: {skuPrefix}
                <br />
                Self Billing: {isSelfBilling ? 'Yes' : 'No'}
                <br />
                Chamber of Commerce number: {chamberOfCommerce}
                <br />
                Website:{' '}
                {website && (
                    <a href={website} target='_blank' rel='noreferrer'>
                        {website}
                    </a>
                )}
            </p>
        </>
    );
};

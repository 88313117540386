import React from 'react';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import styles from './dataGridExportToolbar.module.scss';

export const DataGridExportToolbar = () => (
    <GridToolbarContainer>
        <div className={styles.exportBtnContainer}>
            <GridToolbarExport />
        </div>
    </GridToolbarContainer>
);

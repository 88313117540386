import React from 'react';
import { Form } from '../shared/Form';
import { CheckboxGroup, CheckboxInput, TextInput } from '../shared/Form/Inputs';
import { useProductUpload } from './useProductUpload';
import { SubmitButton } from '../shared/Buttons';
import { Error } from '../shared';
import styles from './productUpload.module.scss';

export const ProductUpload = () => {
    const { url, sheet, isMarketplaceNL, isMarketplaceDE, handleSubmit, formErrors, isLoading, hasError } =
        useProductUpload();

    return (
        <>
            {hasError && (
                <div className={styles.errorWrapper}>
                    <Error />
                </div>
            )}
            <Form id='product-upload' handleSubmit={handleSubmit}>
                <div className={styles.saveBtnWrapper}>
                    <SubmitButton formId='product-upload' variant='upload' isLoading={isLoading} />
                </div>
                <div className={styles.formWrapper}>
                    <TextInput
                        label='URL'
                        value={url.value}
                        handleChange={url.handleChange}
                        error={formErrors.url !== ''}
                        helperText={formErrors.url}
                    />

                    <TextInput
                        label='Sheet name'
                        value={sheet.value}
                        handleChange={sheet.handleChange}
                        error={formErrors.sheet !== ''}
                        helperText={formErrors.sheet}
                    />

                    <CheckboxGroup
                        label='Marketplace codes'
                        error={formErrors.marketplaces !== ''}
                        helperText={formErrors.marketplaces}
                    >
                        <CheckboxInput
                            label='NL'
                            checked={isMarketplaceNL.value}
                            handleChange={isMarketplaceNL.handleChange}
                        />
                        <CheckboxInput
                            label='DE'
                            checked={isMarketplaceDE.value}
                            handleChange={isMarketplaceDE.handleChange}
                        />
                    </CheckboxGroup>
                </div>
            </Form>
        </>
    );
};

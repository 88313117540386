import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { selectIsLoadingPatchContractStatus, selectHasContractStatusPatchError } from '../../redux/reducers';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { clearBaseContractError, activateContract } from '../../redux/reducers/supplierDetails';

export const useActivateContract = () => {
    const dispatch = useAppDispatch();
    const { contractId } = useParams();
    const { supplierId } = useParams();
    const hasActivateContractError = useAppSelector(selectHasContractStatusPatchError);
    const isLoadingPatchContractStatus = useAppSelector(selectIsLoadingPatchContractStatus);

    const handleActivateContract = () => {
        if (supplierId && contractId) {
            dispatch(activateContract({ status: 'active' as ContractBase['status'] }, { supplierId, contractId }));
        }
    };

    useEffect(() => {
        dispatch(clearBaseContractError());
    }, [dispatch]);

    return { handleActivateContract, isLoadingPatchContractStatus, hasActivateContractError };
};

import React from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import styles from './yesNoField.module.scss';

interface YesNoFieldProps {
    yes: boolean;
}

export const YesNoField = ({ yes }: YesNoFieldProps) => {
    return (
        <div className={styles.yesNo}>
            {yes ? (
                <>
                    <CheckOutlinedIcon color='success' /> YES
                </>
            ) : (
                <>
                    <ClearOutlinedIcon color='error' /> NO
                </>
            )}
        </div>
    );
};

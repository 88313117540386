import { useState } from 'react';
import Papa from 'papaparse';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '@app/redux/typed-hooks';
import { exportCommissionContactSkuFees, importCommissionContactSkuFees } from '@app/redux/reducers/supplierDetails';

interface ISkuAction {
    command: string;
    supplierNumber: string;
    commissionId: string;
    vendorSku: string;
    commissionPercentage: string;
    beginDate: string;
    endDate: string;
}

export const useSkuActions = () => {
    const dispatch = useAppDispatch();
    const { supplierId } = useParams();
    const [fileName, setFileName] = useState<string>('');
    const [csvData, setCsvData] = useState<string>('');
    const [jsonData, setJsonData] = useState<ISkuAction[]>([]);
    const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);

    const openUploadDialog = () => setShowUploadDialog(true);
    const closeUploadDialog = () => setShowUploadDialog(false);

    const onExportCommissionContactSkuFees = () => {
        if (supplierId) dispatch(exportCommissionContactSkuFees({ supplierId }));
    };

    const onImportCommissionContactSkuFees = () => {
        if (supplierId && fileName && csvData) {
            const fileContents = window.btoa(csvData);
            dispatch(importCommissionContactSkuFees({ supplierId, fileName, fileContents }));
        }
    };

    const onFileUpload = (file: File) => {
        const reader = new FileReader();
        reader.onload = async ({ target }) => {
            if (!target) return;

            const csvRawData = target.result as string;
            const parsedJsonData = Papa.parse(csvRawData, { header: true })?.data as ISkuAction[];
            const mappedJsonData = parsedJsonData.map((item) => ({
                command: item.command || '',
                supplierNumber: item.supplierNumber || '',
                commissionId: item.commissionId || '',
                vendorSku: item.vendorSku || '',
                commissionPercentage: item.commissionPercentage || '',
                beginDate: item.beginDate || '',
                endDate: item.endDate || '',
            }));

            setFileName(file.name);
            setCsvData(csvRawData);
            setJsonData(mappedJsonData);
            setShowUploadDialog(true);

            console.log(parsedJsonData);
        };
        reader.readAsText(file);
    };

    const skuActionTableColumns = [
        {
            label: 'Command',
        },
        {
            label: 'Supplier Number',
        },
        {
            label: 'Commission ID',
        },
        {
            label: 'Vendor SKU',
        },
        {
            label: 'Commission Percentage',
        },
        {
            label: 'Begin Date',
        },
        {
            label: 'End Date',
        },
    ];

    return {
        fileName,
        csvData,
        jsonData,
        showUploadDialog,
        openUploadDialog,
        closeUploadDialog,
        onFileUpload,
        skuActionTableColumns,
        onExportCommissionContactSkuFees,
        onImportCommissionContactSkuFees,
    };
};

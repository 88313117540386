import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/redux/typed-hooks';
import { editSupplierGeneralDetails, clearSupplierDetailsPatchErrors } from '@app/redux/reducers/supplierDetails';
import { selectIsLoadingSupplierGeneralDetails, selectHasSupplierGeneralDetailsError } from '@app/redux/reducers';
import { useRedirect } from '@app/hooks';
import {
    validateWebsite,
    validateSkuPrefix,
    validateRequiredField,
    validateEmails,
    objectHasNonEmptyValue,
} from '@app/utils/validation';
import { useTextInput, useSelectInput, useCheckboxInput } from '@app/components/shared/Form/Inputs';

export const useEditGeneralDetailsForm = (supplier: EditSupplierGeneralDetails) => {
    const supplierName = useTextInput(supplier.supplierName);
    const website = useTextInput(supplier.website);
    const chamberOfCommerce = useTextInput(supplier.chamberOfCommerce);
    const skuPrefix = useTextInput(supplier.skuPrefix);
    const isSelfBilling = useCheckboxInput(supplier.isSelfBilling);
    const invoiceAddressName = useTextInput(supplier.invoicing.invoiceAddressName);
    const invoiceAddressStreet = useTextInput(supplier.invoicing.invoiceAddressStreet);
    const invoiceAddressHouseNumber = useTextInput(supplier.invoicing.invoiceAddressHouseNumber);
    const invoiceAddressSuffix = useTextInput(supplier.invoicing.invoiceAddressSuffix);
    const invoiceAddressPostalCode = useTextInput(supplier.invoicing.invoiceAddressPostalCode);
    const invoiceAddressCity = useTextInput(supplier.invoicing.invoiceAddressCity);
    const invoiceAddressCountryCode = useSelectInput(supplier.invoicing.invoiceAddressCountryCode);
    const vatNumber = useTextInput(supplier.invoicing.vatNumber);
    const invoiceLanguage = useSelectInput(supplier.invoicing.invoiceLanguage);
    const invoiceEmailAddresses = useTextInput(supplier.invoicing.invoiceEmailAddresses.join(', '));
    const [formErrors, setFormErrors] = useState({
        supplierName: '',
        website: '',
        chamberOfCommerce: '',
        skuPrefix: '',
        invoiceAddressName: '',
        invoiceAddressStreet: '',
        invoiceAddressHouseNumber: '',
        invoiceAddressSuffix: '',
        invoiceAddressPostalCode: '',
        invoiceAddressCity: '',
        invoiceAddressCountryCode: '',
        vatNumber: '',
        invoiceLanguage: '',
        invoiceEmailAddresses: '',
    });
    const dispatch = useAppDispatch();
    const { supplierId } = useParams();
    const isLoading = useAppSelector(selectIsLoadingSupplierGeneralDetails);
    const hasError = useAppSelector(selectHasSupplierGeneralDetailsError);

    // Redirect on successful API call
    useRedirect();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newError = {
            supplierName: validateRequiredField({ value: supplierName.value, fieldName: 'Supplier name' }),
            website: website.value ? validateWebsite({ value: website.value, fieldName: 'URL' }) : '',
            chamberOfCommerce: '',
            skuPrefix: validateSkuPrefix({ value: skuPrefix.value, fieldName: 'SKU prefix' }),
            invoiceAddressName: validateRequiredField({
                value: invoiceAddressName.value,
                fieldName: 'Address name',
            }),
            invoiceAddressStreet: validateRequiredField({
                value: invoiceAddressStreet.value,
                fieldName: 'Street',
            }),
            invoiceAddressHouseNumber: validateRequiredField({
                value: invoiceAddressHouseNumber.value,
                fieldName: 'House number',
            }),
            invoiceAddressSuffix: '',
            invoiceAddressPostalCode: validateRequiredField({
                value: invoiceAddressPostalCode.value,
                fieldName: 'Postal code',
            }),
            invoiceAddressCity: validateRequiredField({
                value: invoiceAddressCity.value,
                fieldName: 'City',
            }),
            invoiceAddressCountryCode: validateRequiredField({
                value: invoiceAddressCountryCode.value,
                fieldName: 'Country code',
            }),
            vatNumber: validateRequiredField({ value: vatNumber.value, fieldName: 'Vat number' }),
            invoiceLanguage: validateRequiredField({ value: invoiceLanguage.value, fieldName: 'Invoice language' }),
            invoiceEmailAddresses: validateEmails({
                emails: invoiceEmailAddresses.value.split(','),
                fieldName: 'Email Addresses',
            }),
        };

        setFormErrors(newError);
        if (objectHasNonEmptyValue(newError)) return;

        const newDetails: EditSupplierGeneralDetails = {
            supplierName: supplierName.value,
            website: website.value,
            chamberOfCommerce: chamberOfCommerce.value,
            skuPrefix: skuPrefix.value,
            isSelfBilling: isSelfBilling.value,
            invoicing: {
                invoiceAddressName: invoiceAddressName.value,
                invoiceAddressStreet: invoiceAddressStreet.value,
                invoiceAddressHouseNumber: invoiceAddressHouseNumber.value,
                invoiceAddressSuffix: invoiceAddressSuffix.value,
                invoiceAddressPostalCode: invoiceAddressPostalCode.value,
                invoiceAddressCity: invoiceAddressCity.value,
                invoiceAddressCountryCode:
                    invoiceAddressCountryCode.value as SupplierInvoicing['invoiceAddressCountryCode'],
                vatNumber: vatNumber.value,
                invoiceLanguage: invoiceLanguage.value as SupplierInvoicing['invoiceLanguage'],
                invoiceEmailAddresses: invoiceEmailAddresses.value.split(', '),
            },
        };

        if (supplierId) {
            dispatch(editSupplierGeneralDetails(newDetails, supplierId));
        }
    };

    useEffect(() => {
        dispatch(clearSupplierDetailsPatchErrors());
    }, [dispatch]);

    return {
        formErrors,
        supplierName,
        website,
        chamberOfCommerce,
        skuPrefix,
        isSelfBilling,
        invoiceAddressName,
        invoiceAddressStreet,
        invoiceAddressHouseNumber,
        invoiceAddressSuffix,
        invoiceAddressPostalCode,
        invoiceAddressCity,
        invoiceAddressCountryCode,
        vatNumber,
        invoiceLanguage,
        invoiceEmailAddresses,
        handleSubmit,
        isLoading,
        hasError,
    };
};

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/typed-hooks';
import { editSupplierContact, clearSupplierDetailsPatchErrors } from '../../../../redux/reducers/supplierDetails';
import { selectIsLoadingPatchSupplierContact, selectHasSupplierContactPatchError } from '../../../../redux/reducers';
import { useSelectInput, useTextInput } from '../../../shared/Form/Inputs';
import { useRedirect } from '../../../../hooks';
import { objectHasNonEmptyValue, validateEmail, validateRequiredField } from '../../../../utils/validation';

export const useEditContactForm = (contact: SupplierContact) => {
    const contactType = useSelectInput(contact.type);
    const contactName = useTextInput(contact.name);
    const contactTelephone = useTextInput(contact.telephoneNumber);
    const contactEmail = useTextInput(contact.email);
    const [formErrors, setFormErrors] = useState({ type: '', name: '', telephone: '', email: '' });
    const isLoading = useAppSelector(selectIsLoadingPatchSupplierContact);
    const hasError = useAppSelector(selectHasSupplierContactPatchError);
    const dispatch = useAppDispatch();
    const { supplierId } = useParams();

    // Redirect on successful API call
    useRedirect();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newError = {
            type: validateRequiredField({ value: contactType.value, fieldName: 'Contact type' }),
            name: validateRequiredField({ value: contactName.value, fieldName: 'Supplier name' }),
            telephone: validateRequiredField({
                value: contactTelephone.value,
                fieldName: 'Supplier telephone number',
            }),
            email: validateEmail({ value: contactEmail.value, fieldName: 'Email address' }),
        };
        setFormErrors(newError);
        if (objectHasNonEmptyValue(newError)) return;

        const newContact: EditSupplierContact = {
            type: contactType.value as SupplierContact['type'],
            name: contactName.value,
            telephoneNumber: contactTelephone.value,
            email: contactEmail.value,
        };

        if (supplierId) {
            dispatch(editSupplierContact({ newContact, supplierId, contactId: contact.id }));
        }
    };

    useEffect(() => {
        dispatch(clearSupplierDetailsPatchErrors());
    }, [dispatch]);

    return { formErrors, contactType, contactName, contactTelephone, contactEmail, handleSubmit, isLoading, hasError };
};

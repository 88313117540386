import React from 'react';
import { useParams } from 'react-router-dom';
import { Alert } from '@mui/material';
import { PriceUploadErrors, PriceUploadForm, PriceUploadHeadersError, PriceUploadPreview } from './subcomponents';
import { Error } from '../shared/Error/Error';
import { SubmitButton } from '../shared/Buttons';
import { usePriceUpload } from './usePriceUpload';
import styles from './priceUpload.module.scss';

export const PriceUpload = () => {
    const {
        handleFileChange,
        handleSubmit,
        file,
        parsedFile,
        validHeaders,
        headersError,
        formErrors,
        isLoading,
        hasError,
    } = usePriceUpload();

    const { supplierId } = useParams();

    const isSubmissionEnabled = file && !headersError && formErrors.length === 0;

    const formId = 'price-upload-form';

    return (
        <div className={styles.priceUpload}>
            <div className={styles.priceUpload__form}>
                <PriceUploadForm
                    formId={formId}
                    fileName={file ? file.name : ''}
                    handleFileChange={handleFileChange}
                    handleSubmit={handleSubmit(file, supplierId || '')}
                />
            </div>

            {parsedFile && (
                <div className={styles.priceUpload__preview}>
                    <PriceUploadPreview headers={validHeaders} products={parsedFile} />
                </div>
            )}

            {headersError && (
                <div className={styles.priceUpload__alert}>
                    <PriceUploadHeadersError validHeaders={validHeaders} />
                </div>
            )}

            {formErrors.length > 0 && (
                <div className={styles.priceUpload__alert}>
                    <PriceUploadErrors errors={formErrors} />
                </div>
            )}

            {isSubmissionEnabled && (
                <div className={styles.priceUpload__alert}>
                    <Alert severity='success'>Your file has been successfully validated and is ready for upload.</Alert>
                </div>
            )}

            {hasError && (
                <div className={styles.priceUpload__alert}>
                    <Error />
                </div>
            )}

            <SubmitButton formId={formId} variant='upload' isLoading={isLoading} isDisabled={!isSubmissionEnabled} />
        </div>
    );
};

import React from 'react';
import { Form } from '../../../shared/Form';
import { TextInput } from '../../../shared/Form/Inputs';
import { SubmitButton } from '../../../shared/Buttons';
import { Error } from '../../../shared';
import { useNotes } from './useNotes';
import { useCheckIfUserHasManagerRights } from '../../../../hooks';
import styles from './notes.module.scss';

export const Notes = () => {
    const { hasManagerRights } = useCheckIfUserHasManagerRights();
    const { notes, newNotes, handleSubmit, isLoading, hasError } = useNotes();

    if (hasManagerRights)
        return (
            <Form id='notes-form' handleSubmit={handleSubmit}>
                {hasError && (
                    <div className={styles.errorWrapper}>
                        <Error />
                    </div>
                )}
                <div className={styles.commentWrapper}>
                    <TextInput
                        variant='textarea'
                        label='Notes'
                        hasExtraLabel={false}
                        value={newNotes.value}
                        handleChange={newNotes.handleChange}
                    />
                </div>
                <div className={styles.postBtnWrapper}>
                    <SubmitButton variant='save' isLoading={isLoading} />
                </div>
            </Form>
        );
    return <div>{notes || 'No notes'}</div>;
};

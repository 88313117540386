import axios from 'axios';
import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { downloadAsCSV } from '@app/utils/downloadAsCSV'
import { getRandomShortId } from '@app/utils/random'
import { getGatewayApiInstance, handleAxiosErrors } from '@app/utils/http-requests';
import { getError, clearError } from './error';
import { notificationUpdated, errorNotificationUpdated } from './notifications';
import { redirectLinkUpdated } from './redirect';

const initialState = {
    supplierDetails: null as null | SupplierDetails,
    isLoading: false,
    isLoadingGeneralDetails: false,
    isLoadingPatchNumber: false,
    isLoadingPatchIban: false,
    isLoadingPatchSupplierAddress: false,
    isLoadingPostSupplierAddress: false,
    isLoadingPatchSupplierContact: false,
    isLoadingPostSupplierContact: false,
    isLoadingPatchSupplierManager: false,
    isLoadingPostSupplierManager: false,
    isLoadingPatchSupplierNotes: false,
    isLoadingPatchPurchaseContract: false,
    isLoadingPostPurchaseContract: false,
    isLoadingPatchCommissionContract: false,
    isLoadingPostCommissionContract: false,
    isLoadingPatchBaseContract: false,
    isLoadingDuplicateContract: false,
    hasError: false,
    hasGeneralDetailsError: false,
    hasNumberPatchError: false,
    hasIbanPatchError: false,
    hasSupplierAddressPatchError: false,
    hasSupplierAddressPostError: false,
    hasSupplierContactPatchError: false,
    hasSupplierContactPostError: false,
    hasSupplierManagerPatchError: false,
    hasSupplierManagerPostError: false,
    hasSupplierNotesPatchError: false,
    hasCommissionContractPatchError: false,
    hasCommissionContractPostError: false,
    hasPurchaseContractPatchError: false,
    hasPurchaseContractPostError: false,
    hasContractStatusPatchError: false,
    hasContractDuplicateError: false,
};

type State = typeof initialState;

const supplierDetailsSlice = createSlice({
    name: 'supplierDetails',
    initialState,
    reducers: {
        supplierDetailsLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        supplierGeneralDetailsLoading(state) {
            return {
                ...state,
                isLoadingGeneralDetails: true,
                hasGeneralDetailsError: false,
            };
        },
        supplierNumberLoading(state) {
            return {
                ...state,
                isLoadingPatchNumber: true,
                hasNumberPatchError: false,
            };
        },
        supplierIbanLoading(state) {
            return {
                ...state,
                isLoadingPatchIban: true,
                hasIbanPatchError: false,
            };
        },
        supplierAddressPatchLoading(state) {
            return {
                ...state,
                isLoadingPatchSupplierAddress: true,
                hasSupplierAddressPatchError: false,
            };
        },
        supplierAddressPostLoading(state) {
            return {
                ...state,
                isLoadingPostSupplierAddress: true,
                hasSupplierAddressPostError: false,
            };
        },
        supplierContactPatchLoading(state) {
            return {
                ...state,
                isLoadingPatchSupplierContact: true,
                hasSupplierContactPatchError: false,
            };
        },
        supplierContactPostLoading(state) {
            return {
                ...state,
                isLoadingPostSupplierContact: true,
                hasSupplierContactPostError: false,
            };
        },
        supplierManagerPatchLoading(state) {
            return {
                ...state,
                isLoadingPatchSupplierManager: true,
                hasSupplierManagerPatchError: false,
            };
        },
        supplierManagerPostLoading(state) {
            return {
                ...state,
                isLoadingPostSupplierManager: true,
                hasSupplierManagerPostError: false,
            };
        },
        supplierNotesPatchLoading(state) {
            return {
                ...state,
                isLoadingPatchSupplierNotes: true,
                hasSupplierNotesPatchError: false,
            };
        },
        commissionContractPatchLoading(state) {
            return {
                ...state,
                isLoadingPatchCommissionContract: true,
                hasCommissionContractPatchError: false,
            };
        },
        commissionContractPostLoading(state) {
            return {
                ...state,
                isLoadingPostCommissionContract: true,
                hasCommissionContractPostError: false,
            };
        },
        purchaseContractPatchLoading(state) {
            return {
                ...state,
                isLoadingPatchPurchaseContract: true,
                hasPurchaseContractPatchError: false,
            };
        },
        purchaseContractPostLoading(state) {
            return {
                ...state,
                isLoadingPostPurchaseContract: true,
                hasPurchaseContractPostError: false,
            };
        },
        contractStatusPatchLoading(state) {
            return {
                ...state,
                isLoadingPatchBaseContract: true,
                hasContractStatusPatchError: false,
            };
        },
        contractDuplicateLoading(state) {
            return {
                ...state,
                isLoadingDuplicateContract: true,
                hasContractDuplicateError: false,
            };
        },
        supplierDetailsReceived(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                supplierDetails: action.payload,
                isLoading: false,
            };
        },
        supplierGeneralDetailsUpdated(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                supplierDetails: action.payload,
                isLoadingGeneralDetails: false,
            };
        },
        supplierNumberUpdated(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                supplierDetails: action.payload,
                isLoadingPatchNumber: false,
            };
        },
        supplierIbanUpdated(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                supplierDetails: action.payload,
                isLoadingPatchIban: false,
            };
        },
        supplierAddressUpdated(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                supplierDetails: action.payload,
                isLoadingPatchSupplierAddress: false,
            };
        },
        supplierAddressCreated(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                supplierDetails: action.payload,
                isLoadingPostSupplierAddress: false,
            };
        },
        supplierContactUpdated(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                supplierDetails: action.payload,
                isLoadingPatchSupplierContact: false,
            };
        },
        supplierContactCreated(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                supplierDetails: action.payload,
                isLoadingPostSupplierContact: false,
            };
        },
        supplierManagerUpdated(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                supplierDetails: action.payload,
                isLoadingPatchSupplierManager: false,
            };
        },
        supplierManagerCreated(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                supplierDetails: action.payload,
                isLoadingPostSupplierManager: false,
            };
        },
        supplierNotesUpdated(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                supplierDetails: action.payload,
                isLoadingPatchSupplierNotes: false,
            };
        },
        commissionContractUpdated(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                supplierDetails: action.payload,
                isLoadingPatchCommissionContract: false,
            };
        },
        commissionContractCreated(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                supplierDetails: action.payload,
                isLoadingPostCommissionContract: false,
            };
        },
        purchaseContractUpdated(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                supplierDetails: action.payload,
                isLoadingPatchPurchaseContract: false,
            };
        },
        purchaseContractCreated(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                supplierDetails: action.payload,
                isLoadingPostPurchaseContract: false,
            };
        },
        contractStatusUpdated(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                supplierDetails: action.payload,
                isLoadingPatchBaseContract: false,
            };
        },
        contractDuplicated(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                supplierDetails: action.payload,
                isLoadingDuplicateContract: false,
            };
        },
        supplierDetailsError(state) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
        supplierGeneralDetailsPatchError(state) {
            return {
                ...state,
                isLoadingGeneralDetails: false,
                hasGeneralDetailsError: true,
            };
        },
        supplierNumberPatchError(state) {
            return {
                ...state,
                isLoadingPatchNumber: false,
                hasNumberPatchError: true,
            };
        },
        supplierIbanPatchError(state) {
            return {
                ...state,
                isLoadingPatchIban: false,
                hasIbanPatchError: true,
            };
        },
        supplierAddressPatchError(state) {
            return {
                ...state,
                isLoadingPatchSupplierAddress: false,
                hasSupplierAddressPatchError: true,
            };
        },
        supplierAddressPostError(state) {
            return {
                ...state,
                isLoadingPostSupplierAddress: false,
                hasSupplierAddressPostError: true,
            };
        },
        supplierContactPatchError(state) {
            return {
                ...state,
                isLoadingPatchSupplierContact: false,
                hasSupplierContactPatchError: true,
            };
        },
        supplierContactPostError(state) {
            return {
                ...state,
                isLoadingPostSupplierContact: false,
                hasSupplierContactPostError: true,
            };
        },
        supplierManagerPatchError(state) {
            return {
                ...state,
                isLoadingPatchSupplierManager: false,
                hasSupplierManagerPatchError: true,
            };
        },
        supplierManagerPostError(state) {
            return {
                ...state,
                isLoadingPostSupplierManager: false,
                hasSupplierManagerPostError: true,
            };
        },
        supplierNotesPatchError(state) {
            return {
                ...state,
                isLoadingPatchSupplierNotes: false,
                hasSupplierNotesPatchError: true,
            };
        },
        commissionContractPatchError(state) {
            return {
                ...state,
                isLoadingPatchCommissionContract: false,
                hasCommissionContractPatchError: true,
            };
        },
        commissionContractPostError(state) {
            return {
                ...state,
                isLoadingPostCommissionContract: false,
                hasCommissionContractPostError: true,
            };
        },
        purchaseContractPatchError(state) {
            return {
                ...state,
                isLoadingPatchPurchaseContract: false,
                hasPurchaseContractPatchError: true,
            };
        },
        purchaseContractPostError(state) {
            return {
                ...state,
                isLoadingPostPurchaseContract: false,
                hasPurchaseContractPostError: true,
            };
        },
        contractStatusPatchError(state) {
            return {
                ...state,
                isLoadingPatchBaseContract: false,
                hasContractStatusPatchError: true,
            };
        },
        contractDuplicateError(state) {
            return {
                ...state,
                isLoadingDuplicateContract: false,
                hasContractDuplicateError: true,
            };
        },
        // One action to clear all errors resulting from failed PATCH requests
        supplierDetailsPatchErrorsCleared(state) {
            return {
                ...state,
                hasGeneralDetailsError: false,
                hasNumberPatchError: false,
                hasIbanPatchError: false,
                hasSupplierManagerPatchError: false,
                hasSupplierContactPatchError: false,
                hasSupplierAddressPatchError: false,
                hasSupplierNotesPatchError: false,
                hasCommissionContractPatchError: false,
                hasPurchaseContractPatchError: false,
            };
        },
        // One action to clear all errors resulting from failed POST requests
        supplierDetailsPostErrorsCleared(state) {
            return {
                ...state,
                hasSupplierAddressPostError: false,
                hasSupplierContactPostError: false,
                hasSupplierManagerPostError: false,
                hasCommissionContractPostError: false,
                hasPurchaseContractPostError: false,
            };
        },
        baseContractErrorCleared(state) {
            return {
                ...state,
                hasContractStatusPatchError: false,
            };
        },
    },
});

export default supplierDetailsSlice.reducer;

const {
    // Loading actions
    supplierDetailsLoading,
    supplierGeneralDetailsLoading,
    supplierNumberLoading,
    supplierIbanLoading,
    supplierAddressPatchLoading,
    supplierAddressPostLoading,
    supplierNotesPatchLoading,
    commissionContractPatchLoading,
    commissionContractPostLoading,
    purchaseContractPatchLoading,
    purchaseContractPostLoading,
    contractStatusPatchLoading,
    contractDuplicateLoading,
    supplierContactPatchLoading,
    supplierContactPostLoading,
    supplierManagerPatchLoading,
    supplierManagerPostLoading,
    supplierDetailsReceived,
    // Create actions
    supplierAddressCreated,
    supplierContactCreated,
    supplierManagerCreated,
    commissionContractCreated,
    purchaseContractCreated,
    // Update actions
    supplierGeneralDetailsUpdated,
    supplierNumberUpdated,
    supplierIbanUpdated,
    supplierAddressUpdated,
    supplierContactUpdated,
    supplierManagerUpdated,
    supplierNotesUpdated,
    commissionContractUpdated,
    purchaseContractUpdated,
    contractStatusUpdated,
    contractDuplicated,
    // Error actions
    supplierDetailsError,
    supplierGeneralDetailsPatchError,
    supplierNumberPatchError,
    supplierIbanPatchError,
    supplierAddressPatchError,
    supplierAddressPostError,
    contractStatusPatchError,
    contractDuplicateError,
    commissionContractPatchError,
    commissionContractPostError,
    purchaseContractPatchError,
    purchaseContractPostError,
    supplierContactPatchError,
    supplierContactPostError,
    supplierManagerPatchError,
    supplierManagerPostError,
    supplierNotesPatchError,
} = supplierDetailsSlice.actions;

export const { 
    supplierDetailsPatchErrorsCleared: clearSupplierDetailsPatchErrors, 
    supplierDetailsPostErrorsCleared: clearSupplierDetailsPostErrors,
    baseContractErrorCleared: clearBaseContractError
} = supplierDetailsSlice.actions;

export const getSupplierDetails = (supplierId: string) => async (dispatch: Dispatch) => {
    dispatch(clearError())
    dispatch(supplierDetailsLoading());
    try {
        const res = await getGatewayApiInstance().get(`/suppliers/${supplierId}`);
        dispatch(supplierDetailsReceived(res.data));
    } catch (err: unknown) {
        dispatch(supplierDetailsError());
        if (axios.isAxiosError(err) && err.response) {
            dispatch(getError({
                errData: err.response.data,
                errStatus: err.response.status
            }));
        }
        // Log errors to the console
        handleAxiosErrors(err);
    }
};

export const editSupplierGeneralDetails =
    (newDetails: EditSupplierGeneralDetails, supplierId: string) => async (dispatch: Dispatch) => {
        dispatch(clearError());
        dispatch(supplierGeneralDetailsLoading());
        try {
            const res = await getGatewayApiInstance().patch(`/suppliers/${supplierId}`, newDetails);
            dispatch(supplierGeneralDetailsUpdated(res.data));
            dispatch(notificationUpdated('Supplier general details were successfully updated'));
            dispatch(redirectLinkUpdated(`/suppliers/${supplierId}`));
        } catch (err: unknown) {
            dispatch(supplierGeneralDetailsPatchError());
            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
            // Log errors to the console
            handleAxiosErrors(err);
        }
    };

type SupplierNumberURLParams = { supplierId: string; newSupplierNumber: { supplierNumber: number } };

export const editSupplierNumber =
    ({ supplierId, newSupplierNumber }: SupplierNumberURLParams) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError());
        dispatch(supplierNumberLoading());
        try {
            const res = await getGatewayApiInstance().patch(`/suppliers/${supplierId}`, newSupplierNumber);
            dispatch(supplierNumberUpdated(res.data));
            dispatch(notificationUpdated('Supplier number was successfully updated'));
            dispatch(redirectLinkUpdated(`/suppliers/${supplierId}`));
        } catch (err: unknown) {
            dispatch(supplierNumberPatchError());
            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
            // Log errors to the console
            handleAxiosErrors(err);
        }
    };

export const editSupplierIban =
    ({ supplierId, newSupplierIban }: { supplierId: string; newSupplierIban: EditSupplierIban }) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError())
        dispatch(supplierIbanLoading());
        try {
            const res = await getGatewayApiInstance().patch(`/suppliers/${supplierId}`, newSupplierIban);
            dispatch(supplierIbanUpdated(res.data));
            dispatch(notificationUpdated('Supplier IBAN was successfully updated'));
            dispatch(redirectLinkUpdated(`/suppliers/${supplierId}`));
        } catch (err: unknown) {
            dispatch(supplierIbanPatchError());
            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
            // Log errors to the console
            handleAxiosErrors(err);
        }
    };


type EditSupplierAddressParams = {
    newAddress: EditSupplierAddress;
    supplierId: string;
    addressId: number;
};

export const editSupplierAddress =
    ({ newAddress, supplierId, addressId }: EditSupplierAddressParams) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError());
        dispatch(supplierAddressPatchLoading());
        try {
            const res = await getGatewayApiInstance().put(
                `/suppliers/${supplierId}/addresses/${addressId}`,
                newAddress
            );
            dispatch(supplierAddressUpdated(res.data));
            dispatch(notificationUpdated('Supplier address was successfully updated'));
            dispatch(redirectLinkUpdated(`/suppliers/${supplierId}#addresses`));
        } catch (err: unknown) {
            dispatch(supplierAddressPatchError());
            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
            // Log errors to the console
            handleAxiosErrors(err);
        }
    };

type CreateSupplierAddressParams = {
    newAddress: CreateSupplierAddress;
    supplierId: string;
};

export const createSupplierAddress =
    ({ newAddress, supplierId }: CreateSupplierAddressParams) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError());
        dispatch(supplierAddressPostLoading());
        try {
            const res = await getGatewayApiInstance().post(`/suppliers/${supplierId}/addresses`, newAddress);
            dispatch(supplierAddressCreated(res.data));
            dispatch(notificationUpdated('Supplier address was successfully created'));
            dispatch(redirectLinkUpdated(`/suppliers/${supplierId}#addresses`));
        } catch (err: unknown) {
            dispatch(supplierAddressPostError());
            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
            // Log errors to the console
            handleAxiosErrors(err);
        }
    };

type EditSupplierContactParams = {
    newContact: EditSupplierContact;
    supplierId: string;
    contactId: number;
};

export const editSupplierContact =
    ({ newContact, supplierId, contactId }: EditSupplierContactParams) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError());
        dispatch(supplierContactPatchLoading());
        try {
            const res = await getGatewayApiInstance().put(`/suppliers/${supplierId}/contacts/${contactId}`, newContact);
            dispatch(supplierContactUpdated(res.data));
            dispatch(notificationUpdated('Supplier contact was successfully updated'));
            dispatch(redirectLinkUpdated(`/suppliers/${supplierId}#contacts`));
        } catch (err: unknown) {
            dispatch(supplierContactPatchError());
            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
            // Log errors to the console
            handleAxiosErrors(err);
        }
    };

type CreateSupplierContactParams = {
    newContact: CreateSupplierContact;
    supplierId: string;
};

export const createSupplierContact =
    ({ newContact, supplierId }: CreateSupplierContactParams) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError());
        dispatch(supplierContactPostLoading());
        try {
            const res = await getGatewayApiInstance().post(`/suppliers/${supplierId}/contacts`, newContact);
            dispatch(supplierContactCreated(res.data));
            dispatch(notificationUpdated('Supplier contact was successfully created'));
            dispatch(redirectLinkUpdated(`/suppliers/${supplierId}#contacts`));
        } catch (err: unknown) {
            dispatch(supplierContactPostError());
            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
            // Log errors to the console
            handleAxiosErrors(err);
        }
    };

type EditSupplierManagerParams = {
    newManager: EditManager;
    supplierId: string;
    managerId: number;
};

export const editSupplierManager =
    ({ newManager, supplierId, managerId }: EditSupplierManagerParams) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError());
        dispatch(supplierManagerPatchLoading());
        try {
            const res = await getGatewayApiInstance().put(
                `/suppliers/${supplierId}/internal-managers/${managerId}`,
                newManager
            );
            dispatch(supplierManagerUpdated(res.data));
            dispatch(notificationUpdated('Supplier manager was successfully updated'));
            dispatch(redirectLinkUpdated(`/suppliers/${supplierId}#managers`));
        } catch (err: unknown) {
            dispatch(supplierManagerPatchError());
            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
            // Log errors to the console
            handleAxiosErrors(err);
        }
    };


export const createSupplierManager =
    ({ newManager, supplierId }: { newManager: CreateManager; supplierId: string }) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError());
        dispatch(supplierManagerPostLoading());
        try {
            const res = await getGatewayApiInstance().post(`/suppliers/${supplierId}/internal-managers`, newManager);
            dispatch(supplierManagerCreated(res.data));
            dispatch(notificationUpdated('Supplier manager was successfully created'));
            dispatch(redirectLinkUpdated(`/suppliers/${supplierId}#managers`));
        } catch (err: unknown) {
            dispatch(supplierManagerPostError());
            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
            handleAxiosErrors(err);
        }
    };

export const editSupplierNotes =
    ({ newNotes, supplierId }: { newNotes: EditSupplierNotes; supplierId: string }) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError());
        dispatch(supplierNotesPatchLoading());
        try {
            const res = await getGatewayApiInstance().patch(`/suppliers/${supplierId}`, newNotes);
            dispatch(notificationUpdated('Supplier notes were successfully updated'));
            dispatch(supplierNotesUpdated(res.data));
        } catch (err: unknown) {
            dispatch(supplierNotesPatchError());
            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
            handleAxiosErrors(err);
        }
    };

// Contract actions

export const activateContract =
    (contractStatus: {status: ContractBase['status']}, { supplierId, contractId }: { supplierId: string; contractId: string }) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError());
        dispatch(contractStatusPatchLoading());
        try {
            const res = await getGatewayApiInstance().patch(
                `/suppliers/${supplierId}/contracts/${contractId}`,
                contractStatus
            );
            dispatch(contractStatusUpdated(res.data));
        } catch (err: unknown) {
            dispatch(contractStatusPatchError());
            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
            handleAxiosErrors(err);
        }
    };

export const duplicateContract =
    ({ supplierId, contractId }: { supplierId: string; contractId: string }) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError());
        dispatch(contractDuplicateLoading());
        dispatch(notificationUpdated('Duplicating contract'));
        try {
            const res = await getGatewayApiInstance().put(
                `/suppliers/${supplierId}/contracts/${contractId}/duplicate`
            );

            dispatch(contractDuplicated(res.data));
            dispatch(notificationUpdated('contract was successfully duplicated'));

        } catch (err: unknown) {
            dispatch(contractDuplicateError());
            dispatch(notificationUpdated('Failed to duplicate contract'));

            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
            handleAxiosErrors(err);
        }
    };

type CommissionContractURLParams = { supplierId: string; contractId: number; newContract: EditCommissionContract };

export const editCommissionContract =
    ({ supplierId, contractId, newContract }: CommissionContractURLParams) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError());
        dispatch(commissionContractPatchLoading());
        try {
            const res = await getGatewayApiInstance().patch(
                `/suppliers/${supplierId}/contracts/${contractId}`,
                newContract
            );
            dispatch(commissionContractUpdated(res.data));
            dispatch(notificationUpdated('Commission contract was successfully updated'));
            dispatch(redirectLinkUpdated(`/suppliers/${supplierId}/commission-contract/${contractId}`));
        } catch (err: unknown) {
            dispatch(commissionContractPatchError());
            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
            // Log errors to the console
            handleAxiosErrors(err);
        }
    };

export const createCommissionContract =
    ({ supplierId, newCommissionContract }: { supplierId: string; newCommissionContract: CreateCommissionContract }) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError());
        dispatch(commissionContractPostLoading());
        try {
            const res = await getGatewayApiInstance().post(`/suppliers/${supplierId}/contracts`, newCommissionContract);
            dispatch(commissionContractCreated(res.data));
            dispatch(notificationUpdated('Commission contract was successfully created'));
            dispatch(redirectLinkUpdated(`/suppliers/${res.data.id}#contracts`));
        } catch (err: unknown) {
            dispatch(commissionContractPostError());
            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
            // Log errors to the console
            handleAxiosErrors(err);
        }
    };

type PurchaseContractURLParams = { supplierId: string; contractId: number; newContract: EditPurchaseContract };

export const editPurchaseContract =
    ({ supplierId, contractId, newContract }: PurchaseContractURLParams) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError());
        dispatch(purchaseContractPatchLoading());
        try {
            const res = await getGatewayApiInstance().patch(
                `/suppliers/${supplierId}/contracts/${contractId}`,
                newContract
            );
            dispatch(purchaseContractUpdated(res.data));
            dispatch(notificationUpdated( 'Purchase contract was successfully updated'));
            dispatch(redirectLinkUpdated(`/suppliers/${supplierId}/purchase-contract/${contractId}`));
        } catch (err: unknown) {
            dispatch(purchaseContractPatchError());
            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
             // Log errors to the console
             handleAxiosErrors(err);
        }
    };

export const createPurchaseContract =
    ({ supplierId, newPurchaseContract }: { supplierId: string; newPurchaseContract: CreatePurchaseContract }) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError());
        dispatch(purchaseContractPostLoading());
        try {
            const res = await getGatewayApiInstance().post(`/suppliers/${supplierId}/contracts`, newPurchaseContract);
            dispatch(purchaseContractCreated(res.data));
            dispatch(notificationUpdated('Purchase contract was successfully created'));
            dispatch(redirectLinkUpdated(`/suppliers/${res.data.id}#contracts`));
        } catch (err: unknown) {
            dispatch(purchaseContractPostError());
            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
             // Log errors to the console
             handleAxiosErrors(err);
        }
    };

// Commission Contact SKU Fees
export const exportCommissionContactSkuFees =
    ({ supplierId }: { supplierId: string; }) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError());
        try {
            const res = await getGatewayApiInstance().get(`/suppliers/${supplierId}/sku-commissions/csv`);
            if(res.data) downloadAsCSV(res.data, `sku_fees_${getRandomShortId()}.csv`)
            dispatch(notificationUpdated('Successfully exported commission contact SKU fees.'));
        } catch (err: unknown) {
            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
             // Log errors to the console
             handleAxiosErrors(err);
             dispatch(errorNotificationUpdated('Failed to export commission contact SKU fees.'));
        }
    };
    
export const importCommissionContactSkuFees =
    ({ supplierId, fileName, fileContents }: { supplierId: string; fileName: string; fileContents: string; }) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError());
        try {
            const res = await getGatewayApiInstance().post(`/suppliers/${supplierId}/sku-commission-batches`, {fileName, fileContents});
            
            if(res.data.status !== 'created') {
                throw new Error('Received wrong status in response')
            }

            dispatch(notificationUpdated('Commission contact SKU fees is importing'));
        } catch (err: unknown) {
            if (axios.isAxiosError(err) && err.response) {
                dispatch(getError({
                    errData: err.response.data,
                    errStatus: err.response.status
                }));
            }
             // Log errors to the console
             handleAxiosErrors(err);
             dispatch(errorNotificationUpdated('Failed to import commission contact SKU fees.'));
        }
    };

// SELECTORS
// Data
export const selectSupplierDetails = (state: State): null | SupplierDetails => state.supplierDetails;
export const selectSupplierNumber = (state: State): undefined | SupplierDetails['supplierNumber'] => state.supplierDetails?.supplierNumber;
export const selectSupplierIban = (state: State): undefined | SupplierInvoicing['iban'] => state.supplierDetails?.invoicing.iban;
export const selectSupplierName = (state: State): undefined | SupplierDetails['supplierName'] => state.supplierDetails?.supplierName;
export const selectSupplierSku = (state: State): undefined | SupplierDetails['skuPrefix'] => state.supplierDetails?.skuPrefix;
export const selectSupplierInvoicing = (state: State): undefined | SupplierInvoicing => state.supplierDetails?.invoicing;
export const selectSupplierManagers = (state: State): undefined | SupplierManager[] => state.supplierDetails?.internalManagers;
export const selectSupplierManagerById = (state: State, id: number | null): undefined | SupplierManager => state.supplierDetails?.internalManagers.find((manager) => manager.id === id);
export const selectSupplierContacts = (state: State): undefined | SupplierContact[] => state.supplierDetails?.contacts;
export const selectSupplierContactById = (state: State, id: number | null): undefined | SupplierContact => state.supplierDetails?.contacts.find((contact) => contact.id === id);
export const selectSupplierAddresses = (state: State): undefined | SupplierAddress[] => state.supplierDetails?.addresses;
export const selectSupplierReturnAddresses = (state: State): undefined | SupplierAddress[] => state.supplierDetails?.addresses.filter((address) => address.type === 'return');
export const selectSupplierAddressById = (state: State, id: number | null): undefined | SupplierAddress => state.supplierDetails?.addresses.find((address) => address.id === id);
export const selectContractsOverview = (state: State): undefined | ContractOverview[] => {
    const contracts = state.supplierDetails?.contracts;
    if (!contracts) return undefined;
    return contracts.map((contract) => ({
        id: contract.id,
        term: {
            beginDate: contract.term.beginDate,
            endDate: contract.term.endDate,
        },
        model: contract.model,
        marketplaceCodes: contract.marketplaceCodes,
        status: contract.status,
    }));
};
export const selectContractById = (state: State, id: number | null): undefined | Contract => state.supplierDetails?.contracts.find((contract) => contract.id === id);
export const selectSupplierNotes = (state: State): string => state.supplierDetails?.remarks || '';
// Loading
export const selectIsLoadingSupplierDetails = (state: State): boolean => state.isLoading;
export const selectIsLoadingSupplierGeneralDetails = (state: State): boolean => state.isLoadingGeneralDetails;
export const selectIsLoadingSupplierNumber = (state: State): boolean => state.isLoadingPatchNumber;
export const selectIsLoadingPatchSupplierIban = (state: State): boolean => state.isLoadingPatchIban;
export const selectIsLoadingPatchSupplierAddress = (state: State): boolean => state.isLoadingPatchSupplierAddress;
export const selectIsLoadingPostSupplierAddress = (state: State): boolean => state.isLoadingPostSupplierAddress;
export const selectIsLoadingPatchSupplierContact = (state: State): boolean => state.isLoadingPatchSupplierContact;
export const selectIsLoadingPostSupplierContact = (state: State): boolean => state.isLoadingPostSupplierContact;
export const selectIsLoadingPatchSupplierManager = (state: State): boolean => state.isLoadingPatchSupplierManager;
export const selectIsLoadingPostSupplierManager = (state: State): boolean => state.isLoadingPostSupplierManager;
export const selectIsLoadingPatchSupplierNotes = (state: State): boolean => state.isLoadingPatchSupplierNotes;
export const selectIsLoadingPatchCommissionContract = (state: State): boolean => state.isLoadingPatchCommissionContract;
export const selectIsLoadingPostCommissionContract = (state: State): boolean => state.isLoadingPostCommissionContract;
export const selectIsLoadingPatchPurchaseContract = (state: State): boolean => state.isLoadingPatchPurchaseContract;
export const selectIsLoadingPostPurchaseContract = (state: State): boolean => state.isLoadingPostPurchaseContract;
export const selectIsLoadingPatchContractStatus = (state: State): boolean => state.isLoadingPatchBaseContract;
// Errors
export const selectHasSupplierDetailsError = (state: State): boolean => state.hasError;
export const selectHasSupplierGeneralDetailsError = (state: State): boolean => state.hasGeneralDetailsError;
export const selectHasSupplierNumberError = (state: State): boolean => state.hasNumberPatchError;
export const selectHasSupplierIbanPatchError = (state: State): boolean => state.hasIbanPatchError;
export const selectHasSupplierAddressPatchError = (state: State): boolean => state.hasSupplierAddressPatchError;
export const selectHasSupplierAddressPostError = (state: State): boolean => state.hasSupplierAddressPostError;
export const selectHasSupplierContactPatchError = (state: State): boolean => state.hasSupplierContactPatchError;
export const selectHasSupplierContactPostError = (state: State): boolean => state.hasSupplierContactPostError;
export const selectHasSupplierManagerPatchError = (state: State): boolean => state.hasSupplierManagerPatchError;
export const selectHasSupplierManagerPostError = (state: State): boolean => state.hasSupplierManagerPostError;
export const selectHasSupplierNotesPatchError = (state: State): boolean => state.hasSupplierNotesPatchError;
export const selectHasCommissionContractPatchError = (state: State): boolean => state.hasCommissionContractPatchError;
export const selectHasCommissionContractPostError = (state: State): boolean => state.hasCommissionContractPostError;
export const selectHasPurchaseContractPatchError = (state: State): boolean => state.hasPurchaseContractPatchError;
export const selectHasPurchaseContractPostError = (state: State): boolean => state.hasPurchaseContractPostError;
export const selectHasContractStatusPatchError = (state: State): boolean => state.hasContractStatusPatchError;

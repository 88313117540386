import React, { useState } from 'react';

// To be used with boolean data: converting string values (from e.target) to boolean state to match the data shape in the backend
export const useRadioInputGroup = (initialValue: boolean) => {
    const [value, setValue] = useState(initialValue);

    type YesNo = 'yes' | 'no';

    const toBoolean = (string: YesNo) => {
        if (string === 'yes') return true;
        return false;
    };

    const toString = (bool: boolean) => {
        if (bool) return 'yes';
        return 'no';
    };

    const stringValue = toString(value);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(toBoolean(e.target.value as YesNo));
    };

    return { value, stringValue, handleChange };
};

// import { getSpreadsheetData } from '@app/utils/googleapis';
import { useState } from 'react';

export const useImportSkuGoogleSheet = () => {
    const [showImportDialog, setShowImportDialog] = useState<boolean>(false);
    const toggleImportDialog = () => setShowImportDialog(!showImportDialog);

    const [spreadsheetId, setSpreadsheetId] = useState<string>('');
    const onChangeSpreadsheetId = (e: React.ChangeEvent<HTMLInputElement>) => setSpreadsheetId(e.target.value);

    const onImportSpreadsheet = async () => {
        if (!spreadsheetId) return;

        try {
            // await getSpreadsheetData(spreadsheetId);
        } catch (error) {
            console.error('Error importing spreadsheet:', error);
        }
    };

    return {
        showImportDialog,
        toggleImportDialog,
        spreadsheetId,
        onChangeSpreadsheetId,
        onImportSpreadsheet,
    };
};

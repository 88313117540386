import React from 'react';
import { useAppSelector } from '@app/redux/typed-hooks';
import { selectSupplierInvoicing } from '@app/redux/reducers';
import { Heading } from '@app/components/shared';

export const Financial = () => {
    const invoicing = useAppSelector(selectSupplierInvoicing);
    if (!invoicing) return <p>There is no financial information for this supplier.</p>;
    const {
        invoiceAddressName,
        invoiceAddressStreet,
        invoiceAddressHouseNumber,
        invoiceAddressSuffix,
        invoiceAddressPostalCode,
        invoiceAddressCity,
        invoiceAddressCountryCode,
        iban,
        vatNumber,
        invoiceLanguage,
        invoiceEmailAddresses,
    } = invoicing;

    return (
        <>
            <Heading level={2} text='Financial' />
            <p>
                {invoiceAddressName}
                <br />
                {`${invoiceAddressStreet} ${invoiceAddressHouseNumber} ${invoiceAddressSuffix}`}
                <br />
                {`${invoiceAddressPostalCode} ${invoiceAddressCity}`}
                <br />
                {invoiceAddressCountryCode}
                <br />
                <br />
                IBAN (bank account): {iban}
                <br />
                VAT number: {vatNumber}
                <br />
                Invoice language country code: {invoiceLanguage}
                <br />
                Invoice email addresses: {invoiceEmailAddresses.length > 0 && invoiceEmailAddresses.join(', ')}
            </p>
        </>
    );
};

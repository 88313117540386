export const downloadAsCSV = (csvData: string, fileName = 'document.csv') => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);

    a.click();
};

import React from 'react';
import { Alert } from '@mui/material';
import styles from './priceUploadHeadersError.module.scss';

interface PriceUploadHeadersErrorProps {
    validHeaders: string[];
}

export const PriceUploadHeadersError = ({ validHeaders }: PriceUploadHeadersErrorProps) => {
    const validHeadersListItems = validHeaders.map((header) => (
        <React.Fragment key={header}>
            <li>{header}</li>
        </React.Fragment>
    ));

    return (
        <Alert severity='warning'>
            <p className={styles.errorHeader}>
                <strong>
                    The file does not have correct headers. Make sure the file has the following headers and re-upload:
                </strong>
            </p>
            <ul className={styles.headersList}>{validHeadersListItems}</ul>
            <p>
                <strong>Note:</strong> Headers are case-<b>in</b>sensitive.
            </p>
        </Alert>
    );
};

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '@mui/material/Button';
import { ActionMenu, Dialog } from '../../../shared';
import { ActivateButton, CancelButton } from '../../../shared/Buttons';

interface ContractActionMenuProps {
    contractModel: Contract['model'];
    contractCanBeActivated: Contract['canActivate'];
    handleActivateContract: () => void;
    handleDuplicateContract: () => void;
}

export const ContractActionMenu = ({
    contractModel,
    contractCanBeActivated,
    handleActivateContract,
    handleDuplicateContract,
}: ContractActionMenuProps) => {
    const navigate = useNavigate();
    const { supplierId, contractId } = useParams();
    const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
    const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);

    const handleEditClick = () => {
        navigate(`/suppliers/${supplierId}/edit-${contractModel}-contract/${contractId}`);
    };
    const goToSupplierDetailsPage = () => {
        navigate(`/suppliers/${supplierId}`);
    };

    const toggleActivateDialog = () => {
        setIsActivateDialogOpen(!isActivateDialogOpen);
    };
    const toggleDuplicateDialog = () => {
        setIsDuplicateDialogOpen(!isDuplicateDialogOpen);
    };

    const menuItems = [
        {
            text: 'Edit',
            icon: <EditIcon />,
            handler: handleEditClick,
        },
        {
            text: 'Activate contract',
            icon: <CheckIcon />,
            handler: toggleActivateDialog,
            isDisabled: !contractCanBeActivated,
        },
        {
            text: 'Duplicate contract',
            icon: <ContentCopyIcon />,
            handler: toggleDuplicateDialog,
            isDisabled: contractCanBeActivated,
        },
    ];

    return (
        <>
            <ActionMenu menuItems={menuItems} />
            <Dialog dialogOpen={isActivateDialogOpen} titleText='Are you sure that you want to activate this contract?'>
                <CancelButton handleClick={toggleActivateDialog} />
                <ActivateButton
                    handleClick={() => {
                        toggleActivateDialog();
                        handleActivateContract();
                    }}
                />
            </Dialog>
            <Dialog
                dialogOpen={isDuplicateDialogOpen}
                titleText='Are you sure that you want to duplicate this contract?'
            >
                <CancelButton handleClick={toggleDuplicateDialog} />
                <Button
                    variant='outlined'
                    color='success'
                    onClick={() => {
                        toggleDuplicateDialog();
                        handleDuplicateContract();
                        goToSupplierDetailsPage();
                    }}
                >
                    Duplicate
                </Button>
            </Dialog>
        </>
    );
};

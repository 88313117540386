import React from 'react';
import { GridOverlay } from '@mui/x-data-grid';

interface DataGridNoRowsOverlayProps {
    text: string;
}

export const DataGridNoRowsOverlay = ({ text }: DataGridNoRowsOverlayProps) => (
    <GridOverlay>
        <p>{text}</p>
    </GridOverlay>
);

/**
    USAGE:

    <DataGrid
        {...}
        components={{
            NoRowsOverlay: () => <DataGridNoRowsOverlay text='Some text' />, // Use this prop when there is no rows passed to the DataGrid (rows={[]}).
            NoResultsOverlay: () => <DataGridNoRowsOverlay text='Some text' /> // Use this prop when there is row data in the DataGrid, but the local filter returns no result
        }}
    />
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

type AxiosErrorResponse = {
    errData: AxiosResponse['data'];
    errStatus: AxiosResponse['status'];
};

const initialState = {
    errData: null as null | AxiosErrorResponse['errData'],
    errStatus: null as null | AxiosErrorResponse['errStatus'],
};

type State = typeof initialState;

const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        getError(state, action: PayloadAction<AxiosErrorResponse>) {
            const { errData, errStatus } = action.payload;
            return {
                ...state,
                errData,
                errStatus,
            };
        },
        clearError() {
            return initialState;
        },
    },
});

export default errorSlice.reducer;

export const { getError, clearError } = errorSlice.actions;

// SELECTORS
export const selectError400Msg = (state: State): string => {
    if (state.errStatus === 400) {
        const { error } = state.errData;
        if (typeof error === 'string') return error;
        return 'Could not read error message';
    }
    return '';
};

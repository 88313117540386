import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Dialog } from '@app/components/shared';
import { useImportSkuGoogleSheet } from './useImportSkuGoogleSheet';

export const ImportSkuGoogleSheet = () => {
    const { showImportDialog, toggleImportDialog, spreadsheetId, onChangeSpreadsheetId, onImportSpreadsheet } =
        useImportSkuGoogleSheet();

    return (
        <>
            <Button onClick={toggleImportDialog}>Import</Button>

            <Dialog titleText='Import Google spreadsheet' dialogOpen={showImportDialog} onClose={toggleImportDialog}>
                <Box sx={{ p: 2 }}>
                    <Grid container alignItems='center' gap={2}>
                        <Grid item>
                            <TextField
                                label='Google spreadsheet ID'
                                placeholder='Google spreadsheet ID'
                                value={spreadsheetId}
                                onChange={onChangeSpreadsheetId}
                            />
                        </Grid>

                        <Grid item>
                            <Button variant='contained' color='success' onClick={onImportSpreadsheet}>
                                Import
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </>
    );
};

import React from 'react';
import { Heading } from '../../../shared';
import { capitalize } from '../../../../utils/formatting';
import styles from './contractLogisticsCommissionModel.module.scss';

interface ContractLogisticsCommissionModelProps {
    logistics: CommissionModel['outbound'];
}

export const ContractLogisticsCommissionModel = ({ logistics }: ContractLogisticsCommissionModelProps) => {
    const { setup, logisticsProvider } = logistics;

    return (
        <>
            <Heading level={2} text='Logistics' />
            <dl className={styles.list}>
                <dt>Setup:</dt>
                <dd>{setup ? capitalize(setup) : ''}</dd>
                <dt>Logistics provider:</dt>
                <dd>{logisticsProvider ? capitalize(logisticsProvider) : ''}</dd>
            </dl>
        </>
    );
};

import * as React from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import BreadcrumbsComponent from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useAppSelector } from '../../redux/typed-hooks';
import { selectSupplierName } from '../../redux/reducers';
import { getRoutes } from '../../utils/routing';
import styles from './breadcrumbs.module.scss';

export const Breadcrumbs = () => {
    const supplierName = useAppSelector(selectSupplierName);

    const location = useLocation();
    const { pathname } = location;
    const pathnames = pathname.split('/').filter((x) => x);

    // Routes without the 404 page
    const namedRoutes = getRoutes(supplierName || 'Supplier').filter((route) => route.path !== '*');

    const breadcrumbsList = pathnames
        .map((name, index, self) => {
            const routeTo = `/${self.slice(0, index + 1).join('/')}`;

            // Check if the current matches any of the pre-defined / named routes
            const matchingRoutes = namedRoutes.filter((namedRoute) => {
                const match = matchPath({ path: namedRoute.path, end: true }, routeTo);
                return match !== null;
            });

            let humanReadablePathname = '';
            if (matchingRoutes.length > 0) {
                // If more than one route is matching, get the exact route rather than the route with params
                humanReadablePathname = matchingRoutes[0].name;
            }

            // Do not render a link for unnamed routes parts: E.g. /suppliers/30/edit-manager/34 is named, while /suppliers/30/edit-manager (without :managerId) is not a named route
            if (!humanReadablePathname) return null;

            const currentPage = index === pathnames.length - 1;

            if (currentPage) return <span key={name}>{humanReadablePathname}</span>;
            return (
                <Link key={name} to={`${routeTo}`} className={styles.link}>
                    {humanReadablePathname}
                </Link>
            );
        })
        .filter((breadcrumb) => breadcrumb !== null);

    if (breadcrumbsList.length <= 1) return null;
    return (
        <BreadcrumbsComponent separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
            {breadcrumbsList}
        </BreadcrumbsComponent>
    );
};

import React from 'react';
import { FormTemplate } from '../../templates';
import { Fieldset } from '../../shared/Form';
import { CheckboxGroup, CheckboxInput, DateInput, SelectInput, NumberInput } from '../../shared/Form/Inputs';
import { useCreateCommissionContract } from './useCreateCommissionContract';
import { commissionContractOptions } from '../../../utils/constants/contract-constants';
import styles from './createCommissionContract.module.scss';

export const CreateCommissionContract = () => {
    const {
        beginDate,
        endDate,
        isMarketplaceNL,
        isMarketplaceDE,
        invoicingCutOffDate,
        paymentDueDate,
        feePercentage,
        handleSubmit,
        formErrors,
        isLoading,
        hasError,
    } = useCreateCommissionContract();

    return (
        <FormTemplate handleSubmit={handleSubmit} isSubmitting={isLoading} hasError={hasError}>
            <div className={styles.createContract__fieldsetFlexContainer}>
                <div className={styles.createContract__fieldset}>
                    <Fieldset legend='General'>
                        <DateInput
                            label='Start date'
                            value={beginDate.value}
                            error={formErrors.beginDate !== ''}
                            helperText={formErrors.beginDate}
                            handleChange={(newDate) => beginDate.handleChange(newDate)}
                        />
                        <DateInput
                            label='End date'
                            value={endDate.value}
                            handleChange={(newDate) => endDate.handleChange(newDate)}
                        />
                        <CheckboxGroup
                            label='Marketplaces'
                            error={formErrors.marketplaceCodes !== ''}
                            helperText={formErrors.marketplaceCodes}
                        >
                            <CheckboxInput
                                label='NL'
                                checked={isMarketplaceNL.value}
                                handleChange={isMarketplaceNL.handleChange}
                            />
                            <CheckboxInput
                                label='DE'
                                checked={isMarketplaceDE.value}
                                handleChange={isMarketplaceDE.handleChange}
                            />
                        </CheckboxGroup>
                    </Fieldset>
                </div>
                <div className={styles.createContract__fieldset}>
                    <Fieldset legend='Payments'>
                        <SelectInput
                            label='Invoicing cut-off date'
                            options={commissionContractOptions.invoicingCutOffDate}
                            value={invoicingCutOffDate.value}
                            handleChange={invoicingCutOffDate.handleChange}
                        />

                        <SelectInput
                            label='Payment due date'
                            options={commissionContractOptions.paymentDueDate}
                            value={paymentDueDate.value}
                            handleChange={paymentDueDate.handleChange}
                        />
                        <NumberInput
                            variant='percentage'
                            label='Fee percentage'
                            value={feePercentage.value}
                            handleChange={feePercentage.handleChange}
                            error={formErrors.feePercentage !== ''}
                            helperText={formErrors.feePercentage}
                        />
                    </Fieldset>
                </div>
            </div>
        </FormTemplate>
    );
};

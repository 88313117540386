import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Heading } from '../../../shared';

interface PriceUploadPreviewProps {
    headers: string[];
    products: Product[];
}

export const PriceUploadPreview = ({ headers, products }: PriceUploadPreviewProps) => {
    const tableHeaders = headers.map((header) => (
        <React.Fragment key={header}>
            <TableCell>{header}</TableCell>
        </React.Fragment>
    ));

    const tableRows = products
        .filter((_product, i) => i < 10) // Show first 10 entries
        .map((product, i) => {
            return (
                // Using array index as key as there is no other unique identifier available. This part of the UI is not editable so using an index should not introduce any bugs
                /* eslint-disable react/no-array-index-key */
                <TableRow key={`product-${i}`}>
                    <TableCell>{product.marketplace}</TableCell>
                    <TableCell>{product.suppliernumber}</TableCell>
                    <TableCell>{product.sku}</TableCell>
                    <TableCell>{product.startdate}</TableCell>
                    <TableCell>{product.costprice}</TableCell>
                    <TableCell>{product.shipmentcostsnl}</TableCell>
                    <TableCell>{product.shipmentcostsbe}</TableCell>
                    <TableCell>{product.shipmentcostsde}</TableCell>
                </TableRow>
            );
        });

    return (
        <>
            <Box sx={{ marginBottom: '20px' }}>
                <Heading level={2} text='Preview' />
                <p>Showing first 10 entries...</p>
            </Box>
            <TableContainer>
                <Table size='small' aria-label='Preview uploaded prices'>
                    <TableHead
                        sx={{
                            '& .MuiTableCell-head': {
                                fontWeight: '700',
                            },
                        }}
                    >
                        <TableRow>{tableHeaders}</TableRow>
                    </TableHead>
                    <TableBody>{tableRows}</TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

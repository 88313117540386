import React from 'react';
import { useAppSelector } from '../../../redux/typed-hooks';
import { selectSupplierNumber } from '../../../redux/reducers';
import { Error, Loader } from '../../shared';
import { useFetchSupplierDetails } from '../../SupplierDetails/useFetchSupplierDetails';
import { EditSupplierNumberForm } from './subcomponents/EditSupplierNumberForm';

export const EditSupplierNumber = () => {
    const { isLoading, hasError } = useFetchSupplierDetails();
    const supplierNum = useAppSelector(selectSupplierNumber);

    if (isLoading) return <Loader />;
    if (hasError) return <Error />;
    return <EditSupplierNumberForm supplierNum={supplierNum || null} />;
};

import React from 'react';
import { toDateTime } from '../../../utils/formatting';
import styles from './footer.module.scss';

interface FooterProps {
    createdAt: string;
    updatedAt: string;
}

export const Footer = ({ createdAt, updatedAt }: FooterProps) => {
    return (
        <footer className={styles.footer}>
            <hr className={styles.footer__hr} />
            <p>
                Created: {toDateTime(createdAt)}
                <br />
                Updated: {toDateTime(updatedAt)}
            </p>
        </footer>
    );
};

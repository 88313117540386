import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/typed-hooks';
import { selectSupplierContactById } from '../../../redux/reducers';
import { Error, Loader } from '../../shared';
import { EditContactForm } from './subcomponents/EditContactForm';
import { useFetchSupplierDetails } from '../../SupplierDetails/useFetchSupplierDetails';
import { toInteger } from '../../../utils/formatting';

export const EditContact = () => {
    const { isLoading, hasError } = useFetchSupplierDetails();
    const { contactId } = useParams();
    const contactToEditId = contactId ? toInteger(contactId) : null;
    const contact = useAppSelector((state) => selectSupplierContactById(state, contactToEditId));

    if (isLoading) return <Loader />;
    if (hasError) return <Error />;
    if (!contact) return <p>There is no contact information for this supplier.</p>;
    return <EditContactForm contact={contact} />;
};

import React from 'react';
import { Chip } from '@mui/material';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import { capitalize } from '../../../../utils/formatting';
import styles from './contractStatus.module.scss';

interface ContractStatusProps {
    version: Contract['id'];
    status: Contract['status'];
}

export const ContractStatus = ({ version, status }: ContractStatusProps) => {
    type Color = 'default' | 'success' | 'warning' | 'info' | 'error' | 'primary' | 'secondary' | undefined;

    let icon;
    let chipColor = 'default';
    if (status === 'active') {
        icon = <NotificationsActiveIcon />;
        chipColor = 'success';
    } else if (status === 'draft') {
        icon = <EditNotificationsIcon />;
        chipColor = 'warning';
    } else if (status === 'archived') {
        icon = <NotificationsPausedIcon />;
        chipColor = 'info';
    } else if (status === 'inactive') {
        icon = <NotificationsOffIcon />;
        chipColor = 'error';
    }
    return (
        <div className={styles.status}>
            <Chip label={capitalize(status)} icon={icon} color={chipColor as Color} />
            <div className={styles.status__version}>
                <i>Version {version}</i>
            </div>
        </div>
    );
};

import React from 'react';
import { PageTemplate } from '../../components/templates';
import { HomeButton } from '../../components/shared/Buttons';
import styles from './404.module.scss';

export const FourOhFourPage = () => {
    return (
        <PageTemplate headerText='404' hasBackButton hasXYCenteredContent>
            <div>
                <p className={styles.text}>
                    <b>Knock knock</b>
                    <br /> Who&apos;s there?
                    <br /> <b>Error.</b>
                    <br />
                    Error Who?
                    <br /> <b>Error 404: Punchline not found.</b>
                </p>
                <HomeButton />
            </div>
        </PageTemplate>
    );
};

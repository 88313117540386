import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/typed-hooks';
import { editSupplierManager, clearSupplierDetailsPatchErrors } from '../../../../redux/reducers/supplierDetails';
import { selectHasSupplierManagerPatchError, selectIsLoadingPatchSupplierManager } from '../../../../redux/reducers';
import { useSelectInput, useTextInput } from '../../../shared/Form/Inputs';
import { useRedirect } from '../../../../hooks';
import { objectHasNonEmptyValue, validateRequiredField } from '../../../../utils/validation';

export const useEditManagerForm = (manager: SupplierManager) => {
    const name = useTextInput(manager.name);
    const role = useSelectInput(manager.role);
    const isLoading = useAppSelector(selectIsLoadingPatchSupplierManager);
    const hasError = useAppSelector(selectHasSupplierManagerPatchError);
    const dispatch = useAppDispatch();
    const { supplierId } = useParams();
    const [formErrors, setFormErrors] = useState({ name: '', role: '' });

    // Redirect on successful API call
    useRedirect();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newError = {
            name: validateRequiredField({ value: name.value, fieldName: 'Name' }),
            role: validateRequiredField({ value: role.value, fieldName: 'Role' }),
        };
        setFormErrors(newError);
        if (objectHasNonEmptyValue(newError)) return;

        const newManager: EditManager = {
            name: name.value,
            role: role.value as SupplierManager['role'],
        };

        if (supplierId) {
            dispatch(editSupplierManager({ newManager, supplierId, managerId: manager.id }));
        }
    };

    useEffect(() => {
        dispatch(clearSupplierDetailsPatchErrors());
    }, [dispatch]);

    return { formErrors, name, role, handleSubmit, isLoading, hasError };
};

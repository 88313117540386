import React from 'react';
import { Header } from '../../shared';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';
import styles from './pageTemplate.module.scss';

type PageTemplateProps = {
    headerText: string;
    hasBackButton: boolean;
    hasXYCenteredContent?: boolean;
    children: React.ReactNode;
};

export const PageTemplate: React.FC<PageTemplateProps> = ({
    headerText,
    hasBackButton,
    hasXYCenteredContent = false,
    children,
}) => {
    return (
        <section className={styles.page}>
            <div className={styles.page__content}>
                <div className={styles.page__breadcrumbs}>
                    <Breadcrumbs />
                </div>
                <Header text={headerText} hasBackButton={hasBackButton} />
                <div className={styles.page__children}>
                    <div className={hasXYCenteredContent ? styles.page__centeredContent : ''}>{children}</div>
                </div>
            </div>
        </section>
    );
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/typed-hooks';
import { selectIsLoadingPostSupplierManager, selectHasSupplierManagerPostError } from '../../../redux/reducers';
import { useSelectInput, useTextInput } from '../../shared/Form/Inputs';
import { createSupplierManager, clearSupplierDetailsPostErrors } from '../../../redux/reducers/supplierDetails';
import { useRedirect } from '../../../hooks';
import { objectHasNonEmptyValue, validateRequiredField } from '../../../utils/validation';

export const useCreateManager = () => {
    const dispatch = useAppDispatch();
    const { supplierId } = useParams();

    const name = useTextInput('');
    const role = useSelectInput('partnerships');
    const isLoading = useAppSelector(selectIsLoadingPostSupplierManager);
    const hasError = useAppSelector(selectHasSupplierManagerPostError);
    const [formErrors, setFormErrors] = useState({ name: '' });

    // Redirect on successful API call
    useRedirect();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newError = {
            name: validateRequiredField({ value: name.value, fieldName: 'Name' }),
        };
        setFormErrors(newError);
        if (objectHasNonEmptyValue(newError)) return;

        const newManager: CreateManager = {
            name: name.value,
            role: role.value as SupplierManager['role'],
        };
        if (supplierId) {
            dispatch(createSupplierManager({ newManager, supplierId }));
        }
    };

    useEffect(() => {
        dispatch(clearSupplierDetailsPostErrors());
    }, [dispatch]);

    return { name, role, handleSubmit, isLoading, formErrors, hasError };
};

import React, { useState, useEffect } from 'react';
import { parse } from 'papaparse';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { selectIsPriceUploadLoading, selectHasPriceUploadError } from '../../redux/reducers';
import { uploadPrices, clearPricesUploadError } from '../../redux/reducers/prices';
import {
    getDecimalCount,
    isCostPriceValid,
    isDateValid,
    isMarketplaceValid,
    isShipmentCostValid,
    isSKUValid,
    isSupplierNumberValid,
    objectHasValidKeys,
    trimIfString,
    validateProductData,
} from '../../utils/validation';

export const usePriceUpload = () => {
    const [file, setFile] = useState<File | null>(null);
    const [parsedFile, setParsedFile] = useState<Product[] | null>(null);
    const [headersError, setHeadersError] = useState(false);
    const [formErrors, setFormErrors] = useState<RowErrors[]>([]);
    const isLoading = useAppSelector(selectIsPriceUploadLoading);
    const hasError = useAppSelector(selectHasPriceUploadError);

    const { supplierId } = useParams();
    const dispatch = useAppDispatch();

    const validHeaders = [
        'Marketplace',
        'SupplierNumber',
        'Sku',
        'StartDate',
        'CostPrice',
        'ShipmentCostsNl',
        'ShipmentCostsBe',
        'ShipmentCostsDe',
    ];

    const validHeadersInLowerCase = validHeaders.map((validHeader) => validHeader.toLowerCase());

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormErrors([]);
        const { files } = e.target;
        if (!files) return;
        // Set the file to send to the backend
        setFile(files[0]);
        const text = await files[0].text();
        // Parse the file with Papaparse
        const result = parse(text, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: (header) => header.trim().toLowerCase(),
        });
        // @ts-ignore Intentionally casting data as `any` to cast as Products[] later on
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const { data }: any = result;
        // Validate file headers
        const firstProduct = data[0];
        // If first product object object has valid headers, it's safe to assume all products will have valid headers:
        const eachProductHasValidHeaders = objectHasValidKeys(firstProduct, validHeadersInLowerCase);
        if (!eachProductHasValidHeaders) {
            setHeadersError(true);
            return;
        }
        setHeadersError(false);
        // Get data only for specified headers and trim values
        const selectedColumnsParsedData = data.map((product: Product) => {
            const {
                marketplace,
                suppliernumber,
                sku,
                startdate,
                costprice,
                shipmentcostsnl,
                shipmentcostsbe,
                shipmentcostsde,
            } = product;

            return {
                marketplace: trimIfString(marketplace),
                suppliernumber: trimIfString(suppliernumber),
                sku: trimIfString(sku),
                startdate: trimIfString(startdate),
                costprice: trimIfString(costprice),
                shipmentcostsnl: trimIfString(shipmentcostsnl),
                shipmentcostsbe: trimIfString(shipmentcostsbe),
                shipmentcostsde: trimIfString(shipmentcostsde),
            };
        });
        // For the preview
        setParsedFile(selectedColumnsParsedData);

        // Validate uploaded data
        const dataErrors = validateProductData(supplierId, selectedColumnsParsedData, {
            getDecimalCount,
            isMarketplaceValid,
            isSupplierNumberValid,
            isSKUValid,
            isDateValid,
            isCostPriceValid,
            isShipmentCostValid,
        });

        setFormErrors(dataErrors);
    };

    /* eslint-disable @typescript-eslint/no-shadow */
    const handleSubmit = (file: File | null, supplierId: string) => {
        return (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (!file || formErrors.length > 0) return;
            // Submit if there is no errors
            dispatch(uploadPrices(file, supplierId));
        };
    };

    useEffect(() => {
        dispatch(clearPricesUploadError());
    }, [dispatch]);

    return {
        handleFileChange,
        handleSubmit,
        file,
        parsedFile,
        validHeaders,
        headersError,
        formErrors,
        isLoading,
        hasError,
    };
};

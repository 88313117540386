import { SkuActions } from '@app/components/EditContract/EditCommissionContract/subcomponents/SkuActions';
import React from 'react';
import { Heading, YesNoField } from '../../../shared';
import styles from './contractPayments.module.scss';

interface ContractPaymentsProps {
    smallBusinessScheme: Contract['smallBusinessScheme'];
    invoicingCutOffDate: Contract['invoicingCutOffDate'];
    paymentDueDate: Contract['paymentDueDate'];
    skontoDiscountPercentage:
        | CommissionModel['skontoDiscountPercentageBasedOnSellingPrice']
        | PurchaseModel['skontoDiscountPercentageBasedOnCostPrice'];
    vatPercentage: CommissionModel['vatPercentage'] | PurchaseModel['vatPercentage'];
    paymentDiscount: CommissionModel['paymentDiscount'] | PurchaseModel['paymentDiscount'];
    ignorePurchaseCosts: Contract['ignorePurchaseCosts'];
    feePercentage?: CommissionModel['feePercentage'];
}

export const ContractPayments = ({
    smallBusinessScheme,
    invoicingCutOffDate,
    paymentDueDate,
    skontoDiscountPercentage,
    vatPercentage,
    paymentDiscount,
    ignorePurchaseCosts,
    feePercentage,
}: ContractPaymentsProps) => {
    return (
        <>
            <Heading level={2} text='Payments' />
            <dl className={styles.list}>
                <dt>Small Business Scheme:</dt>
                <dd>
                    <YesNoField yes={smallBusinessScheme} />
                </dd>
                <dt>Ignore purchase costs:</dt>
                <dd>
                    <YesNoField yes={Boolean(ignorePurchaseCosts)} />
                </dd>
                <dt>Invoicing cut-off date:</dt>
                <dd>{invoicingCutOffDate}</dd>
                <dt>Payment due date:</dt>
                <dd>{paymentDueDate}</dd>
                <dt>Discount percentage:</dt>
                <dd>{skontoDiscountPercentage ? `${skontoDiscountPercentage}%` : '0%'}</dd>
                <dt>Payment discount:</dt>
                <dd>{paymentDiscount ? `${paymentDiscount}%` : '0%'}</dd>
                <dt>VAT percentage:</dt>
                <dd>{vatPercentage ? `${vatPercentage}%` : '0%'}</dd>
                {feePercentage && (
                    <>
                        <dt>Fee percentage:</dt>
                        <dd>{`${feePercentage}%`}</dd>
                    </>
                )}

                <dt>SKU Fees:</dt>
                <dd>
                    <SkuActions />
                </dd>
            </dl>
        </>
    );
};

import axios from 'axios';

export const getGatewayApiInstance = () => {
    return axios.create({
        baseURL: process.env.REACT_APP_GATEWAY_API_URL || 'https://staging.naduvi.io/api/gateway/',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
    });
};

export const handleAxiosErrors = (err: unknown) => {
    // Axios error type guard
    if (axios.isAxiosError(err)) {
        if (err.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            // We are already collecting data and status info in the error reducer
            // console.log(err.response.data);
            // console.log(err.response.status);
            console.log(err.response.headers);
        } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', err.message);
        }
        console.log(err.config);
    }
};

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { getSupplierDetails } from '../../redux/reducers/supplierDetails';
import { selectIsLoadingSupplierDetails, selectHasSupplierDetailsError } from '../../redux/reducers';

export const useFetchSupplierDetails = () => {
    const { supplierId } = useParams();
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoadingSupplierDetails);
    const hasError = useAppSelector(selectHasSupplierDetailsError);

    useEffect(() => {
        if (supplierId) {
            dispatch(getSupplierDetails(supplierId));
        }
    }, [dispatch, supplierId]);

    return {
        isLoading,
        hasError,
    };
};

import React from 'react';
import { Button } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import styles from './fileInput.module.scss';

interface FileInputProps {
    fileName: string | undefined;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FileInput = ({ fileName, handleChange }: FileInputProps) => {
    return (
        <div className={styles.fileInput}>
            {/* Custom file input (no MUI equivalent) */}
            <Button variant='contained' component='label' startIcon={<FileUploadIcon />}>
                Upload File
                <input type='file' onChange={handleChange} accept='.csv' required hidden />
            </Button>
            <p className={styles.fileInput__filename}>{fileName || 'No file selected...'}</p>
        </div>
    );
};

export default FileInput;

import axios from 'axios';
import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { getError, clearError } from './error';
import { notificationUpdated } from './notifications';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';

const initialState = {
    isLoading: false,
    hasError: false,
};

type State = typeof initialState;

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        productsUploadLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        productsUploaded(state) {
            return {
                ...state,
                isLoading: false,
            };
        },
        productsUploadError(state) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
        productsUploadErrorCleared(state) {
            return {
                ...state,
                hasError: false,
            };
        },
    },
});

export default productsSlice.reducer;

const { productsUploadLoading, productsUploaded, productsUploadError } = productsSlice.actions;
export const { productsUploadErrorCleared: clearProductsUploadError } = productsSlice.actions;

export const uploadProducts = (productObject: ProductSheet) => async (dispatch: Dispatch) => {
    dispatch(clearError());
    dispatch(productsUploadLoading());
    try {
        await getGatewayApiInstance().post(`/product-sheets`, productObject);
        dispatch(productsUploaded());
        dispatch(notificationUpdated('Products were successfully uploaded'));
    } catch (err: unknown) {
        dispatch(productsUploadError());
        if (axios.isAxiosError(err) && err.response) {
            dispatch(
                getError({
                    errData: err.response.data,
                    errStatus: err.response.status,
                })
            );
        }
        // Log errors to the console
        handleAxiosErrors(err);
    }
};

// SELECTORS
export const selectIsProductUploadLoading = (state: State): boolean => state.isLoading;
export const selectHasProductUploadError = (state: State): boolean => state.hasError;

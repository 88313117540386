import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import { ActionMenu } from '../../shared';
import { useCheckIfUserHasAdminRights } from '../../../hooks';

export const SupplierDetailsActionMenu = () => {
    const navigate = useNavigate();
    const { supplierId } = useParams();
    const { hasAdminRights } = useCheckIfUserHasAdminRights();

    const handleEditClick = () => {
        navigate(`/suppliers/${supplierId}/edit-general-details`);
    };

    const handleEditSupplierNumberClick = () => {
        navigate(`/suppliers/${supplierId}/edit-supplier-number`);
    };

    const handleEditIbanClick = () => {
        navigate(`/suppliers/${supplierId}/edit-iban`);
    };

    const handlePriceClick = () => {
        navigate(`/suppliers/${supplierId}/price-upload`);
    };

    const handleProductClick = () => {
        navigate(`/suppliers/${supplierId}/product-upload`);
    };

    const menuItems = [
        {
            text: 'Edit',
            icon: <EditIcon />,
            handler: handleEditClick,
        },
        {
            text: 'Edit Supplier Number',
            icon: <EditIcon />,
            handler: handleEditSupplierNumberClick,
        },
        {
            text: 'Edit IBAN',
            icon: <EditIcon />,
            handler: handleEditIbanClick,
            // In this case we are disabling (and not hiding) the button. This is so that managers are aware that this option is available
            isDisabled: !hasAdminRights,
        },
        {
            text: 'Upload Prices',
            icon: <ArchiveIcon />,
            handler: handlePriceClick,
        },
        {
            text: 'Upload Products',
            icon: <ArchiveIcon />,
            handler: handleProductClick,
        },
    ];

    return <ActionMenu menuItems={menuItems} />;
};

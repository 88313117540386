import React, { useRef, useLayoutEffect } from 'react';
import { useAppSelector } from '../../redux/typed-hooks';
import { selectSupplierDetails } from '../../redux/reducers';
import {
    Addresses,
    SupplierDetailsActionMenu,
    SupplierInfo,
    Financial,
    Managers,
    Contacts,
    Contracts,
    Notes,
} from './subComponents';
import { Error, Loader, Footer } from '../shared';
import { useFetchSupplierDetails } from './useFetchSupplierDetails';
import { useCheckIfUserHasManagerRights } from '../../hooks';
import styles from './supplierDetails.module.scss';

export const SupplierDetails = () => {
    const { hasManagerRights } = useCheckIfUserHasManagerRights();
    const supplier = useAppSelector(selectSupplierDetails);
    const { isLoading, hasError } = useFetchSupplierDetails();

    const managersRef = useRef<HTMLElement>(null);
    const contractsRef = useRef<HTMLElement>(null);
    const contactsRef = useRef<HTMLElement>(null);
    const addressesRef = useRef<HTMLElement>(null);

    const scrollRefIntoView = (ref: React.RefObject<HTMLElement>) => {
        if (ref.current) {
            window.scrollTo({
                top: ref.current.offsetTop - 100, // Leave space for the menu bar
                behavior: 'smooth',
            });
        }
    };

    useLayoutEffect(() => {
        // Push to the end of the execution queue
        const timer = setTimeout(() => {
            if (supplier) {
                const { hash } = window.location;
                const refName = hash.substring(1); // Remove leading #
                if (refName === 'managers') {
                    scrollRefIntoView(managersRef);
                } else if (refName === 'contracts') {
                    scrollRefIntoView(contractsRef);
                } else if (refName === 'addresses') {
                    scrollRefIntoView(addressesRef);
                } else if (refName === 'contacts') {
                    scrollRefIntoView(contactsRef);
                }
            }
        }, 0);

        return () => clearTimeout(timer);
    }, [supplier]);

    if (isLoading) return <Loader />;
    if (hasError) return <Error />;
    if (!supplier) return <p>There are no details for this supplier.</p>;

    return (
        <>
            <div className={styles.actionBtnWrapper}>{hasManagerRights && <SupplierDetailsActionMenu />}</div>

            <div className={styles.flexContainer}>
                <section className={`${styles.section} ${styles.flexContainer__item} `}>
                    <SupplierInfo
                        supplierName={supplier.supplierName}
                        supplierNumber={supplier.supplierNumber}
                        skuPrefix={supplier.skuPrefix}
                        isSelfBilling={supplier.isSelfBilling}
                        chamberOfCommerce={supplier.chamberOfCommerce}
                        website={supplier.website}
                    />
                </section>
                <section className={`${styles.section} ${styles.flexContainer__item} `}>
                    <Financial />
                </section>
                <section ref={managersRef} className={`${styles.section} ${styles.flexContainer__item} `}>
                    <Managers />
                </section>
            </div>
            <section ref={contractsRef} className={styles.section}>
                <Contracts />
            </section>
            <section ref={contactsRef} className={styles.section}>
                <Contacts />
            </section>
            <section ref={addressesRef} className={styles.section}>
                <Addresses />
            </section>
            <hr className={styles.hr} />
            <section className={styles.section}>
                <Notes />
            </section>
            <Footer createdAt={supplier.created} updatedAt={supplier.updated} />
        </>
    );
};

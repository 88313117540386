import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/typed-hooks';
import { selectIsLoadingSupplierNumber, selectHasSupplierNumberError } from '../../../../redux/reducers';
import { clearSupplierDetailsPatchErrors, editSupplierNumber } from '../../../../redux/reducers/supplierDetails';
import { useNumberInput } from '../../../shared/Form/Inputs';
import { useRedirect } from '../../../../hooks';
import { objectHasNonEmptyValue, validateRequiredField } from '../../../../utils/validation';

export const useEditSupplierNumberForm = (number: number | null) => {
    const supplierNumber = useNumberInput(number);
    const [formErrors, setFormErrors] = useState({ supplierNumber: '' });
    const isLoading = useAppSelector(selectIsLoadingSupplierNumber);
    const hasError = useAppSelector(selectHasSupplierNumberError);
    const dispatch = useAppDispatch();
    const { supplierId } = useParams();

    // Redirect on successful API call
    useRedirect();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newError = {
            supplierNumber: validateRequiredField({ value: supplierNumber.value, fieldName: 'Supplier number' }),
        };
        setFormErrors(newError);
        if (objectHasNonEmptyValue(newError)) return;

        const newSupplierNumber = {
            supplierNumber: supplierNumber.value as number, // Passed validation so it's safe to cast it as number
        };

        if (supplierId) {
            dispatch(editSupplierNumber({ newSupplierNumber, supplierId }));
        }
    };

    useEffect(() => {
        dispatch(clearSupplierDetailsPatchErrors());
    }, [dispatch]);

    return {
        formErrors,
        supplierNumber,
        handleSubmit,
        isLoading,
        hasError,
    };
};

type PurchaseContractOptions = {
    status: PurchaseContract['status'][];
    invoicingCutOffDate: PurchaseContract['invoicingCutOffDate'][];
    paymentDueDate: PurchaseContract['paymentDueDate'][];
    returnPolicy: PurchaseModel['return']['returnPolicy'][];
    returnLogisticsProvider: PurchaseModel['return']['returnLogisticsProvider'][];
    whoPaysSightReturns: PurchaseModel['return']['whoPaysSightReturns'][];
    whoPaysReturnShippingCostsSupplierFault: PurchaseModel['return']['whoPaysReturnShippingCostsSupplierFault'][];
    outboundSetup: PurchaseModel['outbound']['setup'][];
    logisticsProvider: PurchaseModel['outbound']['logisticsProvider'][];
    whoPaysCrossdock: PurchaseModel['outbound']['whoPaysCrossdock'][];
};

export const purchaseContractOptions: PurchaseContractOptions = {
    status: ['draft', 'active'],
    invoicingCutOffDate: ['invoicing_cut_off_day', 'previous_month_last_day'],
    paymentDueDate: ['current_month_last_day', 'next_month_last_day', 'next_month_7', 'next_month_15'],
    returnPolicy: ['supplier', 'berkenrode', 'timco', 'troostwijk', 'gsd', 'bva', null],
    returnLogisticsProvider: ['supplier', 'naduvi', null],
    whoPaysSightReturns: ['naduvi', 'supplier', null],
    outboundSetup: ['dropshipment', 'cross-dock', null],
    logisticsProvider: ['vendor', 'wuunder', 'dhl/wuunder', 'vlot/wuunder',  'truck-->wuunder', 'truck-->dhl/wuunder', null],
    whoPaysCrossdock: ['supplier', 'naduvi', null],
    whoPaysReturnShippingCostsSupplierFault: ['naduvi', 'supplier'],
};

type CommissionContractOptions = {
    status: CommissionContract['status'][];
    invoicingCutOffDate: CommissionContract['invoicingCutOffDate'][];
    paymentDueDate: CommissionContract['paymentDueDate'][];
    returnPolicy: CommissionModel['return']['returnPolicy'][];
    returnLogisticsProvider: CommissionModel['return']['returnLogisticsProvider'][];
    whoPaysReturnShippingCostsSupplierFault: CommissionModel['return']['whoPaysReturnShippingCostsSupplierFault'][];
    setup: CommissionModel['outbound']['setup'][];
    logisticsProvider: CommissionModel['outbound']['logisticsProvider'][];
};

export const commissionContractOptions: CommissionContractOptions = {
    status: ['draft', 'active'],
    invoicingCutOffDate: ['invoicing_cut_off_day', 'previous_month_last_day'],
    paymentDueDate: ['current_month_last_day', 'next_month_last_day', 'next_month_7', 'next_month_15'],
    returnPolicy: ['supplier', 'berkenrode', 'timco', 'troostwijk', 'gsd', 'bva', null],
    returnLogisticsProvider: ['supplier', 'naduvi', null],
    setup: ['dropshipment', 'cross-dock', null],
    logisticsProvider: ['vendor', 'wuunder', 'dhl/wuunder', 'vlot/wuunder', 'truck-->wuunder', 'truck-->dhl/wuunder', null],
    whoPaysReturnShippingCostsSupplierFault: ['naduvi', 'supplier'],
};

import React from 'react';
import { useAppSelector } from '../../../redux/typed-hooks';
import { selectSupplierIban } from '../../../redux/reducers';
import { Error, Loader } from '../../shared';
import { EditIbanForm } from './subcomponents/EditIbanForm';
import { useFetchSupplierDetails } from '../../SupplierDetails/useFetchSupplierDetails';

export const EditIban = () => {
    const { isLoading, hasError } = useFetchSupplierDetails();
    const iban = useAppSelector(selectSupplierIban);

    if (isLoading) return <Loader />;
    if (hasError) return <Error />;

    return <EditIbanForm iban={iban || ''} />;
};

import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import styles from './priceUploadErrors.module.scss';

interface PriceUploadErrorsProps {
    errors: RowErrors[];
}

export const PriceUploadErrors = ({ errors }: PriceUploadErrorsProps) => {
    const errorLists = errors.map((errObj) => {
        const { rowNumber, errors: validationErrors } = errObj;
        const errorListItems = validationErrors.map((error) => <li key={`${error}-${rowNumber}`}>{error}</li>);
        return (
            <React.Fragment key={`error${rowNumber}`}>
                <p className={styles.errorsHeader}>
                    <WarningAmberIcon sx={{ color: '#ff9800' }} />
                    <strong>Errors in row {rowNumber}:</strong>
                </p>
                <ul className={styles.errorList}>{errorListItems}</ul>
            </React.Fragment>
        );
    });

    return (
        <Alert severity='warning'>
            <AlertTitle sx={{ marginBottom: '16px' }}>
                <strong>
                    The file contains incorrect or wrongly formatted data. Please correct the following entries and
                    re-upload the file:
                </strong>
            </AlertTitle>
            {errorLists}
        </Alert>
    );
};

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataGrid, GridCellParams, GridColDef, MuiEvent } from '@mui/x-data-grid';
import { DataGridNoRowsOverlay, DataGridCustomAddItemFooter } from '../../shared/DataGrid';
import { useAppSelector } from '../../../redux/typed-hooks';
import { selectSupplierManagers } from '../../../redux/reducers';
import { Heading } from '../../shared';
import { EditButton } from '../../shared/Buttons';
import { useCheckIfUserHasManagerRights } from '../../../hooks';

export const Managers = () => {
    const navigate = useNavigate();
    const { supplierId } = useParams();
    const { hasManagerRights } = useCheckIfUserHasManagerRights();
    const managers = useAppSelector(selectSupplierManagers);
    if (!managers) return <p>There are no internal managers assigned to this supplier.</p>;

    const handleEditClick = () => {
        // This is intentional
    };

    const renderEditButton = () => <EditButton variant='text' handleClick={handleEditClick} />;

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.5,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'role',
            headerName: 'Role',
            flex: 0.5,
            align: 'left',
            headerAlign: 'left',
        },
    ];

    const editColumn: GridColDef = {
        field: 'editButton',
        headerName: '',
        align: 'center',
        headerAlign: 'center',
        renderCell: renderEditButton,
    };

    if (hasManagerRights) {
        columns.push(editColumn);
    }

    const handleEditContactClick = (params: GridCellParams, e: MuiEvent<React.MouseEvent<HTMLElement>>) => {
        e.defaultMuiPrevented = true;
        if (params.field !== 'editButton') return;
        const { id } = params.row;
        navigate(`/suppliers/${supplierId}/edit-manager/${id}`);
    };

    const handleAddClick = (e: MuiEvent<React.MouseEvent<HTMLElement>>) => {
        e.defaultMuiPrevented = true;
        navigate(`/suppliers/${supplierId}/add-manager`);
    };

    return (
        <>
            <Heading level={2} text='Managers' />
            <DataGrid
                rows={managers}
                columns={columns}
                getRowId={(row) => row.id}
                onCellClick={handleEditContactClick}
                autoHeight
                hideFooter={!hasManagerRights}
                components={{
                    Footer: DataGridCustomAddItemFooter,
                    NoRowsOverlay: DataGridNoRowsOverlay,
                }}
                componentsProps={{
                    footer: { buttons: [{ label: 'Add manager', handleAddItem: handleAddClick }] },
                    noRowsOverlay: { text: 'No managers' },
                }}
            />
        </>
    );
};

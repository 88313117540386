import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/redux/typed-hooks';
import { selectCreateSupplierLoading, selectHasCreateSupplierError } from '@app/redux/reducers';
import { clearCreateSupplierPostErrors, createSupplier } from '@app/redux/reducers/suppliers';
import { useTextInput, useSelectInput, useNumberInput, useCheckboxInput } from '@app/components/shared/Form/Inputs';
import { useRedirect } from '@app/hooks';
import {
    objectHasNonEmptyValue,
    validateSkuPrefix,
    validateRequiredField,
    validateEmails,
} from '@app/utils/validation';

export const useCreateSupplier = () => {
    const supplierNumber = useNumberInput(null);
    const supplierName = useTextInput('');
    const skuPrefix = useTextInput('');
    const isSelfBilling = useCheckboxInput(false);
    const invoiceAddressName = useTextInput('');
    const invoiceAddressStreet = useTextInput('');
    const invoiceAddressHouseNumber = useTextInput('');
    const invoiceAddressSuffix = useTextInput('');
    const invoiceAddressPostalCode = useTextInput('');
    const invoiceAddressCity = useTextInput('');
    const invoiceAddressCountryCode = useSelectInput('NL');
    const vatNumber = useTextInput('');
    const invoiceLanguage = useSelectInput('NL');
    const iban = useTextInput('');
    const invoiceEmailAddresses = useTextInput('');
    const [formErrors, setFormErrors] = useState({
        supplierNumber: '',
        supplierName: '',
        skuPrefix: '',
        invoiceAddressName: '',
        invoiceAddressStreet: '',
        invoiceAddressHouseNumber: '',
        invoiceAddressSuffix: '',
        invoiceAddressPostalCode: '',
        invoiceAddressCity: '',
        vatNumber: '',
        iban: '',
        invoiceEmailAddresses: '',
        // The remaining fields are always valid
    });
    const isLoading = useAppSelector(selectCreateSupplierLoading);
    const hasError = useAppSelector(selectHasCreateSupplierError);
    const dispatch = useAppDispatch();

    // Redirect on successful API call
    useRedirect();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newError = {
            supplierNumber: validateRequiredField({ value: supplierNumber.value, fieldName: 'Supplier number' }),
            supplierName: validateRequiredField({ value: supplierName.value, fieldName: 'Supplier name' }),
            skuPrefix: validateSkuPrefix({ value: skuPrefix.value, fieldName: 'SKU prefix' }),
            invoiceAddressName: validateRequiredField({
                value: invoiceAddressName.value,
                fieldName: 'Address name',
            }),
            invoiceAddressStreet: validateRequiredField({
                value: invoiceAddressStreet.value,
                fieldName: 'Street',
            }),
            invoiceAddressHouseNumber: validateRequiredField({
                value: invoiceAddressHouseNumber.value,
                fieldName: 'House number',
            }),
            invoiceAddressSuffix: '',
            invoiceAddressPostalCode: validateRequiredField({
                value: invoiceAddressPostalCode.value,
                fieldName: 'Postal code',
            }),
            invoiceAddressCity: validateRequiredField({
                value: invoiceAddressCity.value,
                fieldName: 'City',
            }),
            vatNumber: validateRequiredField({ value: vatNumber.value, fieldName: 'Vat number' }),
            iban: validateRequiredField({ value: iban.value, fieldName: 'Iban' }),
            invoiceEmailAddresses: validateEmails({
                emails: invoiceEmailAddresses.value.split(','),
                fieldName: 'Email Addresses',
            }),
        };

        setFormErrors(newError);
        if (objectHasNonEmptyValue(newError)) return;

        const newSupplier: CreateSupplier = {
            supplierNumber: supplierNumber.value as number,
            supplierName: supplierName.value,
            skuPrefix: skuPrefix.value,
            isSelfBilling: isSelfBilling.value,
            invoicing: {
                invoiceAddressName: invoiceAddressName.value,
                invoiceAddressStreet: invoiceAddressStreet.value,
                invoiceAddressHouseNumber: invoiceAddressHouseNumber.value,
                invoiceAddressSuffix: invoiceAddressSuffix.value,
                invoiceAddressPostalCode: invoiceAddressPostalCode.value,
                invoiceAddressCity: invoiceAddressCity.value,
                invoiceAddressCountryCode:
                    invoiceAddressCountryCode.value as SupplierInvoicing['invoiceAddressCountryCode'],
                vatNumber: vatNumber.value,
                invoiceLanguage: invoiceLanguage.value as SupplierInvoicing['invoiceLanguage'],
                iban: iban.value,
                invoiceEmailAddresses: invoiceEmailAddresses.value.split(', '),
            },
        };

        dispatch(createSupplier(newSupplier));
    };

    useEffect(() => {
        dispatch(clearCreateSupplierPostErrors());
    }, [dispatch]);

    return {
        supplierNumber,
        supplierName,
        skuPrefix,
        isSelfBilling,
        invoiceAddressName,
        invoiceAddressStreet,
        invoiceAddressHouseNumber,
        invoiceAddressSuffix,
        invoiceAddressPostalCode,
        invoiceAddressCity,
        invoiceAddressCountryCode,
        iban,
        vatNumber,
        invoiceLanguage,
        invoiceEmailAddresses,
        handleSubmit,
        formErrors,
        isLoading,
        hasError,
    };
};

import React from 'react';
import { AddButton } from '../../Buttons';
import styles from './dataGridCustomAddItemFooter.module.scss';

interface DataGridCustomAddItemFooterProps {
    buttons: {
        label: string;
        handleAddItem: () => void;
    }[];
}

export const DataGridCustomAddItemFooter = ({ buttons }: DataGridCustomAddItemFooterProps) => {
    return (
        <div className={styles.customAddItemFooter}>
            {buttons.map((button) => (
                <AddButton key={button.label} label={button.label} handleClick={button.handleAddItem} />
            ))}
        </div>
    );
};

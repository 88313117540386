import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../../redux/typed-hooks';
import { selectIsLoadingPatchPurchaseContract, selectHasPurchaseContractPatchError } from '../../../../redux/reducers';
import { editPurchaseContract, clearSupplierDetailsPatchErrors } from '../../../../redux/reducers/supplierDetails';
import {
    useCheckboxInput,
    useDateInput,
    useNumberInput,
    useRadioInputGroup,
    useSelectInput,
    useTextInput,
} from '../../../shared/Form/Inputs';
import { useRedirect } from '../../../../hooks';
import { validateRequiredCheckboxGroup, objectHasNonEmptyValue } from '../../../../utils/validation';
import { convertCheckboxGroupToList, toDate } from '../../../../utils/formatting';

export const useEditPurchaseContractForm = (contract: PurchaseContract) => {
    const { supplierId } = useParams();
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoadingPatchPurchaseContract);
    const hasError = useAppSelector(selectHasPurchaseContractPatchError);

    const {
        id,
        created,
        updated,
        marketplaceCodes,
        term,
        smallBusinessScheme,
        ignorePurchaseCosts,
        refundFeeForReturns,
        invoicingCutOffDate,
        paymentDueDate,
        purchase,
        comments,
    } = contract;

    // GENERAL
    const newBeginDate = useDateInput(term.beginDate);
    const newEndDate = useDateInput(term.endDate);
    const isMarketplaceNL = useCheckboxInput(marketplaceCodes.includes('nl'));
    const isMarketplaceDE = useCheckboxInput(marketplaceCodes.includes('de'));
    const newMarketPlaceCodes = convertCheckboxGroupToList([
        { value: 'nl', checked: isMarketplaceNL.value },
        { value: 'de', checked: isMarketplaceDE.value },
    ]);

    // PAYMENTS
    const newSmallBusinessScheme = useRadioInputGroup(smallBusinessScheme);
    const newIgnorePurchaseCosts = useRadioInputGroup(ignorePurchaseCosts);
    const newInvoicingCutOffDate = useSelectInput(invoicingCutOffDate);
    const newPaymentDueDate = useSelectInput(paymentDueDate);
    const newSkontoDiscountPercentage = useNumberInput(purchase.skontoDiscountPercentageBasedOnCostPrice);
    const newVatPercentage = useNumberInput(purchase.vatPercentage);
    const newPaymentDiscount = useNumberInput(purchase.paymentDiscount);

    // RETURNS
    const newReturnPolicy = useSelectInput(purchase.return.returnPolicy);
    const newReturnLogisticsProvider = useSelectInput(purchase.return.returnLogisticsProvider);
    const { supplierReturnAddressId } = purchase.return;
    const newSupplierReturnAddressId = useSelectInput(
        supplierReturnAddressId ? supplierReturnAddressId.toString() : null
    );
    const newWhoPaysReturnShippingCostsSupplierFault = useSelectInput(
        purchase.return.whoPaysReturnShippingCostsSupplierFault
    );
    const newRefundFeeForReturns = useRadioInputGroup(refundFeeForReturns);
    const newCostPriceReimbursementPercentage = useNumberInput(purchase.return.costPriceReimbursementPercentage);
    const newWhoPaysSightReturns = useSelectInput(purchase.return.whoPaysSightReturns);

    // LOGISTICS
    const newOutboundSetup = useSelectInput(purchase.outbound.setup);
    const newLogisticsProvider = useSelectInput(purchase.outbound.logisticsProvider);
    const newWhoPaysCrossdock = useSelectInput(purchase.outbound.whoPaysCrossdock);
    const newPurchasePriceInclWorks = useRadioInputGroup(purchase.outbound.purchasePriceInclWorks);

    // COMMENTS
    const newComments = useTextInput(comments);

    const [formErrors, setFormErrors] = useState({
        marketplaceCodes: '',
    });

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errors = {
            marketplaceCodes: validateRequiredCheckboxGroup(newMarketPlaceCodes, 'marketplace codes'),
        };

        setFormErrors(errors);
        if (objectHasNonEmptyValue(errors)) return;

        const newPurchaseContract: EditPurchaseContract = {
            marketplaceCodes: newMarketPlaceCodes as PurchaseContract['marketplaceCodes'],
            term: {
                beginDate: newBeginDate.value ? toDate(newBeginDate.value.toString()) : term.beginDate,
                endDate: newEndDate.value ? toDate(newEndDate.value.toString()) : null,
            },
            smallBusinessScheme: newSmallBusinessScheme.value,
            ignorePurchaseCosts: newIgnorePurchaseCosts.value,
            refundFeeForReturns: newRefundFeeForReturns.value,
            invoicingCutOffDate: newInvoicingCutOffDate.value as PurchaseContract['invoicingCutOffDate'],
            paymentDueDate: newPaymentDueDate.value as PurchaseContract['paymentDueDate'],
            purchase: {
                skontoDiscountPercentageBasedOnCostPrice:
                    typeof newSkontoDiscountPercentage.value === 'number' ? newSkontoDiscountPercentage.value : 0,
                paymentDiscount: typeof newPaymentDiscount.value === 'number' ? newPaymentDiscount.value : 0,
                vatPercentage: typeof newVatPercentage.value === 'number' ? newVatPercentage.value : 0,
                outbound: {
                    setup: (newOutboundSetup.value as PurchaseModel['outbound']['setup']) || null,
                    logisticsProvider:
                        (newLogisticsProvider.value as PurchaseModel['outbound']['logisticsProvider']) || null,
                    whoPaysCrossdock:
                        (newWhoPaysCrossdock.value as PurchaseModel['outbound']['whoPaysCrossdock']) || null,
                    purchasePriceInclWorks: newPurchasePriceInclWorks.value,
                },
                return: {
                    returnPolicy: newReturnPolicy.value
                        ? (newReturnPolicy.value as PurchaseModel['return']['returnPolicy'])
                        : null,
                    returnLogisticsProvider: newReturnLogisticsProvider.value
                        ? (newReturnLogisticsProvider.value as PurchaseModel['return']['returnLogisticsProvider'])
                        : null,
                    supplierReturnAddressId: newSupplierReturnAddressId.value
                        ? parseFloat(newSupplierReturnAddressId.value)
                        : null,
                    costPriceReimbursementPercentage: newCostPriceReimbursementPercentage.value
                        ? newCostPriceReimbursementPercentage.value
                        : null,
                    whoPaysSightReturns: newWhoPaysSightReturns.value
                        ? (newWhoPaysSightReturns.value as PurchaseModel['return']['whoPaysSightReturns'])
                        : null,
                    whoPaysReturnShippingCostsSupplierFault: newWhoPaysReturnShippingCostsSupplierFault.value
                        ? (newWhoPaysReturnShippingCostsSupplierFault.value as PurchaseModel['return']['whoPaysReturnShippingCostsSupplierFault'])
                        : null,
                },
            },
            comments: newComments.value,
        };

        // Send
        if (supplierId) {
            dispatch(editPurchaseContract({ supplierId, contractId: contract.id, newContract: newPurchaseContract }));
        }
    };

    useEffect(() => {
        dispatch(clearSupplierDetailsPatchErrors());
    }, [dispatch]);

    // Redirect on successful API call
    useRedirect();

    return {
        id,
        created,
        updated,
        isMarketplaceNL,
        isMarketplaceDE,
        newBeginDate,
        newEndDate,
        newSmallBusinessScheme,
        newIgnorePurchaseCosts,
        newRefundFeeForReturns,
        newInvoicingCutOffDate,
        newPaymentDueDate,
        newSkontoDiscountPercentage,
        newVatPercentage,
        newPaymentDiscount,
        newOutboundSetup,
        newLogisticsProvider,
        newWhoPaysCrossdock,
        newPurchasePriceInclWorks,
        newReturnPolicy,
        newReturnLogisticsProvider,
        newSupplierReturnAddressId,
        newCostPriceReimbursementPercentage,
        newWhoPaysSightReturns,
        newWhoPaysReturnShippingCostsSupplierFault,
        newComments,
        handleSubmit,
        formErrors,
        isLoading,
        hasError,
    };
};

import React from 'react';
import { PageTemplate } from '../../components/templates';
import { CreateSupplier } from '../../components/CreateSupplier/CreateSupplier';

export const CreateSupplierPage: React.FC = () => {
    return (
        <PageTemplate headerText='Create supplier' hasBackButton>
            <CreateSupplier />
        </PageTemplate>
    );
};

import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useAppSelector } from './redux/typed-hooks';
import {
    selectUserIsAuthenticated,
    selectUserHasNoRights,
    selectUserHasViewerRights,
    selectUserHasManagerRights,
    selectUserHasAdminRights,
} from './redux/reducers';
import { NavBar } from './components/NavBar/NavBar';
import { SuccessNotification } from './components/SuccessNotification/SuccessNotification';
import { ErrorNotification } from './components/ErrorNotification/ErrorNotification';
import {
    AboutPage,
    CommissionContractPage,
    CreateAddressPage,
    CreateCommissionContractPage,
    CreateContactPage,
    CreatePurchaseContractPage,
    CreateManagerPage,
    CreateSupplierPage,
    EditAddressPage,
    EditCommissionContractPage,
    EditContactPage,
    EditGeneralDetailsPage,
    EditIbanPage,
    EditManagerPage,
    EditPurchaseContractPage,
    EditSupplierNumberPage,
    ForbiddenPage,
    FourOhFourPage,
    LoginPage,
    PriceUploadPage,
    PurchaseContractPage,
    ProductUploadPage,
    SuppliersOverviewPage,
    SupplierPage,
    UnauthorizedPage,
} from './pages';
import { getRoutes } from './utils/routing';
import styles from './app.module.scss';

const RequireAuth = ({
    isAuthenticated,
    isAuthorized,
    hasNoRights,
    children,
}: {
    isAuthenticated: boolean;
    isAuthorized: boolean;
    hasNoRights: boolean;
    children: JSX.Element;
}) => {
    if (isAuthorized) return children;
    if (hasNoRights) return <UnauthorizedPage />; // authenticated but has no rights at all
    if (isAuthenticated && !isAuthorized) return <ForbiddenPage />; // has no sufficient rights
    return <Navigate to='/login' />; // not authenticated
};

export const App = () => {
    const isAuthenticated = useAppSelector(selectUserIsAuthenticated);
    const hasNoRights = useAppSelector(selectUserHasNoRights);
    const hasViewerRights = useAppSelector(selectUserHasViewerRights);
    const hasManagerRights = useAppSelector(selectUserHasManagerRights);
    const hasAdminRights = useAppSelector(selectUserHasAdminRights);

    const routes = getRoutes('Supplier', {
        LoginPage,
        SuppliersOverviewPage,
        FourOhFourPage,
        AboutPage,
        CreateSupplierPage,
        SupplierPage,
        PriceUploadPage,
        ProductUploadPage,
        PurchaseContractPage,
        CommissionContractPage,
        CreateCommissionContractPage,
        CreatePurchaseContractPage,
        EditCommissionContractPage,
        EditPurchaseContractPage,
        EditGeneralDetailsPage,
        EditSupplierNumberPage,
        EditIbanPage,
        CreateAddressPage,
        EditAddressPage,
        CreateContactPage,
        EditContactPage,
        CreateManagerPage,
        EditManagerPage,
    });

    return (
        <div>
            <div className={styles.notifications}>
                <SuccessNotification />
                <ErrorNotification />
            </div>
            <NavBar
                appName='Partnerships'
                landingPage='suppliers'
                pages={[
                    { name: 'Suppliers', path: '/suppliers' },
                    { name: 'About', path: '/about' },
                ]}
            />
            <Routes>
                {routes.map(({ path, permissions, Component }) => {
                    const isAuthorized = () => {
                        if (permissions === 'everyone') return true;
                        if (permissions === 'viewer') return hasViewerRights;
                        if (permissions === 'manager') return hasManagerRights;
                        if (permissions === 'admin') return hasAdminRights;
                        return false;
                    };

                    if (permissions === 'everyone') return <Route path={path} key={path} element={<Component />} />;
                    return (
                        <Route
                            path={path}
                            key={path}
                            element={
                                <RequireAuth
                                    isAuthenticated={isAuthenticated}
                                    isAuthorized={isAuthorized()}
                                    hasNoRights={hasNoRights}
                                >
                                    <Component />
                                </RequireAuth>
                            }
                        />
                    );
                })}
            </Routes>
        </div>
    );
};

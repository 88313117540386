import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/typed-hooks';
import { selectContractById } from '../../../redux/reducers';
import { Error, Loader } from '../../shared';
import { EditCommissionContractForm } from './subcomponents/EditCommissionContractForm';
import { useFetchSupplierDetails } from '../../SupplierDetails/useFetchSupplierDetails';
import { isCommissionContract } from '../../../utils/type-guards';
import { toInteger } from '../../../utils/formatting';

export const EditCommissionContract = () => {
    const { isLoading, hasError } = useFetchSupplierDetails();
    const { contractId } = useParams();
    const contractIdToEdit = contractId ? toInteger(contractId) : null;
    const contract = useAppSelector((state) => selectContractById(state, contractIdToEdit));

    if (isLoading) return <Loader />;
    if (hasError) return <Error />;
    // Using type predicate in order to cast commission contract as CommissionContract
    if (!contract || !isCommissionContract(contract)) return <p>There is no information on this contract.</p>;
    return <EditCommissionContractForm contract={contract} />;
};

import React, { useId } from 'react';
import { TextField } from '@mui/material';
import styles from '../inputs.module.scss';

interface TextInputProps {
    variant?: 'text' | 'textarea';
    label: string;
    value: string;
    hasExtraLabel?: boolean;
    error?: boolean;
    disabled?: boolean;
    helperText?: string;
    handleChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const TextInput = ({
    variant = 'text',
    label,
    hasExtraLabel = true,
    value,
    disabled,
    handleChange,
    error = false,
    helperText = '',
}: TextInputProps) => {
    const id = useId();

    return (
        <div className={variant === 'text' ? styles.textInput : styles.textareaInput}>
            {hasExtraLabel && (
                <label htmlFor={id} aria-hidden='true' className={styles.textInput__label}>
                    {label}
                </label>
            )}
            <div className={variant === 'text' ? styles.textInput__inputWrapper : styles.textareaInput__inputWrapper}>
                <TextField
                    id={id}
                    name={`text-input-${id}`}
                    rows={variant === 'textarea' ? 4 : 1}
                    multiline={variant === 'textarea'}
                    label={label}
                    value={value}
                    onChange={(e) => handleChange && handleChange(e)}
                    disabled={disabled}
                    variant='outlined'
                    inputProps={{
                        readOnly: !handleChange && true, // input will be readonly if no handler is passed
                    }}
                    sx={{ width: '100%' }}
                    error={error}
                    helperText={helperText}
                />
            </div>
        </div>
    );
};

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { selectHasProductUploadError, selectIsProductUploadLoading, selectSupplierNumber } from '../../redux/reducers';
import { clearProductsUploadError, uploadProducts } from '../../redux/reducers/products';
import { useCheckboxInput, useTextInput } from '../shared/Form/Inputs';
import {
    objectHasNonEmptyValue,
    validateRequiredCheckboxGroup,
    validateRequiredField,
    validateWebsite,
} from '../../utils/validation';
import { convertCheckboxGroupToList } from '../../utils/formatting';

export const useProductUpload = () => {
    const url = useTextInput('');
    const sheet = useTextInput('');
    const isMarketplaceNL = useCheckboxInput(true);
    const isMarketplaceDE = useCheckboxInput(false);
    const newMarketPlaceCodes = convertCheckboxGroupToList([
        {
            value: 'nl_NL',
            checked: isMarketplaceNL.value,
        },
        { value: 'de_DE', checked: isMarketplaceDE.value },
    ]);
    const [formErrors, setFormErrors] = useState({ url: '', sheet: '', marketplaces: '' });
    const isLoading = useAppSelector(selectIsProductUploadLoading);
    const hasError = useAppSelector(selectHasProductUploadError);
    const dispatch = useAppDispatch();
    const supplierNumber = useAppSelector(selectSupplierNumber);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newError = {
            url: validateWebsite({ value: url.value, fieldName: 'URL' }),
            sheet: validateRequiredField({ value: sheet.value, fieldName: 'Sheet name' }),
            marketplaces: validateRequiredCheckboxGroup(newMarketPlaceCodes, 'marketplace codes'),
        };

        setFormErrors(newError);
        if (objectHasNonEmptyValue(newError) || !supplierNumber) return;

        const productObject: ProductSheet = {
            url: url.value,
            sheet: sheet.value,
            dropship_provider_id: supplierNumber,
            marketplaces: newMarketPlaceCodes as ProductSheet['marketplaces'],
        };

        dispatch(uploadProducts(productObject));
    };

    useEffect(() => {
        dispatch(clearProductsUploadError());
    }, [dispatch]);

    return {
        url,
        sheet,
        isMarketplaceNL,
        isMarketplaceDE,
        handleSubmit,
        formErrors,
        isLoading,
        hasError,
    };
};

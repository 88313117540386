import React from 'react';
import { useAppSelector } from '../../../../redux/typed-hooks';
import { selectSupplierNumber, selectSupplierName, selectSupplierSku } from '../../../../redux/reducers';
import { Heading } from '../../../shared';
import { toDate } from '../../../../utils/formatting';
import styles from './contractGeneral.module.scss';

interface ContractGeneralProps {
    beginDate: Contract['term']['beginDate'];
    endDate: Contract['term']['endDate'];
    marketplaceCodes: Contract['marketplaceCodes'];
}

export const ContractGeneral = ({ beginDate, endDate, marketplaceCodes }: ContractGeneralProps) => {
    const supplierNumber = useAppSelector(selectSupplierNumber);
    const supplierName = useAppSelector(selectSupplierName);
    const supplierSku = useAppSelector(selectSupplierSku);

    return (
        <>
            <Heading level={2} text='General' />
            <dl className={styles.list}>
                <dt>Supplier name:</dt>
                <dd>
                    <i>{supplierName || ''}</i>
                </dd>
                <dt>Supplier number:</dt>
                <dd>{supplierNumber || ''}</dd>
                <dt>SKU prefix:</dt>
                <dd>{supplierSku || ''}</dd>
                <dt>Start date:</dt>
                <dd>{toDate(beginDate)}</dd>
                <dt>End date:</dt>
                <dd>{endDate ? toDate(endDate) : 'No end date'}</dd>
                <dt>Marketplaces:</dt>
                <dd>
                    {marketplaceCodes.map((code) => (
                        <React.Fragment key={code}>
                            <div>{code.toUpperCase()}</div>
                        </React.Fragment>
                    ))}
                </dd>
            </dl>
        </>
    );
};

import React from 'react';
import { Form } from '../../shared/Form';
import { SubmitButton } from '../../shared/Buttons';
import { Error } from '../../shared/Error/Error';
import styles from './formTemplate.module.scss';

interface FormTemplateProps {
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    isSubmitting: boolean;
    hasError: boolean;
    children: React.ReactNode;
}

export const FormTemplate = ({ handleSubmit, isSubmitting, hasError, children }: FormTemplateProps) => {
    return (
        <Form handleSubmit={handleSubmit}>
            <div className={styles.saveBtnWrapper}>
                <SubmitButton variant='save' isLoading={isSubmitting} />
            </div>
            {hasError && (
                <div className={styles.errorWrapper}>
                    <Error />
                </div>
            )}
            {children}
        </Form>
    );
};

import axios from 'axios';
import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { getError, clearError } from './error';
import { notificationUpdated } from './notifications';
import { redirectLinkUpdated } from './redirect';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';

const initialState = {
    suppliers: [] as SupplierDetails[],
    pagination: null as null | Pagination,
    isLoading: false,
    isLoadingPostSupplier: false,
    hasError: false,
    hasSupplierPostError: false,
    createdSupplierId: null as null | number,
};

type State = typeof initialState;

const suppliersSlice = createSlice({
    name: 'suppliers',
    initialState,
    reducers: {
        suppliersLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        createSupplierLoading(state) {
            return {
                ...state,
                isLoadingPostSupplier: true,
                hasSupplierPostError: false,
            };
        },
        suppliersReceived(state, action: PayloadAction<Suppliers>) {
            return {
                ...state,
                suppliers: action.payload.suppliers,
                pagination: action.payload.paging,
                isLoading: false,
            };
        },
        supplierCreated(state, action: PayloadAction<SupplierDetails>) {
            return {
                ...state,
                suppliers: [...state.suppliers, action.payload],
                isLoadingPostSupplier: false,
            };
        },
        suppliersError(state) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
        createSupplierError(state) {
            return {
                ...state,
                isLoadingPostSupplier: false,
                hasSupplierPostError: true,
            };
        },
        createSupplierPostErrorsCleared(state) {
            return {
                ...state,
                hasSupplierPostError: false,
            };
        },
    },
});

export default suppliersSlice.reducer;

const {
    suppliersLoading,
    suppliersReceived,
    suppliersError,
    createSupplierLoading,
    supplierCreated,
    createSupplierError,
} = suppliersSlice.actions;

export const { createSupplierPostErrorsCleared: clearCreateSupplierPostErrors } = suppliersSlice.actions;

type GetSuppliersParams = {
    page: number;
    pageSize: number;
    search: string;
    manager: string;
};

export const getSuppliers =
    ({ page, pageSize, search, manager }: GetSuppliersParams) =>
    async (dispatch: Dispatch) => {
        dispatch(clearError());
        dispatch(suppliersLoading());
        try {
            const res = await getGatewayApiInstance().get(
                `/suppliers?limit=${pageSize}&page=${page}&search=${search}&manager=${manager}`
            );
            dispatch(suppliersReceived(res.data));
        } catch (err: unknown) {
            dispatch(suppliersError());
            if (axios.isAxiosError(err) && err.response) {
                dispatch(
                    getError({
                        errData: err.response.data,
                        errStatus: err.response.status,
                    })
                );
            }
            // Log errors to the console
            handleAxiosErrors(err);
        }
    };

export const createSupplier = (newSupplier: CreateSupplier) => async (dispatch: Dispatch) => {
    dispatch(clearError());
    dispatch(createSupplierLoading());
    try {
        const res = await getGatewayApiInstance().post(`/suppliers`, newSupplier);
        dispatch(supplierCreated(res.data));
        dispatch(notificationUpdated('Supplier was successfully created'));
        dispatch(redirectLinkUpdated(`/suppliers/${res.data.id}`));
    } catch (err: unknown) {
        dispatch(createSupplierError());
        if (axios.isAxiosError(err) && err.response) {
            dispatch(
                getError({
                    errData: err.response.data,
                    errStatus: err.response.status,
                })
            );
        }
        // Log errors to the console
        handleAxiosErrors(err);
    }
};

// SELECTORS
export const selectSuppliers = (state: State): SupplierDetails[] => state.suppliers;
export const selectSuppliersTotal = (state: State): number | undefined => state.pagination?.totalCount;
export const selectAreSuppliersLoading = (state: State): boolean => state.isLoading;
export const selectCreateSupplierLoading = (state: State): boolean => state.isLoadingPostSupplier;
export const selectHasSuppliersError = (state: State): boolean => state.hasError;
export const selectHasCreateSupplierError = (state: State): boolean => state.hasSupplierPostError;

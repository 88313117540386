import React from 'react';

interface FormProps {
    id?: string;
    children: React.ReactNode;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

export const Form: React.FC<FormProps> = ({ children, id, handleSubmit }) => (
    <form name={id} id={id} onSubmit={handleSubmit} noValidate>
        {children}
    </form>
);

import React from 'react';
import { FormTemplate } from '../templates';
import { Fieldset } from '../shared/Form';
import { NumberInput, SelectInput, TextInput, CheckboxInput } from '../shared/Form/Inputs';
import { useCreateSupplier } from './useCreateSupplier';
import styles from './createSupplier.module.scss';

export const CreateSupplier = () => {
    const {
        supplierNumber,
        supplierName,
        skuPrefix,
        isSelfBilling,
        invoiceAddressName,
        invoiceAddressStreet,
        invoiceAddressHouseNumber,
        invoiceAddressSuffix,
        invoiceAddressPostalCode,
        invoiceAddressCity,
        invoiceAddressCountryCode,
        iban,
        vatNumber,
        invoiceLanguage,
        invoiceEmailAddresses,
        handleSubmit,
        formErrors,
        isLoading,
        hasError,
    } = useCreateSupplier();

    return (
        <FormTemplate handleSubmit={handleSubmit} isSubmitting={isLoading} hasError={hasError}>
            <div className={styles.inputsWrapper}>
                <Fieldset legend='Supplier information'>
                    <NumberInput
                        label='Supplier number'
                        value={supplierNumber.value}
                        handleChange={supplierNumber.handleChange}
                        error={formErrors.supplierNumber !== ''}
                        helperText={formErrors.supplierNumber}
                    />

                    <TextInput
                        label='Supplier name'
                        value={supplierName.value}
                        handleChange={supplierName.handleChange}
                        error={formErrors.supplierName !== ''}
                        helperText={formErrors.supplierName}
                    />

                    <TextInput
                        label='SKU prefix'
                        value={skuPrefix.value}
                        handleChange={skuPrefix.handleChange}
                        error={formErrors.skuPrefix !== ''}
                        helperText={formErrors.skuPrefix}
                    />

                    <CheckboxInput
                        label='Self Billing'
                        checked={isSelfBilling.value}
                        handleChange={isSelfBilling.handleChange}
                    />
                </Fieldset>

                <Fieldset legend='Invoice Address'>
                    <TextInput
                        label='Address name'
                        value={invoiceAddressName.value}
                        handleChange={invoiceAddressName.handleChange}
                        error={formErrors.invoiceAddressName !== ''}
                        helperText={formErrors.invoiceAddressName}
                    />

                    <TextInput
                        label='Street'
                        value={invoiceAddressStreet.value}
                        handleChange={invoiceAddressStreet.handleChange}
                        error={formErrors.invoiceAddressStreet !== ''}
                        helperText={formErrors.invoiceAddressStreet}
                    />

                    <TextInput
                        label='House number'
                        value={invoiceAddressHouseNumber.value}
                        handleChange={invoiceAddressHouseNumber.handleChange}
                        error={formErrors.invoiceAddressHouseNumber !== ''}
                        helperText={formErrors.invoiceAddressHouseNumber}
                    />

                    <TextInput
                        label='Suffix'
                        value={invoiceAddressSuffix.value}
                        handleChange={invoiceAddressSuffix.handleChange}
                        error={formErrors.invoiceAddressSuffix !== ''}
                        helperText={formErrors.invoiceAddressSuffix}
                    />

                    <TextInput
                        label='Postal code'
                        value={invoiceAddressPostalCode.value}
                        handleChange={invoiceAddressPostalCode.handleChange}
                        error={formErrors.invoiceAddressPostalCode !== ''}
                        helperText={formErrors.invoiceAddressPostalCode}
                    />

                    <TextInput
                        label='City'
                        value={invoiceAddressCity.value}
                        handleChange={invoiceAddressCity.handleChange}
                        error={formErrors.invoiceAddressCity !== ''}
                        helperText={formErrors.invoiceAddressCity}
                    />

                    <SelectInput
                        label='Country'
                        value={invoiceAddressCountryCode.value}
                        options={[
                            { text: 'Austria', value: 'AT' },
                            { text: 'Belgium', value: 'BE' },
                            { text: 'Bulgaria', value: 'BG' },
                            { text: 'Croatia', value: 'HR' },
                            { text: 'Republic of Cyprus', value: 'CY' },
                            { text: 'Czech Republic', value: 'CZ' },
                            { text: 'Denmark', value: 'DK' },
                            { text: 'Estonia', value: 'EE' },
                            { text: 'Finland', value: 'FI' },
                            { text: 'France', value: 'FR' },
                            { text: 'Germany', value: 'DE' },
                            { text: 'Greece', value: 'GR' },
                            { text: 'Hungary', value: 'HU' },
                            { text: 'Ireland', value: 'IE' },
                            { text: 'Italy', value: 'IT' },
                            { text: 'Latvia', value: 'LV' },
                            { text: 'Lithuania', value: 'LT' },
                            { text: 'Luxembourg', value: 'LU' },
                            { text: 'Malta', value: 'MT' },
                            { text: 'Netherlands', value: 'NL' },
                            { text: 'Poland', value: 'PL' },
                            { text: 'Portugal', value: 'PT' },
                            { text: 'Romania', value: 'RO' },
                            { text: 'Slovakia', value: 'SK' },
                            { text: 'Slovenia', value: 'SI' },
                            { text: 'Turkey', value: 'TR' },
                            { text: 'Spain', value: 'ES' },
                            { text: 'Sweden', value: 'SE' },
                        ]}
                        handleChange={invoiceAddressCountryCode.handleChange}
                    />

                    <TextInput
                        label='Vat number'
                        value={vatNumber.value}
                        handleChange={vatNumber.handleChange}
                        error={formErrors.vatNumber !== ''}
                        helperText={formErrors.vatNumber}
                    />

                    <SelectInput
                        label='Language'
                        value={invoiceLanguage.value}
                        options={[
                            { value: 'EN', text: 'English' },
                            { value: 'NL', text: 'Dutch' },
                        ]}
                        handleChange={invoiceLanguage.handleChange}
                    />

                    <TextInput
                        label='Iban'
                        value={iban.value}
                        handleChange={iban.handleChange}
                        error={formErrors.iban !== ''}
                        helperText={formErrors.iban}
                    />

                    <TextInput
                        label='Email addresses'
                        value={invoiceEmailAddresses.value}
                        handleChange={invoiceEmailAddresses.handleChange}
                        error={formErrors.invoiceEmailAddresses !== ''}
                        helperText={formErrors.invoiceEmailAddresses}
                    />
                </Fieldset>
            </div>
        </FormTemplate>
    );
};

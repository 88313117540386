import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useAppSelector } from '../../../redux/typed-hooks';
import { selectError400Msg } from '../../../redux/reducers';
import { PortalFeedbackLinkButton } from '../Buttons';
import styles from './error.module.scss';

interface ErrorProps {
    variant?: 'custom' | 'server';
    customErrorMsg?: string;
}

export const Error = ({ customErrorMsg = '', variant = 'server' }: ErrorProps) => {
    const serverErrorMsg = useAppSelector(selectError400Msg);

    return (
        <Alert severity='error' className='error'>
            <AlertTitle>Something went wrong</AlertTitle>
            {customErrorMsg && (
                <p className={styles.errorMsg}>
                    <b>{customErrorMsg}</b>
                </p>
            )}
            {variant === 'server' && serverErrorMsg && (
                <p className={styles.errorMsg}>
                    <b>{serverErrorMsg}</b>
                </p>
            )}
            <p className={styles.errorText}>Please report your issue on the Slack channel:</p>
            <PortalFeedbackLinkButton />
        </Alert>
    );
};

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/redux/typed-hooks';
import { createSupplierAddress, clearSupplierDetailsPostErrors } from '@app/redux/reducers/supplierDetails';
import { selectIsLoadingPostSupplierAddress, selectHasSupplierAddressPostError } from '@app/redux/reducers';
import { useSelectInput, useTextInput } from '@app/components/shared/Form/Inputs';
import { useRedirect } from '@app/hooks';
import { objectHasNonEmptyValue, validateRequiredField } from '@app/utils/validation';

export const useCreateAddress = () => {
    const dispatch = useAppDispatch();
    const { supplierId } = useParams();
    const type = useSelectInput('return' as SupplierAddress['type']);
    const addressStreet = useTextInput('');
    const addressHouseNumber = useTextInput('');
    const addressSuffix = useTextInput('');
    const addressPostalCode = useTextInput('');
    const addressCity = useTextInput('');
    const countryCode = useSelectInput('NL' as SupplierAddress['countryCode']);
    const isLoading = useAppSelector(selectIsLoadingPostSupplierAddress);
    const hasError = useAppSelector(selectHasSupplierAddressPostError);
    const [formErrors, setFormErrors] = useState({
        addressStreet: '',
        addressHouseNumber: '',
        addressSuffix: '',
        addressPostalCode: '',
        addressCity: '',
    });

    // Redirect on successful API call
    useRedirect();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newError = {
            addressStreet: validateRequiredField({ value: addressStreet.value, fieldName: 'Street' }),
            addressHouseNumber: validateRequiredField({ value: addressHouseNumber.value, fieldName: 'House number' }),
            addressSuffix: '',
            addressPostalCode: validateRequiredField({ value: addressPostalCode.value, fieldName: 'Postal code' }),
            addressCity: validateRequiredField({ value: addressCity.value, fieldName: 'City' }),
            // The remaining select fields are always valid
        };
        setFormErrors(newError);
        if (objectHasNonEmptyValue(newError)) return;

        const newAddress: CreateSupplierAddress = {
            type: type.value as SupplierAddress['type'],
            addressStreet: addressStreet.value,
            addressHouseNumber: addressHouseNumber.value,
            addressSuffix: addressSuffix.value,
            addressPostalCode: addressPostalCode.value,
            addressCity: addressCity.value,
            countryCode: countryCode.value as SupplierAddress['countryCode'],
        };

        if (supplierId) {
            dispatch(createSupplierAddress({ newAddress, supplierId }));
        }
    };

    useEffect(() => {
        dispatch(clearSupplierDetailsPostErrors());
    }, [dispatch]);

    return {
        type,
        addressStreet,
        addressHouseNumber,
        addressSuffix,
        addressPostalCode,
        addressCity,
        countryCode,
        handleSubmit,
        isLoading,
        formErrors,
        hasError,
    };
};

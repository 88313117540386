import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';

interface SubmitButtonProps {
    variant: 'save' | 'upload';
    isLoading: boolean;
    isDisabled?: boolean;
    formId?: string | undefined; // to be used if button is placed outside the form
}

export const SubmitButton = ({ variant, isLoading, isDisabled = false, formId = undefined }: SubmitButtonProps) => {
    let label;
    let labelWhenLoading;
    let icon;

    if (variant === 'save') {
        label = 'Save';
        labelWhenLoading = 'Saving...';
        icon = <SaveIcon />;
    } else if (variant === 'upload') {
        label = 'Confirm upload';
        labelWhenLoading = 'Uploading...';
        icon = <SendIcon />;
    }

    return (
        <LoadingButton
            type='submit'
            variant='contained'
            form={formId}
            disabled={isDisabled}
            loading={isLoading}
            loadingPosition='end'
            endIcon={icon}
        >
            {isLoading ? labelWhenLoading : label}
        </LoadingButton>
    );
};

import React from 'react';
import { Heading, YesNoField } from '../../../shared';
import { capitalize } from '../../../../utils/formatting';
import styles from './contractReturnsCommissionModel.module.scss';

interface ContractReturnsCommissionModelProps {
    returns: CommissionModel['return'];
    refundFeeForReturns: CommissionContract['refundFeeForReturns'];
}

export const ContractReturnsCommissionModel = ({
    returns,
    refundFeeForReturns,
}: ContractReturnsCommissionModelProps) => {
    const {
        returnPolicy,
        returnLogisticsProvider,
        sellingPriceReimbursementPercentage,
        whoPaysReturnShippingCostsSupplierFault,
    } = returns;

    return (
        <>
            <Heading level={2} text='Returns' />
            <dl className={styles.list}>
                <dt>Return policy:</dt>
                <dd>{returnPolicy ? capitalize(returnPolicy) : ''}</dd>
                {['berkenrode', 'timco', 'troostwijk', 'gsd', 'bva'].includes(returnPolicy || '') && (
                    <>
                        <dt>Selling Price Reimbursement:</dt>
                        <dd>{sellingPriceReimbursementPercentage ? `${sellingPriceReimbursementPercentage}%` : ''}</dd>
                    </>
                )}
                <dt>Return logistics provider:</dt>
                <dd>{returnLogisticsProvider ? capitalize(returnLogisticsProvider) : ''}</dd>
                <dt>Refund fees for returns:</dt>
                <dd>
                    <YesNoField yes={refundFeeForReturns} />
                </dd>
                <dt>Who pays return shipping costs when supplier at fault:</dt>
                <dd>
                    {whoPaysReturnShippingCostsSupplierFault ? capitalize(whoPaysReturnShippingCostsSupplierFault) : ''}
                </dd>
            </dl>
        </>
    );
};

import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/typed-hooks';
import { selectSupplierManagerById } from '../../../redux/reducers';
import { Error, Loader } from '../../shared';
import { EditManagerForm } from './subcomponents/EditManagerForm';
import { useFetchSupplierDetails } from '../../SupplierDetails/useFetchSupplierDetails';
import { toInteger } from '../../../utils/formatting';

export const EditManager = () => {
    const { isLoading, hasError } = useFetchSupplierDetails();
    const { managerId } = useParams();
    const managerToEditId = managerId ? toInteger(managerId) : null;
    const manager = useAppSelector((state) => selectSupplierManagerById(state, managerToEditId));

    if (isLoading) return <Loader />;
    if (hasError) return <Error />;
    if (!manager) return <p>There is no information on the selected internal manager for this supplier.</p>;
    return <EditManagerForm manager={manager} />;
};

import { useState, useEffect } from 'react';

type UseDataGridServerSidePaginationParams = {
    initialPageSize: number;
};

export const useDataGridServerSidePagination = ({ initialPageSize }: UseDataGridServerSidePaginationParams) => {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [rowCount, setRowCount] = useState<number | undefined>(undefined);
    const [rowCountState, setRowCountState] = useState(rowCount || 0);

    const handlePageChange = (pageParam: number) => setPage(pageParam);

    const handlePageSizeChange = (pageSizeParam: number) => setPageSize(pageSizeParam);

    useEffect(() => {
        // Some api clients return undefine while loading
        // Following lines are here to prevent `rowCountState` from being undefined during the loading
        setRowCountState((prevRowCountState) => (rowCount !== undefined ? rowCount : prevRowCountState));
    }, [rowCount, setRowCountState]);

    return { page, pageSize, rowCountState, handlePageChange, handlePageSizeChange, setRowCount };
};

/* USAGE: 
1. Set paginationMode='server' prop on Data Grid
2. Assign return values from this hook (except `setRowCount`) to their corresponding DataGrid props
3. Assign other props necessary for the grid to work
You will end up with a markup like this: 
        <DataGrid
            paginationMode='server'                 
            rowCount={rowCountState}                  
            page={page}
            pageSize={pageSize}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            // (Other props you will need for the grid to work) 
            columns={columns}                                      // your columns
            rows={rows}                                            // your data
            loading={isLoading}                                    // state of the API request from Redux
            components={{                                          // Implement linear loading overlay (looks neater than the default circular overlay)
                LoadingOverlay: LinearProgress,
            }}
            rowsPerPageOptions={[INITIAL_PAGE_SIZE, 25, 50, 100]}  // Optional. Any integers, max is 100 rows per page.
            // Other props as required, e.g. event handlers etc    // See DataGrid official docs
            ... 
        />
4. How to use `setRowCount`:
- Invoke setRowCount(undefined) before making the request
- Invoke setRowCount(rowsTotal) when the request completes and `rowsTotal` value is accessible (i.e. total no of data entries on the server)
Example: 
    useEffect(() => {
        setRowCount(undefined);
        // Passing `page + 1` as DataGrid pages are zero-indexed, while the backend pagination is one-indexed
        dispatch(getOrders({ page: page + 1, pageSize}));
        setRowCount(ordersTotal);
    }, [dispatch, page, pageSize, ordersTotal]);
*/

import React from 'react';
import { FileInput } from '../../../shared/Form/Inputs';
import { Form } from '../../../shared/Form';

interface PriceUploadFormProps {
    formId: string;
    fileName: string | undefined;
    handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

export const PriceUploadForm = ({ formId, fileName, handleFileChange, handleSubmit }: PriceUploadFormProps) => (
    <Form id={formId} handleSubmit={handleSubmit}>
        <FileInput fileName={fileName} handleChange={handleFileChange} />
    </Form>
);

export default PriceUploadForm;

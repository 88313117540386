import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataGrid, GridColDef, GridRowParams, MuiEvent, GridValueFormatterParams } from '@mui/x-data-grid';
import { DataGridNoRowsOverlay, DataGridCustomAddItemFooter } from '../../shared/DataGrid';
import { useAppSelector } from '../../../redux/typed-hooks';
import { selectContractsOverview } from '../../../redux/reducers';
import { Heading } from '../../shared';
import { useCheckIfUserHasManagerRights } from '../../../hooks';
import { toDateTime } from '../../../utils/formatting';

export const Contracts = () => {
    const navigate = useNavigate();
    const { supplierId } = useParams();
    const { hasManagerRights } = useCheckIfUserHasManagerRights();
    const contractsOverview = useAppSelector(selectContractsOverview);
    if (!contractsOverview) return <p>There are no contracts for this supplier.</p>;

    // Flatten contractsOverview object so that each field in the DataGrid has a unique name
    const contracts = contractsOverview.map((contract) => ({
        id: contract.id,
        beginDate: contract.term.beginDate,
        endDate: contract.term.endDate,
        model: contract.model,
        marketplaceCodes: contract.marketplaceCodes,
        status: contract.status,
    }));

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.5,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'beginDate',
            headerName: 'Begin Date',
            valueFormatter: (params: GridValueFormatterParams<string>) => toDateTime(params.value),
            flex: 0.5,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'endDate',
            headerName: 'End Date',
            valueFormatter: (params: GridValueFormatterParams<string | null>) => {
                const endDate = params.value;
                if (endDate) return toDateTime(endDate);
                return '';
            },
            flex: 0.5,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'model',
            headerName: 'Model',
            flex: 0.5,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'marketplaceCodes',
            headerName: 'Country codes',
            valueFormatter: (params: GridValueFormatterParams<ContractMarketplaceCode[]>) =>
                params.value.map((code) => code.toUpperCase()).join(', '),
            flex: 0.5,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            align: 'left',
            headerAlign: 'left',
        },
    ];

    const handleRowClick = (params: GridRowParams, e: MuiEvent<React.MouseEvent<HTMLElement>>) => {
        e.defaultMuiPrevented = true;
        const contractId = params.id;
        const { model } = params.row;
        if (model === 'purchase') {
            navigate(`/suppliers/${supplierId}/purchase-contract/${contractId}`);
        } else if (model === 'commission') {
            navigate(`/suppliers/${supplierId}/commission-contract/${contractId}`);
        }
    };

    const handleAddPurchaseContractClick = (e: MuiEvent<React.MouseEvent<HTMLElement>>) => {
        e.defaultMuiPrevented = true;
        navigate(`/suppliers/${supplierId}/add-purchase-contract`);
    };

    const handleAddCommissionContractClick = (e: MuiEvent<React.MouseEvent<HTMLElement>>) => {
        e.defaultMuiPrevented = true;
        navigate(`/suppliers/${supplierId}/add-commission-contract`);
    };

    return (
        <>
            <Heading level={2} text='Contracts' />
            <DataGrid
                rows={contracts}
                columns={columns}
                getRowId={(row) => row.id}
                onRowClick={handleRowClick}
                autoHeight
                sx={{ cursor: 'pointer' }}
                hideFooter={!hasManagerRights}
                components={{
                    Footer: DataGridCustomAddItemFooter,
                    NoRowsOverlay: DataGridNoRowsOverlay,
                }}
                componentsProps={{
                    footer: {
                        buttons: [
                            { label: 'Add purchase contract', handleAddItem: handleAddPurchaseContractClick },
                            { label: 'Add commission contract', handleAddItem: handleAddCommissionContractClick },
                        ],
                    },
                    noRowsOverlay: { text: 'No contracts' },
                }}
            />
        </>
    );
};

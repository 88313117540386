import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/typed-hooks';
import { selectIsLoadingPostCommissionContract, selectHasCommissionContractPostError } from '../../../redux/reducers';
import { createCommissionContract, clearSupplierDetailsPostErrors } from '../../../redux/reducers/supplierDetails';
import { useCheckboxInput, useDateInput, useNumberInput, useSelectInput } from '../../shared/Form/Inputs';
import { useRedirect } from '../../../hooks';
import { convertCheckboxGroupToList } from '../../../utils/formatting/convertCheckboxGroupToList/convertCheckboxGroupToList';
import {
    objectHasNonEmptyValue,
    validateRequiredCheckboxGroup,
    validateRequiredField,
    validateRequiredDateField,
} from '../../../utils/validation';
import { toDate } from '../../../utils/formatting';

export const useCreateCommissionContract = () => {
    const { supplierId } = useParams();
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoadingPostCommissionContract);
    const hasError = useAppSelector(selectHasCommissionContractPostError);

    const beginDate = useDateInput(null);
    const endDate = useDateInput(null);
    const isMarketplaceNL = useCheckboxInput(true);
    const isMarketplaceDE = useCheckboxInput(false);
    const checkedCodes = convertCheckboxGroupToList([
        { value: 'nl', checked: isMarketplaceNL.value },
        { value: 'de', checked: isMarketplaceDE.value },
    ]);
    const invoicingCutOffDate = useSelectInput('invoicing_cut_off_day');
    const paymentDueDate = useSelectInput('current_month_last_day');
    const feePercentage = useNumberInput(null);

    const [formErrors, setFormErrors] = useState({
        beginDate: '',
        marketplaceCodes: '',
        feePercentage: '',
        // Remaining fields are always valid
    });

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errors = {
            beginDate: validateRequiredDateField({ value: beginDate.value, fieldName: 'start date' }),
            marketplaceCodes: validateRequiredCheckboxGroup(checkedCodes, 'marketplace codes'),
            feePercentage: validateRequiredField({ value: feePercentage.value, fieldName: 'Fee percentage' }),
        };

        setFormErrors(errors);

        if (objectHasNonEmptyValue(errors)) return;

        const newCommissionContract: CreateCommissionContract = {
            model: 'commission',
            marketplaceCodes: checkedCodes as ContractMarketplaceCode[],
            term: {
                beginDate: toDate(beginDate?.value?.toString() as string), // Passed validation so safe to cast it as string
                endDate: endDate.value ? toDate(endDate.value.toString()) : null,
            },
            invoicingCutOffDate: invoicingCutOffDate.value as CommissionContract['invoicingCutOffDate'],
            paymentDueDate: paymentDueDate.value as CommissionContract['paymentDueDate'],
            commission: {
                feePercentage: feePercentage.value as number, // Passed validation so safe to cast is as number
            },
        };

        // Send
        if (supplierId) {
            dispatch(createCommissionContract({ supplierId, newCommissionContract }));
        }
    };

    useEffect(() => {
        dispatch(clearSupplierDetailsPostErrors());
    }, [dispatch]);

    // Redirect on successful contract creation
    useRedirect();

    return {
        beginDate,
        endDate,
        isMarketplaceNL,
        isMarketplaceDE,
        invoicingCutOffDate,
        paymentDueDate,
        feePercentage,
        handleSubmit,
        formErrors,
        isLoading,
        hasError,
    };
};

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/typed-hooks';
import {
    selectSupplierNotes,
    selectIsLoadingPatchSupplierNotes,
    selectHasSupplierNotesPatchError,
} from '../../../../redux/reducers';
import { editSupplierNotes, clearSupplierDetailsPatchErrors } from '../../../../redux/reducers/supplierDetails';
import { useTextInput } from '../../../shared/Form/Inputs';

export const useNotes = () => {
    const dispatch = useAppDispatch();
    const { supplierId } = useParams();
    const notes = useAppSelector(selectSupplierNotes);
    const newNotes = useTextInput(notes);
    const isLoading = useAppSelector(selectIsLoadingPatchSupplierNotes);
    const hasError = useAppSelector(selectHasSupplierNotesPatchError);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updatedNotes: EditSupplierNotes = {
            remarks: newNotes.value,
        };

        if (supplierId) {
            dispatch(editSupplierNotes({ newNotes: updatedNotes, supplierId }));
        }
    };

    useEffect(() => {
        dispatch(clearSupplierDetailsPatchErrors());
    }, [dispatch]);

    return {
        notes,
        newNotes,
        handleSubmit,
        isLoading,
        hasError,
    };
};

export default useNotes;

import React from 'react';
import { PageTemplate } from '../../components/templates';
import { InfoAlert } from '../../components/shared/InfoAlert/InfoAlert';
import { PortalFeedbackLinkButton } from '../../components/shared/Buttons';
import styles from './unauthorized.module.scss';

export const UnauthorizedPage = () => {
    return (
        <PageTemplate headerText='Unauthorized' hasBackButton={false}>
            <InfoAlert title='You do not have sufficient rights to access the Portal'>
                <p className={styles.text}>Please report your issue on the Slack channel:</p>
                <PortalFeedbackLinkButton />
            </InfoAlert>
        </PageTemplate>
    );
};

import React from 'react';
import { FormTemplate } from '../../../templates';
import { SelectInput, TextInput } from '../../../shared/Form/Inputs';
import { useEditContactForm } from './useEditContactForm';
import styles from './editContactForm.module.scss';

interface EditContactFormProps {
    contact: SupplierContact;
}

export const EditContactForm = ({ contact }: EditContactFormProps) => {
    const { formErrors, contactType, contactName, contactTelephone, contactEmail, handleSubmit, isLoading, hasError } =
        useEditContactForm(contact);

    return (
        <FormTemplate handleSubmit={handleSubmit} isSubmitting={isLoading} hasError={hasError}>
            <div className={styles.inputsWrapper}>
                <SelectInput
                    label='Type'
                    options={['commercial', 'operational', 'service', 'technical']}
                    value={contactType.value}
                    handleChange={contactType.handleChange}
                />

                <TextInput
                    label='Contact name'
                    value={contactName.value}
                    handleChange={contactName.handleChange}
                    error={formErrors.name !== ''}
                    helperText={formErrors.name}
                />

                <TextInput
                    label='Contact telephone number'
                    value={contactTelephone.value}
                    handleChange={contactTelephone.handleChange}
                    error={formErrors.telephone !== ''}
                    helperText={formErrors.telephone}
                />

                <TextInput
                    label='Contact email'
                    value={contactEmail.value}
                    handleChange={contactEmail.handleChange}
                    error={formErrors.email !== ''}
                    helperText={formErrors.email}
                />
            </div>
        </FormTemplate>
    );
};

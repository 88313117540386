import React from 'react';

/**
    Defining a structure for our routes doesn't tie us to one routing library.
    It also allows us to build custom Breadcrumbs, but note:
    For breadcrumbs to work correctly, if two routes match (e.g. '/suppliers/create-supplier' matches '/suppliers/:supplierId'), 
    the route without parameters has to be defined first.
 */

interface Components {
    [index: string]: React.FunctionComponent;
}

type Route = {
    path: string;
    name: string;
    Component: React.FunctionComponent;
    permissions: 'everyone' | 'viewer' | 'manager' | 'admin';
};

export const getRoutes = (
    supplierName: string,
    {
        LoginPage,
        SuppliersOverviewPage,
        FourOhFourPage,
        AboutPage,
        CreateSupplierPage,
        SupplierPage,
        PriceUploadPage,
        ProductUploadPage,
        PurchaseContractPage,
        CommissionContractPage,
        CreateCommissionContractPage,
        CreatePurchaseContractPage,
        EditCommissionContractPage,
        EditPurchaseContractPage,
        EditGeneralDetailsPage,
        EditSupplierNumberPage,
        EditIbanPage,
        CreateAddressPage,
        EditAddressPage,
        CreateContactPage,
        EditContactPage,
        CreateManagerPage,
        EditManagerPage,
    }: Components = {}
): Route[] => [
    {
        path: '/login',
        name: 'Login',
        Component: LoginPage,
        permissions: 'everyone',
    },
    {
        path: '*',
        name: '404',
        Component: FourOhFourPage,
        permissions: 'everyone',
    },
    {
        path: '/',
        name: 'Home',
        Component: SuppliersOverviewPage,
        permissions: 'viewer',
    },
    {
        path: '/about',
        name: 'About',
        Component: AboutPage,
        permissions: 'viewer',
    },
    {
        path: '/suppliers',
        name: 'Suppliers',
        Component: SuppliersOverviewPage,
        permissions: 'viewer',
    },
    {
        path: '/suppliers/create-supplier',
        name: 'Create supplier',
        Component: CreateSupplierPage,
        permissions: 'manager',
    },
    {
        path: '/suppliers/:supplierId',
        name: supplierName,
        Component: SupplierPage,
        permissions: 'viewer',
    },
    {
        path: '/suppliers/:supplierId/price-upload',
        name: 'Price Upload',
        Component: PriceUploadPage,
        permissions: 'manager',
    },
    {
        path: '/suppliers/:supplierId/product-upload',
        name: 'Product Upload',
        Component: ProductUploadPage,
        permissions: 'manager',
    },
    {
        path: '/suppliers/:supplierId/purchase-contract/:contractId',
        name: 'Purchase Contract',
        Component: PurchaseContractPage,
        permissions: 'viewer',
    },

    {
        path: '/suppliers/:supplierId/commission-contract/:contractId',
        name: 'Commission Contract',
        Component: CommissionContractPage,
        permissions: 'viewer',
    },
    {
        path: 'suppliers/:supplierId/add-commission-contract',
        name: 'Add Commission Contract',
        Component: CreateCommissionContractPage,
        permissions: 'manager',
    },
    {
        path: 'suppliers/:supplierId/add-purchase-contract',
        name: 'Add Purchase Contract',
        Component: CreatePurchaseContractPage,
        permissions: 'manager',
    },
    {
        path: 'suppliers/:supplierId/edit-commission-contract/:contractId',
        name: 'Edit Commission Contract',
        Component: EditCommissionContractPage,
        permissions: 'manager',
    },
    {
        path: 'suppliers/:supplierId/edit-purchase-contract/:contractId',
        name: 'Edit Purchase Contract',
        Component: EditPurchaseContractPage,
        permissions: 'manager',
    },

    {
        path: 'suppliers/:supplierId/edit-general-details',
        name: 'Edit General Details',
        Component: EditGeneralDetailsPage,
        permissions: 'manager',
    },
    {
        path: 'suppliers/:supplierId/edit-supplier-number',
        name: 'Edit Supplier Number',
        Component: EditSupplierNumberPage,
        permissions: 'manager',
    },
    {
        path: 'suppliers/:supplierId/edit-iban',
        name: 'Edit Iban',
        Component: EditIbanPage,
        permissions: 'admin',
    },
    {
        path: 'suppliers/:supplierId/add-address',
        name: 'Add Address',
        Component: CreateAddressPage,
        permissions: 'manager',
    },
    {
        path: 'suppliers/:supplierId/edit-address/:addressId',
        name: 'Edit Address',
        Component: EditAddressPage,
        permissions: 'manager',
    },
    {
        path: 'suppliers/:supplierId/add-contact',
        name: 'Add Contact',
        Component: CreateContactPage,
        permissions: 'manager',
    },
    {
        path: 'suppliers/:supplierId/edit-contact/:contactId',
        name: 'Edit Contact',
        Component: EditContactPage,
        permissions: 'manager',
    },
    {
        path: 'suppliers/:supplierId/add-manager',
        name: 'Add Manager',
        Component: CreateManagerPage,
        permissions: 'manager',
    },
    {
        path: 'suppliers/:supplierId/edit-manager/:managerId',
        name: 'Edit Manager',
        Component: EditManagerPage,
        permissions: 'manager',
    },
];

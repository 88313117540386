import React from 'react';
import Button from '@mui/material/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useAppSelector } from '../../redux/typed-hooks';
import { selectErrorMsg } from '../../redux/reducers';
import { useVisibilityTimeout } from '../../hooks';
import styles from './errorNotification.module.scss';

export const ErrorNotification = () => {
    const errorMsg = useAppSelector(selectErrorMsg);

    const { isVisible, hide } = useVisibilityTimeout(4000, errorMsg.id);

    if (!isVisible || !errorMsg.msg) return null;

    return (
        <div className={styles.errorNotification}>
            <div className={styles.errorNotification__errorIcon}>
                <ErrorOutlineIcon color='error' fontSize='medium' />
            </div>
            <p>
                <span className={styles.errorNotification__heading}>
                    <b>Error</b>
                </span>
                <br />
                {errorMsg.msg}
            </p>
            <div className={styles.errorNotification__closeBtn}>
                <Button color='error' variant='text' onClick={hide}>
                    Close
                </Button>
            </div>
        </div>
    );
};

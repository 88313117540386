import React from 'react';
import { PurchaseContract } from '../../components/Contract/PurchaseContract';
import { PageTemplate } from '../../components/templates';

export const PurchaseContractPage = () => {
    return (
        <PageTemplate headerText='Purchase Model Contract Overview' hasBackButton>
            <PurchaseContract />
        </PageTemplate>
    );
};

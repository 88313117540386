import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/typed-hooks';
import { selectContractById } from '../../redux/reducers';
import {
    ContractActionMenu,
    ContractComments,
    ContractGeneral,
    ContractLogisticsCommissionModel,
    ContractPayments,
    ContractReturnsCommissionModel,
    ContractStatus,
} from './subcomponents';
import { Error, Footer, Loader } from '../shared';
import { useActivateContract } from './useActivateContract';
import { useDuplicateContract } from './useDuplicateContract';
import { useFetchSupplierDetails } from '../SupplierDetails/useFetchSupplierDetails';
import { useCheckIfUserHasManagerRights } from '../../hooks';
import { isCommissionContract } from '../../utils/type-guards';
import { toInteger } from '../../utils/formatting';
import styles from './contract.module.scss';

export const CommissionContract = () => {
    const { contractId } = useParams();
    const { hasManagerRights } = useCheckIfUserHasManagerRights();
    const { isLoading, hasError } = useFetchSupplierDetails();
    const contractIdToView = contractId ? toInteger(contractId) : null;
    const contract = useAppSelector((state) => selectContractById(state, contractIdToView));
    const { handleActivateContract, isLoadingPatchContractStatus, hasActivateContractError } = useActivateContract();
    const { handleDuplicateContract } = useDuplicateContract();

    if (isLoading) return <Loader />;
    if (hasError) return <Error />;
    // Using type predicate in order to cast commission contract as CommissionContract
    if (!contract || !isCommissionContract(contract)) return <p>There is no information on this contract.</p>;

    const {
        id,
        created,
        updated,
        status,
        canActivate,
        model,
        marketplaceCodes,
        term,
        smallBusinessScheme,
        ignorePurchaseCosts,
        refundFeeForReturns,
        invoicingCutOffDate,
        paymentDueDate,
        commission,
        comments,
    } = contract;

    return (
        <>
            {isLoadingPatchContractStatus && <Loader />}
            {hasActivateContractError && (
                <div className={styles.errorWrapper}>
                    <Error />
                </div>
            )}

            <div className={`${styles.flexContainerMeta} ${styles.container}`}>
                <ContractStatus version={id} status={status} />
                {hasManagerRights && (
                    <ContractActionMenu
                        contractModel={model}
                        contractCanBeActivated={canActivate}
                        handleActivateContract={handleActivateContract}
                        handleDuplicateContract={handleDuplicateContract}
                    />
                )}
            </div>

            <div className={styles.flexContainer}>
                <div className={`${styles.flexContainer__item} ${styles.container}`}>
                    <ContractGeneral
                        beginDate={term.beginDate}
                        endDate={term.endDate}
                        marketplaceCodes={marketplaceCodes}
                    />
                </div>

                <div className={`${styles.flexContainer__item} ${styles.container}`}>
                    <ContractPayments
                        smallBusinessScheme={smallBusinessScheme}
                        invoicingCutOffDate={invoicingCutOffDate}
                        paymentDueDate={paymentDueDate}
                        skontoDiscountPercentage={commission?.skontoDiscountPercentageBasedOnSellingPrice}
                        vatPercentage={commission?.vatPercentage}
                        paymentDiscount={commission?.paymentDiscount}
                        ignorePurchaseCosts={ignorePurchaseCosts}
                        feePercentage={commission?.feePercentage}
                    />
                </div>

                <div className={`${styles.flexContainer__item} ${styles.container}`}>
                    <ContractReturnsCommissionModel
                        returns={commission.return}
                        refundFeeForReturns={refundFeeForReturns}
                    />
                </div>

                <div className={`${styles.flexContainer__item} ${styles.container}`}>
                    <ContractLogisticsCommissionModel logistics={commission.outbound} />
                </div>
            </div>
            <div className={styles.container}>
                <ContractComments comments={comments} />
            </div>

            <Footer createdAt={created} updatedAt={updated} />
        </>
    );
};

import React from 'react';
import { FormTemplate } from '../../templates';
import { SelectInput, TextInput } from '../../shared/Form/Inputs';
import { useCreateAddress } from './useCreateAddress';
import styles from './createAddress.module.scss';

export const CreateAddress = () => {
    const {
        type,
        addressStreet,
        addressHouseNumber,
        addressSuffix,
        addressPostalCode,
        addressCity,
        countryCode,
        handleSubmit,
        isLoading,
        formErrors,
        hasError,
    } = useCreateAddress();

    return (
        <FormTemplate handleSubmit={handleSubmit} isSubmitting={isLoading} hasError={hasError}>
            <div className={styles.inputsWrapper}>
                <SelectInput
                    label='Type'
                    options={['return', 'office', 'warehouse'] as SupplierAddress['type'][]}
                    value={type.value}
                    handleChange={type.handleChange}
                />

                <TextInput
                    label='Street'
                    value={addressStreet.value}
                    handleChange={addressStreet.handleChange}
                    error={formErrors.addressStreet !== ''}
                    helperText={formErrors.addressStreet}
                />
                <TextInput
                    label='House number'
                    value={addressHouseNumber.value}
                    handleChange={addressHouseNumber.handleChange}
                    error={formErrors.addressHouseNumber !== ''}
                    helperText={formErrors.addressHouseNumber}
                />
                <TextInput
                    label='Suffix'
                    value={addressSuffix.value}
                    handleChange={addressSuffix.handleChange}
                    error={formErrors.addressSuffix !== ''}
                    helperText={formErrors.addressSuffix}
                />
                <TextInput
                    label='Postal code'
                    value={addressPostalCode.value}
                    handleChange={addressPostalCode.handleChange}
                    error={formErrors.addressPostalCode !== ''}
                    helperText={formErrors.addressPostalCode}
                />
                <TextInput
                    label='City'
                    value={addressCity.value}
                    handleChange={addressCity.handleChange}
                    error={formErrors.addressCity !== ''}
                    helperText={formErrors.addressCity}
                />

                <SelectInput
                    label='Country code'
                    options={
                        [
                            'AT',
                            'BE',
                            'BG',
                            'HR',
                            'CY',
                            'CZ',
                            'DK',
                            'EE',
                            'FI',
                            'FR',
                            'DE',
                            'GR',
                            'HU',
                            'IE',
                            'IT',
                            'LV',
                            'LT',
                            'LU',
                            'MT',
                            'NL',
                            'PL',
                            'PT',
                            'RO',
                            'SK',
                            'SI',
                            'ES',
                            'SE',
                        ] as SupplierAddress['countryCode'][]
                    }
                    value={countryCode.value}
                    handleChange={countryCode.handleChange}
                />
            </div>
        </FormTemplate>
    );
};

import React from 'react';
import { CommissionContract } from '../../components/Contract/CommissionContract';
import { PageTemplate } from '../../components/templates';

export const CommissionContractPage = () => {
    return (
        <PageTemplate headerText='Commission Model Contract Overview' hasBackButton>
            <CommissionContract />
        </PageTemplate>
    );
};

import React from 'react';
import { Heading, YesNoField } from '../../../shared';
import { capitalize } from '../../../../utils/formatting';
import styles from './contractLogisticsPurchaseModel.module.scss';

interface ContractLogisticsPurchaseModelProps {
    logistics: PurchaseModel['outbound'];
}

export const ContractLogisticsPurchaseModel = ({ logistics }: ContractLogisticsPurchaseModelProps) => {
    const { setup, logisticsProvider, whoPaysCrossdock, purchasePriceInclWorks } = logistics;

    return (
        <>
            <Heading level={2} text='Logistics' />
            <dl className={styles.list}>
                <dt>Setup:</dt>
                <dd>{setup ? capitalize(setup) : ''}</dd>
                <dt>Logistics provider:</dt>
                <dd>{logisticsProvider ? capitalize(logisticsProvider) : ''}</dd>
                <dt>Who pays Crossdock:</dt>
                <dd>{whoPaysCrossdock ? capitalize(whoPaysCrossdock) : ''}</dd>
                <dt>Purchase price includes works:</dt>
                <dd>
                    <YesNoField yes={purchasePriceInclWorks} />
                </dd>
            </dl>
        </>
    );
};

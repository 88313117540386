import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    DataGrid,
    GridColDef,
    GridInitialState,
    GridRowParams,
    GridValueFormatterParams,
    MuiEvent,
} from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridExportToolbar, DataGridNoRowsOverlay, useDataGridServerSidePagination } from '../shared/DataGrid';
import { useAppSelector, useAppDispatch } from '../../redux/typed-hooks';
import {
    selectSuppliers,
    selectSuppliersTotal,
    selectAreSuppliersLoading,
    selectHasSuppliersError,
} from '../../redux/reducers';
import { getSuppliers } from '../../redux/reducers/suppliers';
import { Error } from '../shared';
import { SearchInput } from '../shared/Form/Inputs';
import { AddButton } from '../shared/Buttons';
import { useCheckIfUserHasManagerRights, useDebounce } from '../../hooks';
import styles from './suppliersOverview.module.scss';

export const SuppliersOverview = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { hasManagerRights } = useCheckIfUserHasManagerRights();
    const suppliers = useAppSelector(selectSuppliers);
    const suppliersTotal = useAppSelector(selectSuppliersTotal);
    const isLoading = useAppSelector(selectAreSuppliersLoading);
    const hasError = useAppSelector(selectHasSuppliersError);
    const [query, setQuery] = useState('');
    const debouncedQuery = useDebounce(query, 300);
    const [managerQuery, setManagerQuery] = useState('');
    const debouncedManagerQuery = useDebounce(managerQuery, 300);

    const INITIAL_PAGE_SIZE = 10;
    const { page, pageSize, rowCountState, handlePageChange, handlePageSizeChange, setRowCount } =
        useDataGridServerSidePagination({ initialPageSize: INITIAL_PAGE_SIZE });

    const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    const handleSearchManager = (e: React.ChangeEvent<HTMLInputElement>) => {
        setManagerQuery(e.target.value);
    };

    const handleRowClick = (params: GridRowParams, e: MuiEvent<React.MouseEvent<HTMLElement>>) => {
        e.defaultMuiPrevented = true;
        navigate(`/suppliers/${params.id}`);
    };

    const handleAddClick = (e: MuiEvent<React.MouseEvent<HTMLElement>>) => {
        e.defaultMuiPrevented = true;
        navigate(`/suppliers/create-supplier`);
    };

    useEffect(() => {
        setRowCount(undefined);
        // Passing `page + 1` as DataGrid pages are zero-indexed, while the backend pagination is one-indexed
        dispatch(getSuppliers({ page: page + 1, pageSize, search: debouncedQuery, manager: debouncedManagerQuery }));
        setRowCount(suppliersTotal);
    }, [dispatch, setRowCount, page, pageSize, debouncedQuery, debouncedManagerQuery, suppliersTotal]);

    const columns: GridColDef[] = [
        { field: 'supplierName', headerName: 'Supplier Name', flex: 1 },
        {
            field: 'supplierNumber',
            headerName: 'Supplier Number',
            type: '',
            flex: 1,
            align: 'left',
            headerAlign: 'left',
        },
        { field: 'skuPrefix', headerName: 'Supplier SKU Prefix', flex: 1 },
        {
            field: 'internalManagers',
            headerName: 'Managers',
            valueFormatter: (params: GridValueFormatterParams<SupplierManager[]>) => {
                return params.value
                    .filter((manager) => manager.role === 'partnerships')
                    .map((manager) => manager.name)
                    .join(', ');
            },
            flex: 1,
        },
    ];

    const initialDataGridState: GridInitialState = {
        sorting: {
            sortModel: [{ field: 'supplierName', sort: 'asc' }],
        },
    };

    if (hasError) return <Error />;
    return (
        <>
            <div className={styles.searchBarContainer}>
                <div className={styles.searchBarContainer__button}>
                    {hasManagerRights && <AddButton label='Create supplier' handleClick={handleAddClick} />}
                </div>

                <div className={styles.searchBarContainer__inputs}>
                    <SearchInput value={query} handleChange={handleSearchInput} />
                    <SearchInput label='Search by manager' value={managerQuery} handleChange={handleSearchManager} />
                </div>
            </div>
            {/* The div wrapper here prevents the last row of the DataGrid from stretching to take the remaining vertical space */}
            <div>
                <DataGrid
                    rows={suppliers}
                    columns={columns}
                    paginationMode='server'
                    rowCount={rowCountState}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    loading={isLoading}
                    onRowClick={handleRowClick}
                    initialState={initialDataGridState}
                    rowsPerPageOptions={[INITIAL_PAGE_SIZE, 25, 50, 100]}
                    autoHeight
                    sx={{ cursor: 'pointer' }}
                    components={{
                        LoadingOverlay: LinearProgress,
                        NoRowsOverlay: DataGridNoRowsOverlay,
                        Toolbar: DataGridExportToolbar,
                    }}
                    componentsProps={{
                        noRowsOverlay: { text: 'No suppliers' },
                    }}
                />
            </div>
        </>
    );
};

import React from 'react';
import { Heading } from '../../../shared';

interface ContractCommentsProps {
    comments: Contract['comments'];
}

export const ContractComments = ({ comments }: ContractCommentsProps) => (
    <>
        <Heading level={2} text='Comments' />
        <p>
            <i>{comments || 'No comments.'}</i>
        </p>
    </>
);

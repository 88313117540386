import React from 'react';
import { PageTemplate } from '../../components/templates';
import { Heading } from '../../components/shared/Heading/Heading';
import { PortalFeedbackLinkButton } from '../../components/shared/Buttons';
import styles from './aboutPage.module.scss';

export const AboutPage = () => {
    return (
        <PageTemplate headerText='About' hasBackButton hasXYCenteredContent>
            <div>
                <Heading level={2} text='Hello Naduvian!' />
                <p>The partnerships portal is developed by the portals team.</p>
                <p className={styles.lastParagraph}>
                    For feedback, questions or just general remarks please go to our slack channel.
                </p>
                <PortalFeedbackLinkButton />
            </div>
        </PageTemplate>
    );
};

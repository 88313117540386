import React from 'react';
import { SuppliersOverview } from '../../components/SuppliersOverview/suppliersOverview';
import { PageTemplate } from '../../components/templates';

export const SuppliersOverviewPage = () => {
    return (
        <PageTemplate headerText='Suppliers overview' hasBackButton={false}>
            <SuppliersOverview />
        </PageTemplate>
    );
};

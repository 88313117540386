import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/typed-hooks';
import { selectIsLoadingPatchSupplierIban, selectHasSupplierIbanPatchError } from '../../../../redux/reducers';
import { editSupplierIban, clearSupplierDetailsPatchErrors } from '../../../../redux/reducers/supplierDetails';
import { useTextInput } from '../../../shared/Form/Inputs';
import { useRedirect } from '../../../../hooks';
import { validateRequiredField } from '../../../../utils/validation';

export const useEditIbanForm = (iban: SupplierDetails['invoicing']['iban']) => {
    const newIban = useTextInput(iban);
    const [formError, setFormError] = useState('');
    const isLoading = useAppSelector(selectIsLoadingPatchSupplierIban);
    const hasError = useAppSelector(selectHasSupplierIbanPatchError);
    const dispatch = useAppDispatch();
    const { supplierId } = useParams();

    // Redirect on successful API call
    useRedirect();

    useEffect(() => {
        dispatch(clearSupplierDetailsPatchErrors());
    }, [dispatch]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newError = validateRequiredField({ value: newIban.value, fieldName: 'Iban' });
        setFormError(newError);
        if (newError) return;

        const newSupplierIban: EditSupplierIban = {
            invoicing: {
                iban: newIban.value,
            },
        };

        if (supplierId) {
            dispatch(editSupplierIban({ supplierId, newSupplierIban }));
        }
    };

    return {
        formError,
        newIban,
        handleSubmit,
        isLoading,
        hasError,
    };
};

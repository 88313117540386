import React from 'react';
import { useAppSelector } from '../../../redux/typed-hooks';
import { selectSupplierDetails } from '../../../redux/reducers';
import { Error, Loader } from '../../shared';
import { EditGeneralDetailsForm } from './subcomponents/EditGeneralDetailsForm';
import { useFetchSupplierDetails } from '../../SupplierDetails/useFetchSupplierDetails';

export const EditGeneralDetails = () => {
    const { isLoading, hasError } = useFetchSupplierDetails();
    const supplier = useAppSelector(selectSupplierDetails);

    if (isLoading) return <Loader />;
    if (hasError) return <Error />;
    if (!supplier) return <p>There are no details available for this supplier.</p>;
    return <EditGeneralDetailsForm supplier={supplier} />;
};

import React from 'react';
import { Button } from '@mui/material';
import AddTaskIcon from '@mui/icons-material/AddTask';

interface ActivateButtonProps {
    handleClick: () => void;
}

export const ActivateButton = ({ handleClick }: ActivateButtonProps) => (
    <Button onClick={handleClick} variant='contained' endIcon={<AddTaskIcon />}>
        Activate
    </Button>
);

import React from 'react';
import { useAppSelector } from '../../../../redux/typed-hooks';
import {
    selectSupplierNumber,
    selectSupplierName,
    selectSupplierSku,
    selectSupplierReturnAddresses,
} from '../../../../redux/reducers';
import { FormTemplate } from '../../../templates';
import { Fieldset } from '../../../shared/Form';
import {
    CheckboxGroup,
    CheckboxInput,
    DateInput,
    NumberInput,
    RadioInputGroup,
    SelectInput,
    TextInput,
} from '../../../shared/Form/Inputs';
import { Footer } from '../../../shared';
import { useEditPurchaseContractForm } from './useEditPurchaseContractForm';
import { purchaseContractOptions } from '../../../../utils/constants/contract-constants';
import { capitalize } from '../../../../utils/formatting';
import styles from '../../editContract.module.scss';

interface EditPurchaseContractFormProps {
    contract: PurchaseContract;
}

export const EditPurchaseContractForm = ({ contract }: EditPurchaseContractFormProps) => {
    const supplierNumber = useAppSelector(selectSupplierNumber);
    const supplierName = useAppSelector(selectSupplierName);
    const supplierSku = useAppSelector(selectSupplierSku);
    const supplierReturnAddresses = useAppSelector(selectSupplierReturnAddresses);

    const {
        id,
        created,
        updated,
        isMarketplaceNL,
        isMarketplaceDE,
        newBeginDate,
        newEndDate,
        newSmallBusinessScheme,
        newIgnorePurchaseCosts,
        newRefundFeeForReturns,
        newInvoicingCutOffDate,
        newPaymentDueDate,
        newSkontoDiscountPercentage,
        newVatPercentage,
        newPaymentDiscount,
        newOutboundSetup,
        newLogisticsProvider,
        newWhoPaysCrossdock,
        newPurchasePriceInclWorks,
        newReturnPolicy,
        newReturnLogisticsProvider,
        newSupplierReturnAddressId,
        newCostPriceReimbursementPercentage,
        newWhoPaysSightReturns,
        newWhoPaysReturnShippingCostsSupplierFault,
        newComments,
        handleSubmit,
        formErrors,
        isLoading,
        hasError,
    } = useEditPurchaseContractForm(contract);

    // Map supplier return addresses to select options
    const supplierReturnAddressesOptions = supplierReturnAddresses?.map((address) => {
        const {
            addressStreet,
            addressHouseNumber,
            addressSuffix,
            addressPostalCode,
            addressCity,
            countryCode,
            id: addressId,
        } = address;
        return {
            value: addressId.toString(),
            text: `${addressStreet} ${addressHouseNumber} ${addressSuffix}, ${addressPostalCode} ${addressCity}, ${countryCode}`,
        };
    });

    return (
        <FormTemplate handleSubmit={handleSubmit} isSubmitting={isLoading} hasError={hasError}>
            <div className={styles.editContract__fieldset}>
                <TextInput label='Version' value={id.toString()} disabled />
                <TextInput label='Status' value={capitalize(contract.status)} disabled />
            </div>

            <div className={styles.editContract__fieldsetFlexContainer}>
                <div className={styles.editContract__fieldset}>
                    <Fieldset legend='General'>
                        <TextInput label='Supplier name' value={supplierName || ''} disabled />
                        <TextInput
                            label='Supplier number'
                            value={supplierNumber ? supplierNumber.toString() : ''}
                            disabled
                        />
                        <TextInput label='Supplier SKU prefix' value={supplierSku || ''} disabled />
                        <DateInput
                            label='Start date'
                            value={newBeginDate.value}
                            handleChange={(newDate) => newBeginDate.handleChange(newDate)}
                        />
                        <DateInput
                            label='End date'
                            value={newEndDate.value}
                            handleChange={(newDate) => newEndDate.handleChange(newDate)}
                        />
                        <CheckboxGroup
                            label='Marketplaces'
                            error={formErrors.marketplaceCodes !== ''}
                            helperText={formErrors.marketplaceCodes}
                        >
                            <CheckboxInput
                                label='NL'
                                checked={isMarketplaceNL.value}
                                handleChange={isMarketplaceNL.handleChange}
                            />
                            <CheckboxInput
                                label='DE'
                                checked={isMarketplaceDE.value}
                                handleChange={isMarketplaceDE.handleChange}
                            />
                        </CheckboxGroup>
                    </Fieldset>
                </div>

                <div className={styles.editContract__fieldset}>
                    <Fieldset legend='Payments'>
                        <RadioInputGroup
                            label='Small Business Scheme'
                            value={newSmallBusinessScheme.stringValue}
                            handleChange={newSmallBusinessScheme.handleChange}
                        />
                        <RadioInputGroup
                            label='Ignore Purchase Costs'
                            value={newIgnorePurchaseCosts.stringValue}
                            handleChange={newIgnorePurchaseCosts.handleChange}
                        />
                        <SelectInput
                            label='Invoicing cut-off date'
                            options={purchaseContractOptions.invoicingCutOffDate}
                            value={newInvoicingCutOffDate.value}
                            handleChange={newInvoicingCutOffDate.handleChange}
                        />
                        <SelectInput
                            label='Payment due date'
                            options={purchaseContractOptions.paymentDueDate}
                            value={newPaymentDueDate.value}
                            handleChange={newPaymentDueDate.handleChange}
                        />
                        <NumberInput
                            variant='percentage'
                            label='Discount percentage'
                            value={newSkontoDiscountPercentage.value}
                            handleChange={newSkontoDiscountPercentage.handleChange}
                        />
                        <NumberInput
                            variant='percentage'
                            label='Payment discount'
                            value={newPaymentDiscount.value}
                            handleChange={newPaymentDiscount.handleChange}
                        />
                        <NumberInput
                            variant='percentage'
                            label='VAT percentage'
                            value={newVatPercentage.value}
                            handleChange={newVatPercentage.handleChange}
                        />
                    </Fieldset>
                </div>

                <div className={styles.editContract__fieldset}>
                    <Fieldset legend='Returns'>
                        <SelectInput
                            label='Return policy'
                            options={purchaseContractOptions.returnPolicy}
                            value={newReturnPolicy.value}
                            handleChange={newReturnPolicy.handleChange}
                        />

                        {newReturnPolicy.value === 'supplier' && supplierReturnAddressesOptions && (
                            <SelectInput
                                label='Supplier return address'
                                options={supplierReturnAddressesOptions}
                                value={newSupplierReturnAddressId.value}
                                handleChange={newSupplierReturnAddressId.handleChange}
                            />
                        )}

                        {['berkenrode', 'timco', 'troostwijk', 'gsd', 'bva'].includes(newReturnPolicy.value) && (
                            <NumberInput
                                variant='percentage'
                                label='Cost price reimbursement percentage'
                                value={newCostPriceReimbursementPercentage.value}
                                handleChange={newCostPriceReimbursementPercentage.handleChange}
                            />
                        )}
                        <SelectInput
                            label='Return logistics provider'
                            options={purchaseContractOptions.returnLogisticsProvider}
                            value={newReturnLogisticsProvider.value}
                            handleChange={newReturnLogisticsProvider.handleChange}
                        />

                        <SelectInput
                            label='Who pays sight returns'
                            options={purchaseContractOptions.whoPaysSightReturns}
                            value={newWhoPaysSightReturns.value}
                            handleChange={newWhoPaysSightReturns.handleChange}
                        />

                        <RadioInputGroup
                            label='Refund fees for returns'
                            value={newRefundFeeForReturns.stringValue}
                            handleChange={newRefundFeeForReturns.handleChange}
                        />

                        <SelectInput
                            label='Who pays return shipping costs when supplier at fault'
                            options={purchaseContractOptions.whoPaysReturnShippingCostsSupplierFault}
                            value={newWhoPaysReturnShippingCostsSupplierFault.value}
                            handleChange={newWhoPaysReturnShippingCostsSupplierFault.handleChange}
                        />
                    </Fieldset>
                </div>

                <div className={styles.editContract__fieldset}>
                    <Fieldset legend='Logistics'>
                        <SelectInput
                            label='Setup'
                            options={purchaseContractOptions.outboundSetup}
                            value={newOutboundSetup.value}
                            handleChange={newOutboundSetup.handleChange}
                        />
                        <SelectInput
                            label='Logistics provider'
                            options={purchaseContractOptions.logisticsProvider}
                            value={newLogisticsProvider.value}
                            handleChange={newLogisticsProvider.handleChange}
                        />

                        <SelectInput
                            label='Who pays crossdock'
                            options={purchaseContractOptions.whoPaysCrossdock}
                            value={newWhoPaysCrossdock.value}
                            handleChange={newWhoPaysCrossdock.handleChange}
                        />
                        <RadioInputGroup
                            label='Purchase price includes works'
                            value={newPurchasePriceInclWorks.stringValue}
                            handleChange={newPurchasePriceInclWorks.handleChange}
                        />
                    </Fieldset>
                </div>
            </div>
            <div className={styles.editContract__textareaFieldset}>
                <Fieldset legend='Comments'>
                    <TextInput
                        variant='textarea'
                        label='Comments'
                        hasExtraLabel={false}
                        value={newComments.value}
                        handleChange={newComments.handleChange}
                    />
                </Fieldset>
            </div>
            <Footer createdAt={created} updatedAt={updated} />
        </FormTemplate>
    );
};

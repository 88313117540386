import { combineReducers } from '@reduxjs/toolkit';
import authReducer, * as fromAuth from './auth';
import errorReducer, * as fromError from './error';
import supplierDetailsReducer, * as fromSupplierDetails from './supplierDetails';
import suppliersReducer, * as fromSuppliers from './suppliers';
import pricesReducer, * as fromPrices from './prices';
import productsReducer, * as fromProducts from './products';
import redirectReducer, * as fromRedirect from './redirect';
import notificationsReducer, * as fromNotifications from './notifications';
import type { RootState } from '../store';

export default combineReducers({
    authReducer,
    errorReducer,
    supplierDetailsReducer,
    suppliersReducer,
    pricesReducer,
    productsReducer,
    redirectReducer,
    notificationsReducer,
});

// GLOBAL SELECTORS
// Auth
export const selectUserName = (state: RootState): string => fromAuth.selectUserName(state.authReducer);
export const selectUserPicture = (state: RootState): string => fromAuth.selectUserPicture(state.authReducer);
export const selectUserIsAuthenticated = (state: RootState): boolean => fromAuth.selectUserIsAuthenticated(state.authReducer);
export const selectUserHasAdminRights = (state: RootState): boolean => fromAuth.selectUserHasAdminRights(state.authReducer);
export const selectUserHasManagerRights = (state: RootState): boolean => fromAuth.selectUserHasManagerRights(state.authReducer);
export const selectUserHasViewerRights = (state: RootState): boolean => fromAuth.selectUserHasViewerRights(state.authReducer);
export const selectUserHasNoRights = (state: RootState): boolean => fromAuth.selectUserHasNoRights(state.authReducer);
export const selectHasLoginError = (state: RootState): boolean => fromAuth.selectHasLoginError(state.authReducer);

// Errors
export const selectError400Msg = (state: RootState): string => fromError.selectError400Msg(state.errorReducer);

// Suppliers
export const selectSuppliers = (state: RootState): SupplierDetails[] => fromSuppliers.selectSuppliers(state.suppliersReducer);
export const selectSuppliersTotal = (state: RootState): number | undefined => fromSuppliers.selectSuppliersTotal(state.suppliersReducer);
export const selectAreSuppliersLoading = (state: RootState): boolean => fromSuppliers.selectAreSuppliersLoading(state.suppliersReducer);
export const selectCreateSupplierLoading = (state: RootState): boolean => fromSuppliers.selectCreateSupplierLoading(state.suppliersReducer);
export const selectHasSuppliersError = (state: RootState): boolean => fromSuppliers.selectHasSuppliersError(state.suppliersReducer);
export const selectHasCreateSupplierError = (state: RootState): boolean => fromSuppliers.selectHasCreateSupplierError(state.suppliersReducer);

// Supplier details
// Data
export const selectSupplierDetails = (state: RootState): null | SupplierDetails => fromSupplierDetails.selectSupplierDetails(state.supplierDetailsReducer);
export const selectSupplierNumber = (state: RootState): undefined | SupplierDetails['supplierNumber'] => fromSupplierDetails.selectSupplierNumber(state.supplierDetailsReducer);
export const selectSupplierIban = (state: RootState): undefined | SupplierInvoicing['iban'] => fromSupplierDetails.selectSupplierIban(state.supplierDetailsReducer);
export const selectSupplierName = (state: RootState): undefined | SupplierDetails['supplierName'] => fromSupplierDetails.selectSupplierName(state.supplierDetailsReducer);
export const selectSupplierSku = (state: RootState): undefined | SupplierDetails['skuPrefix'] => fromSupplierDetails.selectSupplierSku(state.supplierDetailsReducer);
export const selectSupplierInvoicing = (state: RootState): undefined | SupplierInvoicing => fromSupplierDetails.selectSupplierInvoicing(state.supplierDetailsReducer);
export const selectSupplierManagers = (state: RootState): undefined | SupplierManager[] => fromSupplierDetails.selectSupplierManagers(state.supplierDetailsReducer);
export const selectSupplierManagerById = (state: RootState, id: number | null): undefined | SupplierManager => fromSupplierDetails.selectSupplierManagerById(state.supplierDetailsReducer, id);
export const selectSupplierContacts = (state: RootState): undefined | SupplierContact[] => fromSupplierDetails.selectSupplierContacts(state.supplierDetailsReducer);
export const selectSupplierContactById = (state: RootState, id: number | null): undefined | SupplierContact => fromSupplierDetails.selectSupplierContactById(state.supplierDetailsReducer, id);
export const selectSupplierAddresses = (state: RootState): undefined | SupplierAddress[] => fromSupplierDetails.selectSupplierAddresses(state.supplierDetailsReducer);
export const selectSupplierReturnAddresses = (state: RootState): undefined | SupplierAddress[] => fromSupplierDetails.selectSupplierReturnAddresses(state.supplierDetailsReducer);
export const selectSupplierAddressById = (state: RootState, id: number | null): undefined | SupplierAddress => fromSupplierDetails.selectSupplierAddressById(state.supplierDetailsReducer, id);
export const selectContractsOverview = (state: RootState): undefined | ContractOverview[] => fromSupplierDetails.selectContractsOverview(state.supplierDetailsReducer);
export const selectContractById = (state: RootState, id: number | null): undefined | PurchaseContract | CommissionContract => fromSupplierDetails.selectContractById(state.supplierDetailsReducer, id);
export const selectSupplierNotes = (state: RootState): string => fromSupplierDetails.selectSupplierNotes(state.supplierDetailsReducer);
// Loading
export const selectIsLoadingSupplierDetails = (state: RootState): boolean => fromSupplierDetails.selectIsLoadingSupplierDetails(state.supplierDetailsReducer);
export const selectIsLoadingSupplierGeneralDetails = (state: RootState): boolean => fromSupplierDetails.selectIsLoadingSupplierGeneralDetails(state.supplierDetailsReducer);
export const selectIsLoadingSupplierNumber = (state: RootState): boolean => fromSupplierDetails.selectIsLoadingSupplierNumber(state.supplierDetailsReducer);
export const selectIsLoadingPatchSupplierIban = (state: RootState): boolean => fromSupplierDetails.selectIsLoadingPatchSupplierIban(state.supplierDetailsReducer);
export const selectIsLoadingPatchSupplierAddress = (state: RootState): boolean => fromSupplierDetails.selectIsLoadingPatchSupplierAddress(state.supplierDetailsReducer);
export const selectIsLoadingPostSupplierAddress = (state: RootState): boolean => fromSupplierDetails.selectIsLoadingPostSupplierAddress(state.supplierDetailsReducer);
export const selectIsLoadingPatchSupplierContact = (state: RootState): boolean => fromSupplierDetails.selectIsLoadingPatchSupplierContact(state.supplierDetailsReducer);
export const selectIsLoadingPostSupplierContact = (state: RootState): boolean => fromSupplierDetails.selectIsLoadingPostSupplierContact(state.supplierDetailsReducer);
export const selectIsLoadingPatchSupplierManager = (state: RootState): boolean => fromSupplierDetails.selectIsLoadingPatchSupplierManager(state.supplierDetailsReducer);
export const selectIsLoadingPostSupplierManager = (state: RootState): boolean => fromSupplierDetails.selectIsLoadingPostSupplierManager(state.supplierDetailsReducer);
export const selectIsLoadingPatchSupplierNotes = (state: RootState): boolean => fromSupplierDetails.selectIsLoadingPatchSupplierNotes(state.supplierDetailsReducer);
export const selectIsLoadingPatchCommissionContract = (state: RootState): boolean => fromSupplierDetails.selectIsLoadingPatchCommissionContract(state.supplierDetailsReducer);
export const selectIsLoadingPostCommissionContract = (state: RootState): boolean => fromSupplierDetails.selectIsLoadingPostCommissionContract(state.supplierDetailsReducer);
export const selectIsLoadingPatchPurchaseContract = (state: RootState): boolean => fromSupplierDetails.selectIsLoadingPatchPurchaseContract(state.supplierDetailsReducer);
export const selectIsLoadingPostPurchaseContract = (state: RootState): boolean => fromSupplierDetails.selectIsLoadingPostPurchaseContract(state.supplierDetailsReducer);
export const selectIsLoadingPatchContractStatus = (state: RootState): boolean => fromSupplierDetails.selectIsLoadingPatchContractStatus(state.supplierDetailsReducer);
// Errors
export const selectHasSupplierDetailsError = (state: RootState): boolean => fromSupplierDetails.selectHasSupplierDetailsError(state.supplierDetailsReducer);
export const selectHasSupplierGeneralDetailsError = (state: RootState): boolean => fromSupplierDetails.selectHasSupplierGeneralDetailsError(state.supplierDetailsReducer);
export const selectHasSupplierNumberError = (state: RootState): boolean => fromSupplierDetails.selectHasSupplierNumberError(state.supplierDetailsReducer);
export const selectHasSupplierIbanPatchError = (state: RootState): boolean => fromSupplierDetails.selectHasSupplierIbanPatchError(state.supplierDetailsReducer);
export const selectHasSupplierAddressPatchError = (state: RootState): boolean => fromSupplierDetails.selectHasSupplierAddressPatchError(state.supplierDetailsReducer);
export const selectHasSupplierAddressPostError = (state: RootState): boolean => fromSupplierDetails.selectHasSupplierAddressPostError(state.supplierDetailsReducer);
export const selectHasSupplierContactPatchError = (state: RootState): boolean => fromSupplierDetails.selectHasSupplierContactPatchError(state.supplierDetailsReducer);
export const selectHasSupplierContactPostError = (state: RootState): boolean => fromSupplierDetails.selectHasSupplierContactPostError(state.supplierDetailsReducer);
export const selectHasSupplierManagerPatchError = (state: RootState): boolean => fromSupplierDetails.selectHasSupplierManagerPatchError(state.supplierDetailsReducer);
export const selectHasSupplierManagerPostError = (state: RootState): boolean => fromSupplierDetails.selectHasSupplierManagerPostError(state.supplierDetailsReducer);
export const selectHasSupplierNotesPatchError = (state: RootState): boolean => fromSupplierDetails.selectHasSupplierNotesPatchError(state.supplierDetailsReducer);
export const selectHasCommissionContractPatchError = (state: RootState): boolean => fromSupplierDetails.selectHasCommissionContractPatchError(state.supplierDetailsReducer);
export const selectHasCommissionContractPostError = (state: RootState): boolean => fromSupplierDetails.selectHasCommissionContractPostError(state.supplierDetailsReducer);
export const selectHasPurchaseContractPatchError = (state: RootState): boolean => fromSupplierDetails.selectHasPurchaseContractPatchError(state.supplierDetailsReducer);
export const selectHasPurchaseContractPostError = (state: RootState): boolean => fromSupplierDetails.selectHasPurchaseContractPostError(state.supplierDetailsReducer);
export const selectHasContractStatusPatchError = (state: RootState): boolean => fromSupplierDetails.selectHasContractStatusPatchError(state.supplierDetailsReducer);


// Prices
export const selectIsPriceUploadLoading = (state: RootState): boolean => fromPrices.selectIsPriceUploadLoading(state.pricesReducer);
export const selectHasPriceUploadError = (state: RootState): boolean => fromPrices.selectHasPriceUploadError(state.pricesReducer);

// Products
export const selectIsProductUploadLoading = (state: RootState): boolean => fromProducts.selectIsProductUploadLoading(state.productsReducer);
export const selectHasProductUploadError = (state: RootState): boolean => fromProducts.selectHasProductUploadError(state.productsReducer);

// Redirect
export const selectRedirectLink = (state: RootState): string => fromRedirect.selectRedirectLink(state.redirectReducer);

// Notification
export const selectSuccessMsg = (state: RootState): SuccessMsg => fromNotifications.selectSuccessMsg(state.notificationsReducer);
export const selectErrorMsg = (state: RootState): ErrorMsg => fromNotifications.selectErrorMsg(state.notificationsReducer);

import React from 'react';
import { SupplierDetails } from '../../components/SupplierDetails/SupplierDetails';
import { PageTemplate } from '../../components/templates';

export const SupplierPage: React.FC = () => {
    return (
        <PageTemplate headerText='Suppliers details' hasBackButton>
            <SupplierDetails />
        </PageTemplate>
    );
};

export const trimIfString = (value: unknown): unknown => {
    if (value instanceof String) return value.trim();
    return value;
};

type Validators = {
    getDecimalCount: (num: number) => number;
    isMarketplaceValid: (marketplace: unknown) => boolean;
    isSupplierNumberValid: (expected: string | undefined, given: unknown) => boolean;
    isSKUValid: (SKU: unknown) => boolean;
    isDateValid: (date: unknown) => boolean;
    isCostPriceValid: (price: unknown, getDecimalCount: (num: number) => number) => boolean;
    isShipmentCostValid: (cost: unknown, getDecimalCount: (num: number) => number) => boolean;
};

// Disabling 'isSKUValid' is already declared in the upper scope - this problem will go away when we move the functions from this file to separate files
/* eslint-disable @typescript-eslint/no-shadow */
export const validateProductData = (
    supplierId: string | undefined,
    products: Product[],
    {
        getDecimalCount,
        isMarketplaceValid,
        isSupplierNumberValid,
        isSKUValid,
        isDateValid,
        isCostPriceValid,
        isShipmentCostValid,
    }: Validators
): RowErrors[] => {
    const summary: RowErrors[] = [];

    products.forEach((product, i) => {
        const errors = [];
        // Stop validation if there are errors found in more than 10 rows
        if (summary.length >= 10) return;
        const {
            marketplace,
            suppliernumber,
            sku,
            startdate,
            costprice,
            shipmentcostsnl,
            shipmentcostsbe,
            shipmentcostsde,
        } = product;
        // Validate marketplace
        if (!isMarketplaceValid(marketplace)) {
            errors.push('Invalid marketplace.');
        }
        // Validate supplier number
        if (!isSupplierNumberValid(supplierId, suppliernumber)) {
            errors.push('Invalid supplier number.');
        }
        // Validate SKU
        if (!isSKUValid(sku)) {
            errors.push('Invalid SKU identifier.');
        }
        if (!isDateValid(startdate)) {
            errors.push('Invalid date format.');
        }
        // Validate cost price
        if (!isCostPriceValid(costprice, getDecimalCount)) {
            errors.push('Invalid cost price.');
        }
        // Validate shipment cost for NL
        if (!isShipmentCostValid(shipmentcostsnl, getDecimalCount)) {
            errors.push('Invalid NL shipment costs.');
        } else if (
            // check if it matches the marketplace
            shipmentcostsnl &&
            (!marketplace || marketplace.toString().toLowerCase() !== 'nl')
        ) {
            errors.push('Shipment costs are given for NL, while the Marketplace is not NL.');
        }

        // Validate shipment cost for BE
        if (!isShipmentCostValid(shipmentcostsbe, getDecimalCount)) {
            errors.push('Invalid BE shipment costs.');
        } else if (
            // check if it matches the marketplace
            shipmentcostsbe &&
            (!marketplace || marketplace.toString().toLowerCase() !== 'nl')
        ) {
            errors.push('Shipment costs are given for BE, while the Marketplace is not NL.');
        }

        // Validate shipment cost for DE
        if (!isShipmentCostValid(shipmentcostsde, getDecimalCount)) {
            errors.push('Invalid DE shipment costs.');
        } else if (
            // check if it matches the marketplace
            shipmentcostsde &&
            (!marketplace || marketplace.toString().toLowerCase() !== 'de')
        ) {
            errors.push('Shipment costs are given for DE, while the Marketplace is not DE.');
        }

        // Return the errors
        if (errors.length > 0) {
            summary.push({
                rowNumber: i + 1,
                errors,
            });
        }
    });

    return summary;
};

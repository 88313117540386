import React from 'react';
import { ProductUpload } from '../../components/ProductUpload/ProductUpload';
import { PageTemplate } from '../../components/templates';

export const ProductUploadPage = () => {
    return (
        <PageTemplate headerText='Product Upload' hasBackButton>
            <ProductUpload />
        </PageTemplate>
    );
};

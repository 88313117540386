import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    DataGrid,
    GridCellParams,
    GridColDef,
    GridRowParams,
    GridValueFormatterParams,
    MuiEvent,
} from '@mui/x-data-grid';
import { DataGridNoRowsOverlay, DataGridCustomAddItemFooter } from '../../shared/DataGrid';
import { useAppSelector } from '../../../redux/typed-hooks';
import { selectSupplierContacts } from '../../../redux/reducers';
import { Heading } from '../../shared';
import { EditButton } from '../../shared/Buttons';
import { useCheckIfUserHasManagerRights } from '../../../hooks';
import { capitalize } from '../../../utils/formatting';

export const Contacts = () => {
    const navigate = useNavigate();
    const { supplierId } = useParams();
    const { hasManagerRights } = useCheckIfUserHasManagerRights();
    const contacts = useAppSelector(selectSupplierContacts);
    if (!contacts) return <p>There are no contacts for this supplier.</p>;

    const handleEditClick = () => {
        // This is intentional
    };

    const renderEditButton = () => <EditButton variant='text' handleClick={handleEditClick} />;

    const columns: GridColDef[] = [
        {
            field: 'type',
            headerName: 'Type',
            valueFormatter: (params: GridValueFormatterParams<string>) => capitalize(params.value),
            flex: 0.5,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'name',
            headerName: 'Contact Name',
            flex: 0.5,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'telephoneNumber',
            headerName: 'Contact Telephone Number',
            flex: 0.5,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'email',
            headerName: 'Contact Email',
            flex: 0.5,
            align: 'left',
            headerAlign: 'left',
        },
    ];

    const editColumn: GridColDef = {
        field: 'editButton',
        headerName: '',
        align: 'center',
        headerAlign: 'center',
        renderCell: renderEditButton,
    };

    if (hasManagerRights) {
        columns.push(editColumn);
    }

    const handleRowDblClick = (params: GridRowParams, e: MuiEvent<React.MouseEvent<HTMLElement>>) => {
        e.defaultMuiPrevented = true;
        window.location.href = `mailto:${params.row.email}`;
    };

    const handleEditContactClick = (params: GridCellParams, e: MuiEvent<React.MouseEvent<HTMLElement>>) => {
        e.defaultMuiPrevented = true;
        if (params.field !== 'editButton') return;
        const { id } = params.row;
        navigate(`/suppliers/${supplierId}/edit-contact/${id}`);
    };

    const handleAddClick = (e: MuiEvent<React.MouseEvent<HTMLElement>>) => {
        e.defaultMuiPrevented = true;
        navigate(`/suppliers/${supplierId}/add-contact`);
    };

    return (
        <>
            <Heading level={2} text='Contacts' />
            <DataGrid
                rows={contacts}
                columns={columns}
                getRowId={(row) => row.id}
                onRowDoubleClick={handleRowDblClick}
                onCellClick={handleEditContactClick}
                autoHeight
                sx={{ cursor: 'pointer' }}
                hideFooter={!hasManagerRights}
                components={{
                    Footer: DataGridCustomAddItemFooter,
                    NoRowsOverlay: DataGridNoRowsOverlay,
                }}
                componentsProps={{
                    footer: { buttons: [{ label: 'Add contact', handleAddItem: handleAddClick }] },
                    noRowsOverlay: { text: 'No contacts' },
                }}
            />
        </>
    );
};

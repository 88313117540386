import React from 'react';
import { FormTemplate } from '../../templates';
import { SelectInput, TextInput } from '../../shared/Form/Inputs';
import { useCreateManager } from './useCreateManager';
import styles from './createManager.module.scss';

export const CreateManager = () => {
    const { name, role, handleSubmit, isLoading, formErrors, hasError } = useCreateManager();

    return (
        <FormTemplate handleSubmit={handleSubmit} isSubmitting={isLoading} hasError={hasError}>
            <div className={styles.inputsWrapper}>
                <TextInput
                    label='Name'
                    value={name.value}
                    handleChange={name.handleChange}
                    error={formErrors.name !== ''}
                    helperText={formErrors.name}
                />
                <SelectInput
                    label='Role'
                    value={role.value}
                    options={['operations', 'partnerships']}
                    handleChange={role.handleChange}
                />
            </div>
        </FormTemplate>
    );
};

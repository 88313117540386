import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../../redux/typed-hooks';
import {
    selectIsLoadingPatchCommissionContract,
    selectHasCommissionContractPatchError,
} from '../../../../redux/reducers';
import { editCommissionContract, clearSupplierDetailsPatchErrors } from '../../../../redux/reducers/supplierDetails';
import {
    useCheckboxInput,
    useDateInput,
    useNumberInput,
    useRadioInputGroup,
    useSelectInput,
    useTextInput,
} from '../../../shared/Form/Inputs';

import { useRedirect } from '../../../../hooks/useRedirect';
import {
    validateRequiredCheckboxGroup,
    validateRequiredField,
    objectHasNonEmptyValue,
} from '../../../../utils/validation';
import { convertCheckboxGroupToList, toDate } from '../../../../utils/formatting';

export const useEditCommissionContractForm = (contract: CommissionContract) => {
    const { supplierId } = useParams();
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoadingPatchCommissionContract);
    const hasError = useAppSelector(selectHasCommissionContractPatchError);

    const {
        id,
        created,
        updated,
        marketplaceCodes,
        term,
        smallBusinessScheme,
        ignorePurchaseCosts,
        refundFeeForReturns,
        invoicingCutOffDate,
        paymentDueDate,
        commission,
        comments,
    } = contract;

    // GENERAL
    const newBeginDate = useDateInput(term.beginDate);
    const newEndDate = useDateInput(term.endDate);
    const isMarketplaceNL = useCheckboxInput(marketplaceCodes.includes('nl'));
    const isMarketplaceDE = useCheckboxInput(marketplaceCodes.includes('de'));
    const newMarketPlaceCodes = convertCheckboxGroupToList([
        { value: 'nl', checked: isMarketplaceNL.value },
        { value: 'de', checked: isMarketplaceDE.value },
    ]);
    // PAYMENTS
    const newSmallBusinessScheme = useRadioInputGroup(ignorePurchaseCosts);
    const newIgnorePurchaseCosts = useRadioInputGroup(smallBusinessScheme);
    const newInvoicingCutOffDate = useSelectInput(invoicingCutOffDate);
    const newPaymentDueDate = useSelectInput(paymentDueDate);
    const newSkontoDiscountPercentage = useNumberInput(commission.skontoDiscountPercentageBasedOnSellingPrice);
    const newVatPercentage = useNumberInput(commission.vatPercentage);
    const newPaymentDiscount = useNumberInput(commission.paymentDiscount);
    const newFeePercentage = useNumberInput(commission.feePercentage);

    // RETURNS
    const newReturnPolicy = useSelectInput(commission.return.returnPolicy);
    const newReturnLogisticsProvider = useSelectInput(commission.return.returnLogisticsProvider);
    const { supplierReturnAddressId } = commission.return;
    const newSupplierReturnAddressId = useSelectInput(
        supplierReturnAddressId ? supplierReturnAddressId.toString() : null
    );
    const newWhoPaysReturnShippingCostsSupplierFault = useSelectInput(
        commission.return.whoPaysReturnShippingCostsSupplierFault
    );
    const newRefundFeeForReturns = useRadioInputGroup(refundFeeForReturns);
    const newSellingPriceReimbursementPercentage = useNumberInput(
        commission.return.sellingPriceReimbursementPercentage
    );

    // LOGISTICS
    const newOutboundSetup = useSelectInput(commission.outbound.setup);
    const newLogisticsProvider = useSelectInput(commission?.outbound.logisticsProvider);

    // COMMENTS
    const newComments = useTextInput(comments);

    const [formErrors, setFormErrors] = useState({
        marketplaceCodes: '',
        feePercentage: '',
    });

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errors = {
            marketplaceCodes: validateRequiredCheckboxGroup(newMarketPlaceCodes, 'marketplace codes'),
            feePercentage: validateRequiredField({ value: newFeePercentage.value, fieldName: 'Fee percentage' }),
        };

        setFormErrors(errors);

        if (objectHasNonEmptyValue(errors)) return;

        const newCommissionContract: EditCommissionContract = {
            marketplaceCodes: newMarketPlaceCodes as CommissionContract['marketplaceCodes'],
            term: {
                beginDate: newBeginDate.value ? toDate(newBeginDate.value.toString()) : term.beginDate,
                endDate: newEndDate.value ? toDate(newEndDate.value.toString()) : null,
            },
            smallBusinessScheme: newSmallBusinessScheme.value,
            ignorePurchaseCosts: newIgnorePurchaseCosts.value,
            refundFeeForReturns: newRefundFeeForReturns.value,
            invoicingCutOffDate: newInvoicingCutOffDate.value as CommissionContract['invoicingCutOffDate'],
            paymentDueDate: newPaymentDueDate.value as CommissionContract['paymentDueDate'],
            commission: {
                feePercentage: newFeePercentage.value as number, // Passed validation so it's safe to cast it as number
                skontoDiscountPercentageBasedOnSellingPrice: newSkontoDiscountPercentage.value
                    ? newSkontoDiscountPercentage.value
                    : 0,
                vatPercentage: newVatPercentage.value ? newVatPercentage.value : 0,
                paymentDiscount: newPaymentDiscount.value ? newPaymentDiscount.value : 0,
                outbound: {
                    setup: (newOutboundSetup.value as CommissionModel['outbound']['setup']) || null,
                    logisticsProvider:
                        (newLogisticsProvider.value as CommissionModel['outbound']['logisticsProvider']) || null,
                },
                return: {
                    returnPolicy: newReturnPolicy.value
                        ? (newReturnPolicy.value as CommissionModel['return']['returnPolicy'])
                        : null,
                    returnLogisticsProvider: newReturnLogisticsProvider.value
                        ? (newReturnLogisticsProvider.value as CommissionModel['return']['returnLogisticsProvider'])
                        : null,
                    supplierReturnAddressId: newSupplierReturnAddressId.value
                        ? parseFloat(newSupplierReturnAddressId.value)
                        : null,
                    sellingPriceReimbursementPercentage: newSellingPriceReimbursementPercentage.value
                        ? newSellingPriceReimbursementPercentage.value
                        : null,
                    whoPaysReturnShippingCostsSupplierFault: newWhoPaysReturnShippingCostsSupplierFault.value
                        ? (newWhoPaysReturnShippingCostsSupplierFault.value as CommissionModel['return']['whoPaysReturnShippingCostsSupplierFault'])
                        : null,
                },
            },
            comments: newComments.value,
        };

        // Send
        if (supplierId) {
            dispatch(
                editCommissionContract({ supplierId, contractId: contract.id, newContract: newCommissionContract })
            );
        }
    };

    useEffect(() => {
        dispatch(clearSupplierDetailsPatchErrors());
    }, [dispatch]);

    // Redirect on successful API call
    useRedirect();

    return {
        id,
        created,
        updated,
        isMarketplaceNL,
        isMarketplaceDE,
        newBeginDate,
        newEndDate,
        newSmallBusinessScheme,
        newIgnorePurchaseCosts,
        newRefundFeeForReturns,
        newInvoicingCutOffDate,
        newPaymentDueDate,
        newSkontoDiscountPercentage,
        newVatPercentage,
        newPaymentDiscount,
        newOutboundSetup,
        newLogisticsProvider,
        newReturnPolicy,
        newReturnLogisticsProvider,
        newSupplierReturnAddressId,
        newFeePercentage,
        newSellingPriceReimbursementPercentage,
        newWhoPaysReturnShippingCostsSupplierFault,
        newComments,
        handleSubmit,
        formErrors,
        isLoading,
        hasError,
    };
};

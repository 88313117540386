import React from 'react';
// import { FileUpload } from '@app/components/shared/Form/Inputs/FileUpload/FileUpload';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Dialog } from '@app/components/shared';
import { CancelButton } from '@app/components/shared/Buttons';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useSkuActions } from './useSkuActions';
import { ImportSkuGoogleSheet } from './ImportSkuGoogleSheet';

export const SkuActions = () => {
    const {
        // onFileUpload,
        showUploadDialog,
        closeUploadDialog,
        skuActionTableColumns,
        jsonData,
        onExportCommissionContactSkuFees,
        onImportCommissionContactSkuFees,
    } = useSkuActions();

    return (
        <>
            <Grid container>
                <Grid item>
                    {/* <FileUpload accept='text/csv' buttonTitle='Import' onFileUpload={onFileUpload} /> */}
                    <ImportSkuGoogleSheet />
                </Grid>
                <Grid item>
                    <Button onClick={onExportCommissionContactSkuFees}>Export</Button>
                </Grid>
            </Grid>

            <Dialog dialogOpen={showUploadDialog} titleText='Please confirm SKU Fees import'>
                <Box sx={{ p: 1, overflow: 'auto' }}>
                    <Typography>SKU Actions Preview:</Typography>
                    <Paper>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table>
                                <TableHead>
                                    {skuActionTableColumns.map((item) => (
                                        <TableCell key={item.label}>{item.label}</TableCell>
                                    ))}
                                </TableHead>
                                <TableBody>
                                    {jsonData.map((item, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <TableRow key={`${item.command}${index}`}>
                                            <TableCell>{item.command}</TableCell>
                                            <TableCell>{item.supplierNumber}</TableCell>
                                            <TableCell>{item.commissionId}</TableCell>
                                            <TableCell>{item.vendorSku}</TableCell>
                                            <TableCell>{item.commissionPercentage}</TableCell>
                                            <TableCell>{item.beginDate}</TableCell>
                                            <TableCell>{item.endDate}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>

                    <Grid container gap={2} mt={3} justifyContent='flex-end'>
                        <Grid item>
                            <CancelButton
                                handleClick={() => {
                                    closeUploadDialog();
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                color='success'
                                onClick={() => {
                                    closeUploadDialog();
                                    onImportCommissionContactSkuFees();
                                }}
                            >
                                Import
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </>
    );
};

import React from 'react';
import { FormTemplate } from '../../../templates';
import { NumberInput } from '../../../shared/Form/Inputs';
import { Fieldset } from '../../../shared/Form';
import { useEditSupplierNumberForm } from './useEditSupplierNumberForm';
import styles from './editSupplierNumberForm.module.scss';

interface EditSupplierNumberFormProps {
    supplierNum: SupplierDetails['supplierNumber'] | null;
}

export const EditSupplierNumberForm = ({ supplierNum }: EditSupplierNumberFormProps) => {
    const { formErrors, supplierNumber, handleSubmit, isLoading, hasError } = useEditSupplierNumberForm(supplierNum);

    return (
        <FormTemplate handleSubmit={handleSubmit} isSubmitting={isLoading} hasError={hasError}>
            <div className={styles.inputsWrapper}>
                <Fieldset legend='Supplier number'>
                    <NumberInput
                        label='Supplier number'
                        value={supplierNumber.value}
                        handleChange={supplierNumber.handleChange}
                        error={formErrors.supplierNumber !== ''}
                        helperText={formErrors.supplierNumber}
                    />
                </Fieldset>
            </div>
        </FormTemplate>
    );
};

import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../redux/typed-hooks';
import { duplicateContract } from '../../redux/reducers/supplierDetails';

export const useDuplicateContract = () => {
    const dispatch = useAppDispatch();
    const { contractId } = useParams();
    const { supplierId } = useParams();

    const handleDuplicateContract = () => {
        if (supplierId && contractId) {
            dispatch(duplicateContract({ supplierId, contractId }));
        }
    };

    return { handleDuplicateContract };
};

import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/typed-hooks';
import { selectSupplierAddressById } from '../../../redux/reducers';
import { Error, Loader } from '../../shared';
import { EditAddressForm } from './subcomponents/EditAddressForm';
import { useFetchSupplierDetails } from '../../SupplierDetails/useFetchSupplierDetails';
import { toInteger } from '../../../utils/formatting';

export const EditAddress = () => {
    const { isLoading, hasError } = useFetchSupplierDetails();
    const { addressId } = useParams();
    const addressToEditId = addressId ? toInteger(addressId) : null;
    const address = useAppSelector((state) => selectSupplierAddressById(state, addressToEditId));

    if (isLoading) return <Loader />;
    if (hasError) return <Error />;
    if (!address) return <p>There is no information on the selected address for this supplier.</p>;
    return <EditAddressForm address={address} />;
};

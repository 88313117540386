import React from 'react';
import { Heading, YesNoField } from '../../../shared';
import { capitalize } from '../../../../utils/formatting';
import styles from './contractReturnsPurchaseModel.module.scss';

interface ContractReturnsPurchaseModelProps {
    returns: PurchaseModel['return'];
    refundFeeForReturns: PurchaseContract['refundFeeForReturns'];
}

export const ContractReturnsPurchaseModel = ({ returns, refundFeeForReturns }: ContractReturnsPurchaseModelProps) => {
    const {
        returnPolicy,
        returnLogisticsProvider,
        costPriceReimbursementPercentage,
        whoPaysSightReturns,
        whoPaysReturnShippingCostsSupplierFault,
    } = returns;

    return (
        <>
            <Heading level={2} text='Returns' />
            <dl className={styles.list}>
                <dt>Return policy:</dt>
                <dd>{returnPolicy ? capitalize(returnPolicy) : ''}</dd>
                {['berkenrode', 'timco', 'troostwijk', 'gsd', 'bva'].includes(returnPolicy || '') && (
                    <>
                        <dt>Cost Price Reimbursement:</dt>
                        <dd>{costPriceReimbursementPercentage ? `${costPriceReimbursementPercentage}%` : ''}</dd>
                    </>
                )}
                <dt>Return logistics provider:</dt>
                <dd>{returnLogisticsProvider ? capitalize(returnLogisticsProvider) : ''}</dd>
                <dt>Who pays sight returns:</dt>
                <dd>{whoPaysSightReturns ? capitalize(whoPaysSightReturns) : ''}</dd>
                <dt>Refund fees for returns: </dt>
                <dd>
                    <YesNoField yes={refundFeeForReturns} />
                </dd>
                <dt>Who pays return shipping costs when supplier at fault:</dt>
                <dd>
                    {whoPaysReturnShippingCostsSupplierFault ? capitalize(whoPaysReturnShippingCostsSupplierFault) : ''}
                </dd>
            </dl>
        </>
    );
};

import React from 'react';
import { FormTemplate } from '../../../templates';
import { Fieldset } from '../../../shared/Form';
import { TextInput } from '../../../shared/Form/Inputs';
import { useEditIbanForm } from './useEditIbanForm';
import styles from './editIbanForm.module.scss';

interface EditIbanFormProps {
    iban: SupplierDetails['invoicing']['iban'];
}

export const EditIbanForm = ({ iban }: EditIbanFormProps) => {
    const { formError, newIban, handleSubmit, isLoading, hasError } = useEditIbanForm(iban);

    return (
        <FormTemplate handleSubmit={handleSubmit} isSubmitting={isLoading} hasError={hasError}>
            <div className={styles.inputsWrapper}>
                <Fieldset legend='Iban'>
                    <TextInput
                        label='Iban'
                        value={newIban.value}
                        handleChange={newIban.handleChange}
                        error={formError !== ''}
                        helperText={formError}
                    />
                </Fieldset>
            </div>
        </FormTemplate>
    );
};

import React, { useId } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material/';
import styles from '../inputs.module.scss';

interface DateInputProps {
    label: string;
    value: Date | null;
    handleChange: (newValue: Date | null) => void;
    hasExtraLabel?: boolean;
    error?: boolean;
    helperText?: string;
}

export const DateInput = ({
    label,
    value,
    hasExtraLabel = true,
    error = false,
    helperText = '',
    handleChange,
}: DateInputProps) => {
    const id = useId();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className={styles.dateInput}>
                {hasExtraLabel && (
                    <label htmlFor={id} aria-hidden='true' className={styles.dateInput__label}>
                        {label}
                    </label>
                )}
                <div className={styles.textInput__inputWrapper}>
                    <DatePicker
                        label={label}
                        value={value}
                        onChange={handleChange}
                        renderInput={(params) => (
                            /* eslint-disable react/jsx-props-no-spreading */
                            <TextField {...params} id={id} error={error} helperText={helperText} fullWidth />
                        )}
                    />
                </div>
            </div>
        </LocalizationProvider>
    );
};

import React from 'react';
import { Button } from '@mui/material';

interface EditButtonProps {
    variant?: 'text' | 'outlined';
    handleClick: () => void;
}

export const EditButton = ({ variant = 'outlined', handleClick }: EditButtonProps) => (
    <Button type='button' variant={variant} onClick={handleClick}>
        Edit
    </Button>
);

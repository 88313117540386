import React from 'react';
import { FormTemplate } from '../../../templates/FormTemplate/FormTemplate';
import { SelectInput, TextInput } from '../../../shared/Form/Inputs';
import { useEditManagerForm } from './useEditManagerForm';
import styles from './editManagerForm.module.scss';

interface EditManagerFormProps {
    manager: SupplierManager;
}

export const EditManagerForm = ({ manager }: EditManagerFormProps) => {
    const { formErrors, name, role, handleSubmit, isLoading, hasError } = useEditManagerForm(manager);

    return (
        <FormTemplate handleSubmit={handleSubmit} isSubmitting={isLoading} hasError={hasError}>
            <div className={styles.inputsWrapper}>
                <TextInput
                    label='Name'
                    value={name.value}
                    handleChange={name.handleChange}
                    error={formErrors.name !== ''}
                    helperText={formErrors.name}
                />
                <SelectInput
                    label='Role'
                    options={['operations', 'partnerships'] as SupplierManager['role'][]}
                    value={role.value}
                    handleChange={role.handleChange}
                />
            </div>
        </FormTemplate>
    );
};

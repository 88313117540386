import React, { useId } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import styles from '../inputs.module.scss';

interface RadioInputGroupProps {
    label: string;
    value: string;
    name?: string;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioInputGroup = ({ label, name = '', value, handleChange }: RadioInputGroupProps) => {
    const id = useId();
    return (
        <div className={styles.radioInputGroup}>
            <label htmlFor={id} className={styles.radioInputGroup__label}>
                {label}
            </label>
            <div className={styles.radioInputGroup__inputWrapper}>
                <RadioGroup
                    className={styles.MuiFormGroup}
                    aria-labelledby={id}
                    name={name}
                    value={value}
                    onChange={handleChange}
                >
                    <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                    <FormControlLabel value='no' control={<Radio />} label='No' />
                </RadioGroup>
            </div>
        </div>
    );
};

export default RadioInputGroup;
